import { Modal, InfoModal, Button, TextInput, Icons, InfoBubble } from '@doseme/cohesive-ui' //Button
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'

import { showSuccessToast } from '../../../../shared/toast'
import { useFormValidation } from '../../../../hooks/useFormValidation'
import { useAuthStore, useHospitalStore } from '../../../../hooks/useStore'
import { IFormField } from '../../../../types/validation'
import { isRequired } from '../../../../utils/validation/rules'
import { IModalProps } from '../../../PatientRouter/types'

import './index.scss'

export const LoginModal: React.FC<IModalProps> = observer((props) => {
  const [displayLoginError, setDisplayLoginError] = useState<boolean>(false)
  const [lockedOut, setLockedOut] = useState<boolean>(false)
  const [isShowPasswordChecked, setIsShowPasswordChecked] = useState<boolean>(false)

  const authStore = useAuthStore()
  const hospitalStore = useHospitalStore()

  const navigate = useNavigate()
  const { boot, shutdown, update } = useIntercom()

  const ssoRedirectURL = authStore.ssoRedirectURL

  useEffect(() => {
    if (props.show) {
      authStore.statelessAuthLogout()

      if (window.env.INTERCOM_APP_ID) {
        // Reset the Intercom instance when the session timeout modal is displayed
        shutdown()

        boot({
          customAttributes: {
            instance: window.env.INSTALLATION,
            vendor: window.env.VENDOR_MODE
          }
        })
      }

      form.reset()
    }
  }, [props.show])

  useEffect(() => {
    authStore.error.includes('DoseMeRx account has been temporarily locked') ? setLockedOut(true) : setLockedOut(false)
  }, [authStore.error])

  const handleEnter = (event: React.FormEvent) => {
    event.preventDefault()
    if (form.inputs['password']) {
      handleLogin()
    }
  }

  const displayError = () => {
    if (lockedOut) {
      return (
        <div className='account-locked-down'>
          <InfoBubble
            type='error'
            bubbleTitle={
              <div className='error-title'>
                Your account has been locked down.
                <div className='error-text'>{authStore.error}</div>
              </div>
            }
          />
        </div>
      )
    }

    return (
      <div className='mt-3'>
        <InfoBubble type='error' bubbleTitle={<b>{authStore.error}</b>} />
      </div>
    )
  }

  const redirectToSSORealm = () => {
    if (ssoRedirectURL) {
      window.location.href = ssoRedirectURL
    }
  }

  const handleLogin = async () => {
    await authStore.authLogin(form.inputs['email'], form.inputs['password'])

    if (['loadError', 'updateError'].includes(authStore.loadState)) {
      setDisplayLoginError(true)

      return
    }

    // FIXME: IFE-975 Set all Intercom session details from session payload
    // Update the Intercom session with authenticated user details
    if (window.env.INTERCOM_APP_ID && authStore.auth) {
      update({
        name: `${authStore.auth.attributes.clinicianFirstName} ${authStore.auth.attributes.clinicianLastName}`,
        email: authStore.auth.attributes.clinicianEmail,
        userId: `${window.env.INSTALLATION}/${authStore.auth.attributes.clinicianId}`,
        company: {
          companyId: `${window.env.INSTALLATION}/${authStore.auth.attributes.hospitalId}`,
          name: hospitalStore.hospital?.attributes.name
        },
        customAttributes: {
          instance: window.env.INSTALLATION,
          vendor: window.env.VENDOR_MODE
        }
      })
    }

    showSuccessToast('Logged in')
    props.setShow(false)
  }

  const redirectToLoginPage = () => {
    navigate('/login')
    location.reload()
  }

  const title = (
    <div className='d-flex'>
      <div className='session-timeout-padlock'>
        <Icons.Padlock background='white' />
      </div>
      Session timeout
    </div>
  )

  const formFields: Record<string, IFormField> = {
    email: {
      initialInput: authStore.auth?.attributes.clinicianEmail,
      rules: []
    },
    password: {
      initialInput: '',
      rules: [isRequired]
    }
  }

  const form = useFormValidation(formFields)

  const formContent = (): JSX.Element => {
    return (
      <div className='position-relative w-100'>
        <div>
          <b>
            {
              ssoRedirectURL
                ? 'You’ll need to re-authenticate to continue where you left off.'
                : 'Enter your password to continue where you left off.'
            }
          </b>
        </div>
        <div className='mt-4'>
          <TextInput
            label={<b>Email:</b>}
            disabled
            fieldState='valid'
            validationText={form.getValidationMsg('email')}
            value={form.inputs['email']}
            name='email-input-edit'
            onChange={(value) => {
              setDisplayLoginError(false)
              setLockedOut(false)
              form.validateFields([
                {
                  field: 'email',
                  input: value
                }
              ])
            }}
            onBlur={() => form.updateFieldsDisplay(['email'])}
          />
        </div>

        {!ssoRedirectURL &&
          <div className='mt-4'>
            <form onSubmit={handleEnter}>
              <TextInput
                showCapsLock={true}
                type={isShowPasswordChecked ? 'text' : 'password'}
                label={
                  <div className='d-flex'>
                    <b>Password:</b>
                    <div
                      className='timeout-modal-show-password'
                      onClick={() => setIsShowPasswordChecked(!isShowPasswordChecked)}
                    >
                      <input className='timeout-modal-show-password' readOnly type='checkbox' checked={isShowPasswordChecked} />
                      <div className='timeout-modal-show-password-label'>Show password</div>
                    </div>
                  </div>
                }
                fieldState={form.getValidState('password')}
                disabled={lockedOut}
                validationText={form.getValidationMsg('password')}
                value={form.inputs['password']}
                name='login-modal-password-input'
                onChange={(value) =>
                  form.validateFields([
                    {
                      field: 'password',
                      input: value
                    }
                  ])
                }
                onBlur={() => form.updateFieldsDisplay(['password'])}
              />
            </form>
          </div>
        }

        {displayLoginError && displayError()}
      </div>
    )
  }

  const footer = (
    <div className='session-timeout-footer'>
      <div className='session-timeout-logout-text' onClick={redirectToLoginPage}>
        Not you? Sign in as someone else
      </div>
      {!lockedOut && (
        <Button
          loading={authStore.loadState === 'loading'}
          disabled={authStore.loadState === 'loading' || (!ssoRedirectURL && !form.inputs['password'])}
          onClick={ssoRedirectURL ? redirectToSSORealm : handleLogin}
          variant='primary'
        >
          {ssoRedirectURL ? 'Re-authenticate' : 'Log in'}
        </Button>
      )}
    </div>
  )

  return (
    <div className='session-timeout-modal-wrapper'>
      <Modal show={props.show}>
        <InfoModal
          size='s'
          title={title}
          subtitle={<b className='ml-5'>Just to be safe, you've been logged out.</b>}
          message={formContent()}
          linkComponent={footer}
        />
      </Modal>
    </div>
  )
})
