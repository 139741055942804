import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { InfoModal, Modal, Button, Dropdown, TextInput } from '@doseme/cohesive-ui'
import classnames from 'classnames'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useFormValidation } from '../../../../../../../../../../hooks/useFormValidation'
import { IFormField, IFormState } from '../../../../../../../../../../types/validation'
import { IModalProps } from '../../../../../../../../types'
import { IGCSFScheduleParts } from '../../../../../../../../../../store/dosingRecommendation/types'
import { stringToFloat } from '../../../../../../../../../../utils/validation/formatters'
import { isRequired, isStringValidNumber, isLessThanFourDecimalPlaces, isStringWithinNumericLimits } from '../../../../../../../../../../utils/validation/rules'
import { ICourseAdministrationType } from '../../../../../../../../../../store/course/types'
import { formatAdministrations, getGCSFAdminLimits, formatNextDoseDayLimits } from './utils'

import './index.scss'

interface IProps extends IModalProps {
  calculateCustomDose: (form: IFormState | null) => void
  GCSFData?: IGCSFScheduleParts
  adminTypes?: ICourseAdministrationType[]
}

export const SimulatedGCSFForm: React.FC<IProps> = observer((props) => {
  const [blur, setBlur] = useState<boolean>(false)
  const [drugId, setDrugId] = useState<string>(props.GCSFData?.administrationType.id || '1')
  const [simulateGCFSs, setSimulateGCFSs] = useState<boolean>(false)
  const [useGCSFData, setUseGCSFData] = useState<boolean>(false)

  const adminLimits = getGCSFAdminLimits(drugId, props.adminTypes)

  useEffect(() => {
    if (props.show && props.GCSFData?.amount.value) {
      setUseGCSFData(true)
      setDrugId(props.GCSFData?.administrationType.id || '')
    }
  }, [props.show])

  const formFields: Record<string, IFormField> = useMemo(() => {
    return {
      drug: {
        initialInput: drugId,
        rules: [isRequired]
      },
      numberOfDoses: {
        initialInput: useGCSFData
          ? props.GCSFData?.numberOfDoses || ''
          : adminLimits?.numberOfDoses.default.value || '',
        initialConstraints: adminLimits?.numberOfDoses,
        rules: [isRequired, isStringValidNumber, isLessThanFourDecimalPlaces, isStringWithinNumericLimits],
        formatter: stringToFloat
      },
      nextDoseDay: {
        initialInput: useGCSFData
          ? props.GCSFData?.nextDoseDay.toString() || ''
          : adminLimits?.nextDoseDay.default.value.toString() || '',
        rules: [isRequired]
      },
      amount: {
        initialInput: useGCSFData
          ? props.GCSFData?.amount.value || ''
          : adminLimits?.doseClinical.default.value || '',
        initialConstraints: adminLimits?.doseClinical,
        rules: [isRequired, isStringValidNumber, isLessThanFourDecimalPlaces, isStringWithinNumericLimits],
        formatter: stringToFloat
      }
    }
  }, [props.GCSFData, props.adminTypes, drugId, useGCSFData])

  const form = useFormValidation(formFields)

  useEffect(() => {
    form.reset()
  }, [drugId, useGCSFData])

  const changeDrugId = (newDrugId: string) => {
    setUseGCSFData(false)
    setDrugId(newDrugId)
  }

  const formContent = (): JSX.Element => {
    if (props.adminTypes) {
      const adminLimits = getGCSFAdminLimits(drugId, props.adminTypes)

      return (
        <>
          <div>
            <Dropdown
              label='Drug:'
              fieldState={form.getValidState('drug')}
              validationText={form.getValidationMsg('drug')}
              data={formatAdministrations(props.adminTypes)}
              value={drugId}
              onSelect={(item) => {
                setBlur(true)
                form.validateFields(
                  [
                    {
                      field: 'drug',
                      input: item.value
                    }
                  ],
                  'updateFieldsDisplay'
                )
                changeDrugId(item.value)
              }}
              placeholder='Select an item'
            />
          </div>
          <div>
            <TextInput
              label='Number of doses:'
              fieldState={blur ? 'valid' : form.getValidState('numberOfDoses')}
              validationText={blur ? '' : form.getValidationMsg('numberOfDoses')}
              required={true}
              value={form.inputs['numberOfDoses']}
              onChange={(value: string) =>
                form.validateFields([
                  {
                    field: 'numberOfDoses',
                    input: value,
                    constraints: adminLimits?.numberOfDoses
                  }
                ])
              }
              onBlur={() => {
                setBlur(false)
                form.updateFieldsDisplay(['numberOfDoses'])
              }}
              name='number-of-doses-input'
              disabled={adminLimits?.numberOfDoses.min.value === adminLimits?.numberOfDoses.max.value}
            />
          </div>
          <div>
            <Dropdown
              label='Start GCSFs on:'
              fieldState={form.getValidState('nextDoseDay')}
              validationText={form.getValidationMsg('nextDoseDay')}
              data={formatNextDoseDayLimits(adminLimits?.nextDoseDay.min.value, adminLimits?.nextDoseDay.max.value)}
              value={form.inputs['nextDoseDay']}
              onSelect={(item) =>
                form.validateFields(
                  [
                    {
                      field: 'nextDoseDay',
                      input: item.value
                    }
                  ],
                  'updateFieldsDisplay'
                )
              }
              placeholder='Select an item'
            />
          </div>
          <div>
            <TextInput
              label='Dose amount:'
              fieldState={blur ? 'valid' : form.getValidState('amount')}
              validationText={blur ? '' : form.getValidationMsg('amount')}
              required={true}
              value={form.inputs['amount']}
              onChange={(value: string) =>
                form.validateFields([
                  {
                    field: 'amount',
                    input: value,
                    constraints: adminLimits?.doseClinical
                  }
                ])
              }
              onBlur={() => {
                setBlur(false)
                form.updateFieldsDisplay(['amount'])
              }}
              units={adminLimits?.doseClinical.default.unit?.name}
              name='dose-amount-input'
            />
          </div>
        </>
      )
    }

    return <></>
  }

  const GCFSFormContent = (): JSX.Element => {
    return (
      <>
        <div onClick={() => !simulateGCFSs && setSimulateGCFSs(!simulateGCFSs)} className='selectable-checkbox simulate-GCSF-padding'>
          <div>
            <input type='checkbox' readOnly checked={simulateGCFSs} id='checkbox-round' />
          </div>
          <b className={classnames('', { disabled: !simulateGCFSs })}>Simulate GCSF doses</b>
          {!simulateGCFSs && (
            <div className='simulate-GCSF-chevron'>
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          )}
        </div>

        {simulateGCFSs && (
          <div>
            {formContent()}
          </div>
        )}
      </>
    )
  }

  const noGCSFs = (): JSX.Element => {
    return (
      <div onClick={() => simulateGCFSs && setSimulateGCFSs(!simulateGCFSs)} className='selectable-checkbox'>
        <div>
          <input type='checkbox' readOnly checked={!simulateGCFSs} id='checkbox-round' />
        </div>
        <div className={classnames('', { disabled: simulateGCFSs })}>
          <b>No GCSF doses</b>
        </div>
      </div>
    )
  }

  const messages: JSX.Element[] = [
    <div key='no-GCFS' className='position-relative w-100'>
      {noGCSFs()}
    </div>,
    <div key='GCFS' className='position-relative w-100'>
      {GCFSFormContent()}
    </div>
  ]

  return (
    <Modal show={props.show} messageOnly>
      <InfoModal
        size='s'
        draggable
        linkComponent={
          <>
            <Button
              className='mr-3'
              data-testid='cancel-btn'
              onClick={() => props.setShow(false)}
              variant='primary-outline'
            >
              Cancel
            </Button>
            <Button
              disabled={simulateGCFSs && !form.valid}
              onClick={() => {
                props.setShow(false)
                props.calculateCustomDose(simulateGCFSs ? form : null)
              }}
              variant='primary'
            >
              Continue
            </Button>
          </>
        }
        title='Simulate GSCF doses'
        message={messages}
      />
    </Modal>
  )
})
