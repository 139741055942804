import { makeObservable, override } from 'mobx'

import { troughOutcomesMockData } from '../../../components/DoseMeAnalytics/components/TroughOutcomes/mockData'
import { analyticsChartsTroughOutcomesGetUrl } from '../../../constants/api'
import { AnalyticsRootStore } from '../../AnalyticsRootStore/AnalyticsRootStore'
import { AnalyticsChartStore } from '../AnalyticsChartsStore'

export class AnalyticsChartTroughOutcomesStore extends AnalyticsChartStore {
  //FIXME - IFE-1316 Remove once endpoint is implemented. Will use the fetchAnalyticsChart in AnalyticsChartStore
  async fetchAnalyticsChart() {
    this.setLoadState('loading')

    this.setAnalyticsChart(troughOutcomesMockData)
    this.setLoadState('loaded')
  }

  constructor(rootStore: AnalyticsRootStore) {
    super(rootStore, analyticsChartsTroughOutcomesGetUrl())
    makeObservable(this, {
      fetchAnalyticsChart: override
    })
  }
}
