import React from 'react'
import {
  BarChart,
  CartesianGrid,
  Bar,
  Tooltip,
  XAxis,
  Legend,
  LabelList, LabelProps
} from 'recharts'
import {
  neutrals50,
  neutrals300,
  neutrals500,
  neutrals700,
  blueSteel500,
  BLACK
} from '@doseme/cohesive-ui'

import { AnalyticsPlot, AnalyticsTooltip } from '../../AnalyicsPlot'
import { IBasePlotProps, IChartDataSeries } from '../../../types'

export const CourseTimeToTherapeuticTargetPlot: React.FC<IBasePlotProps> = (props) => {
  const courseTimeToTherapeuticTargetKeyNames: string[] = Object.keys(props.plotSeries) || []

  const barSize = 150
  const maxBarSize = 40
  const barCategoryGap = 8
  const chartHeight = 300

  const renderPlotContent = () => {
    return (
      <div
        style={{
          minHeight: '240px',
          maxHeight: '360px',
          width: '100%',
          overflowY: 'hidden',
          overflowX: 'hidden'
        }}
      >
        <BarChart
          layout='horizontal'
          data={props.plotData}
          barCategoryGap={barCategoryGap}
          width={556}
          height={chartHeight}
        >
          <CartesianGrid
            stroke={neutrals300}
            strokeWidth={1}
            fill={neutrals50}
            vertical={false}
            horizontal={true}
          />
          <XAxis
            type='category'
            dataKey='name'
            stroke={neutrals500}
            tick={{ stroke: neutrals700, fill: neutrals700 }}
            tickLine={{ stroke: neutrals500, fill: neutrals500 }}
            height={60}
          />
          <Tooltip
            shared={false}
            content={(props) => (
              <AnalyticsTooltip
                active={props.active}
                payload={props.payload}
                label={props.label}
                dataName={courseTimeToTherapeuticTargetKeyNames}
              />
            )}
            cursor={{ stroke: blueSteel500, opacity: 0.4 }}
          />
          <Legend
            verticalAlign='bottom'
            height={36}
            formatter={(value: string) => {
              return props.plotSeries[value as keyof IChartDataSeries].label || ''
            }}
          />
          {
            Object.entries(props.plotSeries).map(([key, value]) => {
              return (
                <Bar
                  key={key}
                  dataKey={key}
                  stackId='a'
                  fill={value.color}
                  opacity={0.9}
                  barSize={barSize}
                  maxBarSize={maxBarSize}
                >
                  <LabelList
                    dataKey={key}
                    position="center"
                    content={(props: LabelProps) => {
                      const { x = 0, width = 0, y = 0, height = 0, value } = props
                      const numericValue = Number(value) || 0

                      return (
                        <text
                          x={Number(x) + Number(width) / 2}
                          y={Number(y) + Number(height) / 2}
                          fill={BLACK}
                          textAnchor="middle"
                          dominantBaseline="middle"
                          fontSize={14}
                        >
                          {numericValue < 10 ? '' : `${numericValue}%`}
                        </text>
                      )
                    }}
                  />

                </Bar>
              )
            })
          }
        </BarChart>
      </div>
    )
  }

  return (
    <AnalyticsPlot
      plotId='doseme-analytics-course-time-to-therapeutic-target-graph'
      plotData={props.plotData}
      plotSeries={props.plotSeries}
      renderPlotContent={renderPlotContent()}
    />
  )
}
