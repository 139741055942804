import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { Badge, DatePickerInput, IMenuItem, MultiLevelMenu } from '@doseme/cohesive-ui'
import { decode } from 'he'

import { IFilterResourceListItem } from '../../../../store/types'

import '../../index.scss'

export const DoseMeAnalyticsFilters: React.FC = observer(() => {
  // FIXME: replace initial values with payload from the BE for all below
  const tempDateNow = new Date()
  const tempDateFuture: Date = new Date(tempDateNow.setHours(tempDateNow.getHours() + 48))
  const [dateRangeStart, setDateRangeStart] = useState<Date>(tempDateNow)
  const [dateRangeEnd, setDateRangeEnd] = useState<Date>(tempDateFuture)
  const [tempFilter, setTempFilter] = useState<Record<string, string>>({ '1': 'Vancomycin' })
  const [filterItems, setFilterItems] = useState<IMenuItem[]>([])
  const secondLevel: IMenuItem[] = [
    {
      name: tempFilter['1']
    }
  ]

  useEffect(() => {
    //FIXME: to be removed with BE payload
    setFilterItems([{ name: 'drugs', subItems: secondLevel }])
  }, [])

  const getFilterCount = () => {
    return filterItems.reduce<number>((acc, curr) => {
      return acc + (curr.selectedCount || 0)
    }, 0)
  }

  //FIXME: will be updated with BE payload
  const updateFilter = (
    type: string,
    selected: boolean,
    item: IFilterResourceListItem
    // allItems?: IFilterResourceListItem[]
  ) => {
    if (type === 'temp') {
      const newTempFilter = { ...tempFilter }

      //Add
      if (selected) {
        newTempFilter[item.id] = item.name
        setTempFilter(newTempFilter)

        return
      }

      //Remove
      delete newTempFilter[item.id]
      setTempFilter(newTempFilter)

      return
    }
  }

  //FIXME: will be updated with BE payload
  const buildFilterBadges = (): JSX.Element[] => {
    let badges: JSX.Element[] = []

    const tempBadges = Object.keys(tempFilter).map((id: string, key: any) => {
      const name = tempFilter[id]

      return (
        <Badge
          key={id}
          id={id}
          title={decode(name)}
          onDismiss={(id: string) => {
            updateFilter('temp', false, { id: id, name: name })
          }}
        />
      )
    })
    badges = badges.concat(tempBadges)

    return badges
  }

  return (
    <div className='doseme-analytics-panel mb-3'>
      <div className='data-filters-title'>Data filters</div>
      <div className='d-flex'>
        <div className='data-filters-date-range'>
          <div>
            <DatePickerInput
              label='Date range'
              fieldState='valid'
              value={dateRangeStart}
              onChange={(value) => setDateRangeStart(value)}
              showIcon={false}
            />
          </div>
          <div className='end-date-range'>
            <DatePickerInput
              fieldState='valid'
              value={dateRangeEnd}
              onChange={(value) => setDateRangeEnd(value)}
              showIcon={false}
            />
          </div>
        </div>
        <div className='data-filters-context-filters'>
          <div className='patient-list-filter'>
            <MultiLevelMenu
              title='Filters'
              menuItems={filterItems}
              selectedCount={getFilterCount()}
            />
            {buildFilterBadges()}
          </div>
        </div>
      </div>
    </div>
  )
})
