import React from 'react'
import {
  ResponsiveContainer,
  AreaChart,
  CartesianGrid,
  Area,
  Tooltip,
  XAxis,
  YAxis,
  Legend
} from 'recharts'

import { AnalyticsPlot } from '../../AnalyicsPlot'
import { IBasePlotProps, IChartDataSeries } from '../../../types'

export const ReportsByTypePlot: React.FC<IBasePlotProps> = (props) => {
  const renderPlotContent = () => {
    const chartWidth = 652
    const legendWidth = 272
    const totalWidth = chartWidth + legendWidth

    return (
      <ResponsiveContainer width={totalWidth} height={400}>
        <AreaChart
          data={props.plotData}
        >
          <CartesianGrid strokeDasharray='3 3' />

          <XAxis dataKey='name' />

          <YAxis
            tickFormatter={(value) => `${value}%`}
          />

          <Tooltip formatter={(value) => `${value}%`} />

          <Legend
            layout='vertical'
            align='right'
            verticalAlign='top'
            height={36}
            width={legendWidth}
            className='doseme-analytics-legend-panel'
            content={() => (
              <div className='doseme-analytics-legend-panel legend-right'>
                {props.plotName}
                {Object.keys(props.plotSeries).map((key) => (
                  <div key={key} className='doseme-analytics-legend-item legend-right'>
                    <div className='doseme-analytics-legend-item-icon' style={{ backgroundColor: props.plotSeries[key].color }}/>
                    {props.plotSeries[key as keyof IChartDataSeries].label || key}
                  </div>
                ))}
              </div>
            )}
          />

          <Area
            type='monotone'
            dataKey='customDose'
            stackId='1'
            stroke='#7F9FCF'
            fill='#7F9FCF'
            fillOpacity={1}
            name='Custom Dose Report'
          />

          <Area
            type='monotone'
            dataKey='individualized'
            stackId='1'
            stroke='#faa631'
            fill='#faa631'
            fillOpacity={1}
            name='Individualized Report'
          />

          <Area
            type='monotone'
            dataKey='customTarget'
            stackId='1'
            stroke='#aecff7'
            fill='#aecff7'
            fillOpacity={1}
            name='Custom Target Report'
          />

          <Area
            type='monotone'
            dataKey='guideline'
            stackId='1'
            stroke='#fff099'
            fill='#fff099'
            fillOpacity={1}
            name='Guideline Report'
          />
        </AreaChart>
      </ResponsiveContainer>
    )
  }

  return (
    <AnalyticsPlot
      plotId='doseme-analytics-reports-by-type-graph'
      plotData={props.plotData}
      plotSeries={props.plotSeries}
      renderPlotContent={renderPlotContent()}
    />
  )
}
