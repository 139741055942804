import { IDosingMatrixValues } from '@doseme/cohesive-ui'
import { format } from 'date-fns'
import moment from 'moment'

import { dateFnsRawDateOnly, rawTimeOnly } from '../../../../../../../../constants/timeFormat'
import { useFormValidation } from '../../../../../../../../hooks/useFormValidation'
import { ICourseLimits } from '../../../../../../../../store/course/types'
import {
  ICustomDoseSimulationAttributes,
  IDefaultNextDoseAttributes,
  IModelResultsTypes
} from '../../../../../../../../store/dosingRecommendation/types'
import { IGuidelineSimulationAttributes } from '../../../../../../../../types/doseReport'
import { IFormField } from '../../../../../../../../types/validation'
import { stringToFloat, stringToInteger } from '../../../../../../../../utils/validation/formatters'
import {
  isRequired,
  isStringValidNumber,
  isStringWithinNumericLimits,
  isLessThanFourDecimalPlaces,
  isStringValidInteger
} from '../../../../../../../../utils/validation/rules'
import { ICustomTargetForms, ICustomDoseForms } from './types'

export const createCustomTargetForms = (limits: ICourseLimits): ICustomTargetForms => {
  return {
    auc24: useFormValidation({
      auc24: {
        // Input - string
        // Value - number
        initialInput: limits.aucTarget.default.value.toString(),
        initialConstraints: limits.aucTarget,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      infusionLength: {
        // Input - string
        // Value - number
        initialInput: limits.infusionLength.default.value.toString(),
        initialConstraints: limits.infusionLength,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      dosingInterval: {
        // Input - string
        // Value - number
        initialInput: limits.dosingPeriod.default.value.toString(),
        initialConstraints: limits.dosingPeriod,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      numberOfDoses: {
        // Input - string
        // Value - number
        initialInput: limits.numberOfDoses.default.value.toString(),
        initialConstraints: limits.numberOfDoses,
        rules: [isRequired, isStringValidInteger, isStringWithinNumericLimits],
        formatter: stringToInteger
      }
    } as Record<string, IFormField>),
    auc: useFormValidation({
      auc: {
        // Input - string
        // Value - number
        initialInput: limits.aucTarget.default.value.toString(),
        initialConstraints: limits.aucTarget,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      infusionLength: {
        // Input - string
        // Value - number
        initialInput: limits.infusionLength.default.value.toString(),
        initialConstraints: limits.infusionLength,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      dosingInterval: {
        // Input - string
        // Value - number
        initialInput: limits.dosingPeriod.default.value.toString(),
        initialConstraints: limits.dosingPeriod,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      numberOfDoses: {
        // Input - string
        // Value - number
        initialInput: limits.numberOfDoses.default.value.toString(),
        initialConstraints: limits.numberOfDoses,
        rules: [isRequired, isStringValidInteger, isStringWithinNumericLimits],
        formatter: stringToInteger
      }
    } as Record<string, IFormField>),
    'peak/trough': useFormValidation({
      peak: {
        // Input - string
        // Value - number
        initialInput: limits.peakTarget.default.value.toString(),
        initialConstraints: limits.peakTarget,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      trough: {
        // Input - string
        // Value - number
        initialInput: limits.troughTarget.default.value.toString(),
        initialConstraints: limits.troughTarget,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      infusionLength: {
        // Input - string
        // Value - number
        initialInput: limits.infusionLength.default.value.toString(),
        initialConstraints: limits.infusionLength,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      dosingInterval: {
        // Input - string
        // Value - number
        initialInput: limits.dosingPeriod.default.value.toString(),
        initialConstraints: limits.dosingPeriod,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      numberOfDoses: {
        // Input - string
        // Value - number
        initialInput: limits.numberOfDoses.default.value.toString(),
        initialConstraints: limits.numberOfDoses,
        rules: [isRequired, isStringValidInteger, isStringWithinNumericLimits],
        formatter: stringToInteger
      }
    } as Record<string, IFormField>),
    troughonly: useFormValidation({
      trough: {
        // Input - string
        // Value - number
        initialInput: limits.troughTarget.default.value.toString(),
        initialConstraints: limits.troughTarget,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      infusionLength: {
        // Input - string
        // Value - number
        initialInput: limits.infusionLength.default.value.toString(),
        initialConstraints: limits.infusionLength,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      dosingInterval: {
        // Input - string
        // Value - number
        initialInput: limits.dosingPeriod.default.value.toString(),
        initialConstraints: limits.dosingPeriod,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      numberOfDoses: {
        // Input - string
        // Value - number
        initialInput: limits.numberOfDoses.default.value.toString(),
        initialConstraints: limits.numberOfDoses,
        rules: [isRequired, isStringValidInteger, isStringWithinNumericLimits],
        formatter: stringToInteger
      }
    } as Record<string, IFormField>),
    peakonly: useFormValidation({
      peak: {
        // Input - string
        // Value - number
        initialInput: limits.peakTarget.default.value.toString(),
        initialConstraints: limits.peakTarget,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      infusionLength: {
        // Input - string
        // Value - number
        initialInput: limits.infusionLength.default.value.toString(),
        initialConstraints: limits.infusionLength,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      dosingInterval: {
        // Input - string
        // Value - number
        initialInput: limits.dosingPeriod.default.value.toString(),
        initialConstraints: limits.dosingPeriod,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      numberOfDoses: {
        // Input - string
        // Value - number
        initialInput: limits.numberOfDoses.default.value.toString(),
        initialConstraints: limits.numberOfDoses,
        rules: [isRequired, isStringValidInteger, isStringWithinNumericLimits],
        formatter: stringToInteger
      }
    } as Record<string, IFormField>),
    extended_interval: useFormValidation({
      peak: {
        // Input - string
        // Value - number
        initialInput: limits.peakTarget.default.value.toString(),
        initialConstraints: limits.peakTarget,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      trough: {
        // Input - string
        // Value - number
        initialInput: limits.troughTarget.default.value.toString(),
        initialConstraints: limits.troughTarget,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      infusionLength: {
        // Input - string
        // Value - number
        initialInput: limits.infusionLength.default.value.toString(),
        initialConstraints: limits.infusionLength,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      }
    } as Record<string, IFormField>),
    time_above_mic: useFormValidation({
      timeAboveMICPercentage: {
        // Input - string
        // Value - number
        initialInput: limits.timeAboveMICTarget.default.value.toString(),
        initialConstraints: limits.timeAboveMICTarget,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      infusionLength: {
        // Input - string
        // Value - number
        initialInput: limits.infusionLength.default.value.toString(),
        initialConstraints: limits.infusionLength,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      dosingInterval: {
        // Input - string
        // Value - number
        initialInput: limits.dosingPeriod.default.value.toString(),
        initialConstraints: limits.dosingPeriod,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      numberOfDoses: {
        // Input - string
        // Value - number
        initialInput: limits.numberOfDoses.default.value.toString(),
        initialConstraints: limits.numberOfDoses,
        rules: [isRequired, isStringValidInteger, isStringWithinNumericLimits],
        formatter: stringToInteger
      }
    } as Record<string, IFormField>),
    timetotrough: useFormValidation({
      trough: {
        // Input - string
        // Value - number
        initialInput: limits.troughTarget.default.value.toString(),
        initialConstraints: limits.troughTarget,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      doseAmount: {
        // Input - string
        // Value - number
        initialInput: limits.dose.min.value.toString(),
        initialConstraints: limits.dose,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      infusionLength: {
        // Input - string
        // Value - number
        initialInput: limits.infusionLength.default.value.toString(),
        initialConstraints: limits.infusionLength,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      }
    } as Record<string, IFormField>),
    range: useFormValidation({
      peak: {
        // Input - string
        // Value - number
        initialInput: limits.peakTarget.default.value.toString(),
        initialConstraints: limits.peakTarget,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      trough: {
        // Input - string
        // Value - number
        initialInput: limits.troughTarget.default.value.toString(),
        initialConstraints: limits.troughTarget,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      dosingInterval: {
        // Input - string
        // Value - number
        initialInput: limits.dosingPeriod.default.value.toString(),
        initialConstraints: limits.dosingPeriod,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      infusionLength: {
        // Input - string
        // Value - number
        initialInput: limits.infusionLength.default.value.toString(),
        initialConstraints: limits.infusionLength,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      numberOfDoses: {
        // Input - string
        // Value - number
        initialInput: limits.numberOfDoses.default.value.toString(),
        initialConstraints: limits.numberOfDoses,
        rules: [isRequired, isStringValidInteger, isStringWithinNumericLimits],
        formatter: stringToInteger
      }
    } as Record<string, IFormField>),
    cumulative_auc: useFormValidation({
      remainingAUC: {
        // Input - string
        // Value - number
        initialInput: limits.cumulativeAUCTarget.default.value.toString(),
        initialConstraints: limits.cumulativeAUCTarget,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      dosingInterval: {
        // Input - string
        // Value - number
        initialInput: limits.dosingPeriod.default.value.toString(),
        initialConstraints: limits.dosingPeriod,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      infusionLength: {
        // Input - string
        // Value - number
        initialInput: limits.infusionLength.default.value.toString(),
        initialConstraints: limits.infusionLength,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      numberOfDoses: {
        // Input - string
        // Value - number
        initialInput: limits.numberOfDoses.default.value.toString(),
        initialConstraints: limits.numberOfDoses,
        rules: [isRequired, isStringValidInteger, isStringWithinNumericLimits],
        formatter: stringToInteger
      }
    } as Record<string, IFormField>)
  }
}

export const createCustomDoseForms = (
  limits: ICourseLimits,
  dosingMatrixCustomSimulationValue: IDosingMatrixValues | null,
  customTargetModelResult?: IModelResultsTypes | null
): ICustomDoseForms => {
  const doseRecommendation =
    customTargetModelResult?.customTarget?.doseRecommendation?.doseRecommendationAttributes

  return {
    dose: useFormValidation({
      doseAmount: {
        // Input - string
        // Value - number
        initialInput: dosingMatrixCustomSimulationValue
          ? dosingMatrixCustomSimulationValue.amount.toString()
          : customTargetModelResult
            ? customTargetModelResult?.customTarget?.predictedPerDoseOutcomes?.[0].amount.value.toString()
            : limits.dose.min.value.toString(),
        initialConstraints: limits.dose,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      infusionLength: {
        // Input - string
        // Value - number
        initialInput: dosingMatrixCustomSimulationValue
          ? dosingMatrixCustomSimulationValue.infusion.toString()
          : customTargetModelResult
            ? doseRecommendation?.infusionLength.value.toString()
            : limits.infusionLength.default.value.toString(),
        initialConstraints: limits.infusionLength,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      dosingInterval: {
        // Input - string
        // Value - number
        initialInput: dosingMatrixCustomSimulationValue
          ? dosingMatrixCustomSimulationValue.interval.toString()
          : customTargetModelResult
            ? doseRecommendation?.dosingInterval.value.toString()
            : limits.dosingPeriod.default.value.toString(),
        initialConstraints: limits.dosingPeriod,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      numberOfDoses: {
        // Input - string
        // Value - number
        initialInput: dosingMatrixCustomSimulationValue
          ? dosingMatrixCustomSimulationValue.doses.toString()
          : customTargetModelResult
            ? doseRecommendation?.numberOfDoses.toString()
            : limits.numberOfDoses.default.value.toString(),
        initialConstraints: limits.numberOfDoses,
        rules: [isRequired, isStringValidInteger, isStringWithinNumericLimits],
        formatter: stringToInteger
      }
    } as Record<string, IFormField>)
  }
}

export const buildCustomDoseAttributes = (
  customDoseForms: ICustomDoseForms,
  limits: ICourseLimits,
  nextDoseAt: Date,
  hospitalTimezone: string
): ICustomDoseSimulationAttributes => {
  return {
    targetType: 'simulation',
    nextDoseDate: moment
      .tz(format(nextDoseAt, dateFnsRawDateOnly) + 'T' + format(nextDoseAt, rawTimeOnly), hospitalTimezone)
      .utc()
      .toISOString(),
    doseAmount: {
      value: parseFloat(customDoseForms['dose'].values['doseAmount']), //FIXME remove once from validation is fixed
      unit: limits.dose.min.unit
    },
    infusionLength: parseFloat(customDoseForms['dose'].values['infusionLength']), //FIXME remove once from validation is fixed
    dosingInterval: {
      value: customDoseForms['dose'].values['dosingInterval'],
      unit: limits.dosingPeriod.default.unit
    },
    numberOfDoses: customDoseForms['dose'].values['numberOfDoses']
  }
}

export const buildDefaultNextDoseAttributes = (
  nextDoseAt: Date,
  hospitalTimezone: string
): IDefaultNextDoseAttributes => {
  return {
    targetType: 'default',
    nextDoseDate: moment
      .tz(format(nextDoseAt, dateFnsRawDateOnly) + 'T' + format(nextDoseAt, rawTimeOnly), hospitalTimezone)
      .utc()
      .toISOString()
  }
}

export const buildGuidelineDoseAttributes = (
  nextDoseAt: Date,
  hospitalTimezone: string
): IGuidelineSimulationAttributes => {
  return {
    targetType: 'guideline',
    nextDoseDate: moment
      .tz(format(nextDoseAt, dateFnsRawDateOnly) + 'T' + format(nextDoseAt, rawTimeOnly), hospitalTimezone)
      .utc()
      .toISOString()
  }
}
