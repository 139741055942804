import { ObservableMap, makeAutoObservable, observable } from 'mobx'

import axiosClient, { AxiosError, AxiosResponse } from '../../../utils/axiosClient'
import { RootStore } from '../../RootStore'
import { TLoadState } from '../../types'
import {
  adminHospitalDrugModelListGetUrl,
  adminHospitalAddDrugsGetUrl,
  adminHospitalAddDrugsPostUrl,
  adminHospitalDrugDetailsDeleteUrl
} from '../../../constants/api'
import { IAdminHospitalAddDrugsListItem, IAdminHospitalDrugListItem } from './types'
import { AdminHospitalAddDrugsListItem } from './AdminHospitalAddDrugsListItem'
import { AdminHospitalDrugListItem } from './AdminHospitalDrugListItem'

export class AdminHospitalDrugListStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  error: string = ''

  settingsLoadStates: Record<string, TLoadState> = {
    addDrugs: 'initial'
  }

  settingsErrors: Record<string, string> = {
    addDrugs: ''
  }

  adminHospitalDrugs: ObservableMap<string, IAdminHospitalDrugListItem> = observable.map({}, { deep: false })
  adminHospitalAddDrugs: ObservableMap<string, IAdminHospitalAddDrugsListItem> = observable.map({}, { deep: false })

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  resetStore() {
    this.adminHospitalDrugs = observable.map({}, { deep: false })
    this.adminHospitalAddDrugs = observable.map({}, { deep: false })

    this.settingsLoadStates = {
      addDrugs: 'initial'
    }

    this.settingsErrors = {
      addDrugs: ''
    }

    this.error = ''

    this.setLoadState('initial')
  }

  setLoadStateSettings(loadState: TLoadState, setting: string) {
    this.settingsLoadStates[setting] = loadState
  }

  setErrorSettings(errorState: 'loadError' | 'updateError', error: string, setting: string) {
    this.settingsErrors[setting] = error
    this.setLoadStateSettings(errorState, setting)
  }

  resetAdminHospitalDrugs(adminHospitalDrugs: IAdminHospitalDrugListItem[]) {
    this.adminHospitalDrugs = observable.map({}, { deep: false })
    adminHospitalDrugs.forEach((drug) =>
      this.adminHospitalDrugs.set(drug.id, new AdminHospitalDrugListItem(this, drug))
    )
  }

  resetAdminHospitalAddDrugs(adminHospitalAddDrugs: IAdminHospitalAddDrugsListItem[]) {
    this.adminHospitalAddDrugs = observable.map({}, { deep: false })
    adminHospitalAddDrugs.forEach((drug) =>
      this.adminHospitalAddDrugs.set(drug.id, new AdminHospitalAddDrugsListItem(this, drug))
    )
  }

  removeSingleDrug(id: string) {
    this.adminHospitalDrugs.delete(id)
  }

  async fetchAdminHospitalDrugModelList(hospitalId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IAdminHospitalDrugListItem[]>>(adminHospitalDrugModelListGetUrl(hospitalId), { headers })
      .then((response: AxiosResponse) => {
        this.resetAdminHospitalDrugs(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  async fetchAdminHospitalAddDrugs(hospitalId: string) {
    this.setLoadStateSettings('loading', 'addDrugs')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IAdminHospitalAddDrugsListItem[]>>(adminHospitalAddDrugsGetUrl(hospitalId), { headers })
      .then((response: AxiosResponse) => {
        this.resetAdminHospitalAddDrugs(response.data.data)
        this.setLoadStateSettings('loaded', 'addDrugs')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setErrorSettings('loadError', loggableError, 'addDrugs')
      })
  }

  async postAdminHospitalAddDrugs(hospitalId: string, drugModelIds: number[]) {
    this.setLoadStateSettings('loading', 'addDrugs')
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .post<AxiosResponse<IAdminHospitalDrugListItem[]>>(
        adminHospitalAddDrugsPostUrl(hospitalId),
        {
          data: {
            type: 'adminHospitalDrugModelsAddMultiple',
            attributes: {
              drugModelIds: drugModelIds
            }
          }
        },
        { headers }
      )
      .then((response: AxiosResponse) => {
        this.resetAdminHospitalDrugs(response.data.data)
        this.setLoadStateSettings('loaded', 'addDrugs')
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)
        this.setErrorSettings('loadError', loggableError, 'addDrugs')
      })
  }

  async deleteAdminHospitalDrugModel(hospitalId: string, drugModelId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .delete<AxiosResponse<IAdminHospitalDrugListItem>>(adminHospitalDrugDetailsDeleteUrl(hospitalId, drugModelId), {
        headers
      })
      .then(() => {
        this.removeSingleDrug(drugModelId)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }
}
