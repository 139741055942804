import React from 'react'
import { JSX } from 'react/jsx-runtime'
import { TooltipProps } from 'recharts'
import { ValueType, NameType, Payload } from 'recharts/types/component/DefaultTooltipContent'

import { IAnalyticsPlotProps, ITooltipDataItem } from '../types'

export const AnalyticsPlot: React.FC<IAnalyticsPlotProps> = (props) => {

  const renderPlotContent = () => {
    throw new Error('Child component must override the renderPlotContent method.')
  }

  return (
    <div data-testid={props.plotId} className={props.plotClass ? props.plotClass : props.plotId}>
      {props.renderPlotContent ? props.renderPlotContent : renderPlotContent()}
    </div>
  )
}

export interface IAnalyticsTooltip extends TooltipProps<ValueType, NameType> {
  dataName: string[]
}

export interface IAnalyticsMultiTooltip extends TooltipProps<ValueType, NameType> {
  dataName: string[]
  subLabel?: string
}

export const AnalyticsTooltip = ({
  active,
  payload,
  label,
  dataName
}: IAnalyticsTooltip) => {

  let tooltipContent: JSX.Element[] = []

  if (active && payload && payload.length) {
    payload.forEach((e: Payload<ValueType, NameType>, index) => {
      if (!e) return null // Skip this iteration if data point is falsy

      if (e.dataKey && dataName.includes(e.dataKey.toString())) {
        let toolTipDataItem = e.payload.tooltip[e.dataKey] as ITooltipDataItem

        tooltipContent.push(
          <div className='doseme-analytics-tooltip' key={`tooltip-${e.payload.name}-${index}`}>
            <div className='tooltip-title'>{toolTipDataItem.title !== '' ? toolTipDataItem.title : label}</div>
            <div className='tooltip-content'>
              { toolTipDataItem.prefix !== '' ? <span className='tooltip-prefix'>{toolTipDataItem.prefix}&nbsp;</span> : ''}
              <span className='tooltip-record-count' style={{ color: e.color || e.payload.color }}><a href='#'>{toolTipDataItem.value || toolTipDataItem.records?.length}</a></span>
              {toolTipDataItem.suffix !== '' ? <span className='tooltip-suffix'>&nbsp;{toolTipDataItem.suffix}</span> : ''}
            </div>
          </div>
        )
      }
    })
  }

  return (
    <div className='custom-tooltip-outer'>
      {tooltipContent}
    </div>
  )
}

export const AnalyticsMultiTooltip = ({
  active,
  payload,
  label,
  subLabel,
  dataName
}: IAnalyticsMultiTooltip) => {
  let tooltipContent: JSX.Element[] = []

  if (active && payload && payload.length) {

    payload.forEach((e: Payload<ValueType, NameType>, index) => {
      if (!e) return null // Skip this iteration if data point is falsy

      if (e.dataKey && dataName.includes(e.dataKey.toString())) {
        let toolTipDataItem = e.payload.tooltip[e.dataKey] as ITooltipDataItem

        tooltipContent.push(
          <div className='doseme-analytics-tooltip' key={`tooltip-${e.payload.name}-${index}`}>
            <div className='tooltip-content'>
              { toolTipDataItem.prefix !== '' ? <span className='tooltip-prefix' style={{ color: e.color || e.payload.color }}>{toolTipDataItem.prefix}&nbsp;</span> : ''}
              <span className='tooltip-record-count'><b><a href='#'>{toolTipDataItem.value || toolTipDataItem.records?.length}</a></b></span>
              {toolTipDataItem.suffix !== '' ? <span className='tooltip-suffix'>&nbsp;{toolTipDataItem.suffix}</span> : ''}
            </div>
          </div>
        )
      }
    })
  }

  return (
    <div className='custom-tooltip-outer'>
      <div className='tooltip-title'>
        {label}

        {subLabel && (
          <>
            <br />
            <div className='tooltip-subtitle'>{subLabel}</div>
          </>
        )}
      </div>

      {tooltipContent}
    </div>
  )
}

export const AnalyticsMultiTooltipsSingleDataPoint = ({
  active,
  payload,
  dataName
}: IAnalyticsTooltip) => {
  let tooltipContent: JSX.Element[] = []
  let title = ''

  if (active && payload && payload.length) {
    payload.forEach((e: Payload<ValueType, NameType>, index) => {
      if (!e) return null // Skip this iteration if data point is falsy

      if (e.dataKey && dataName.includes(e.dataKey.toString())) {
        let toolTipDataItem = e.payload.tooltip[e.dataKey] as ITooltipDataItem

        if (title === '') {
          title = e.payload.tooltip.title ? e.payload.tooltip.title : ''
        }

        tooltipContent.push(
          <div className='doseme-analytics-tooltip' key={`tooltip-${e.payload.name}-${index}`}>
            <div className='tooltip-content'>
              { toolTipDataItem?.prefix !== '' ? <span className='tooltip-prefix' style={{ color: e.color || e.payload.color }}>{toolTipDataItem?.prefix}&nbsp;</span> : ''}
              <span className='tooltip-record-count'><b><a href='#'>{toolTipDataItem.value || toolTipDataItem.records?.length}</a></b></span>
              {toolTipDataItem?.suffix !== '' ? <span className='tooltip-suffix'>&nbsp;{toolTipDataItem.suffix}</span> : ''}
            </div>
          </div>
        )
      }
    })
  }

  return (
    <div className='custom-tooltip-outer'>
      <div className='tooltip-title'>
        {title}
      </div>
      {tooltipContent}
    </div>
  )
}
