import { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation, useMatches } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Location } from 'history'
import { useIntercom } from 'react-use-intercom'
import { decode } from 'he'
import { DOSEME_BLUE, IListItem, Icons, MenuDropdown, NavMenuBar, NavUtils, BreadcrumbsBar } from '@doseme/cohesive-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

import { showErrorToast } from '../../shared/toast'
import { useAuthStore, useClinicianStore, usePatientStore } from '../../hooks/useStore'
import { HelpRequestModal } from './components/HelpRequestModal'
import { HelpRequestButton, HelpRequestButtonIntercom } from './components/HelpRequestButton'
import { ChangeHospitalModal } from './components/ChangeHospitalModal'
import { buildBreadcrumbs } from '../../routing/utils'

import './index.scss'

export interface ILink {
  displayText: string
  linkHref: string
  external?: boolean
  dangerouslyInsertedHtml?: string
  onClick?: () => void
  isActive?: boolean
}

export const TopMenuBar: React.FC = observer(() => {
  const authStore = useAuthStore()
  const clinicianStore = useClinicianStore()
  const patientStore = usePatientStore()
  const { shutdown } = useIntercom()

  const navigate = useNavigate()
  const location = useLocation()
  const matches = useMatches()

  const matchCrumbs = matches
    //@ts-ignore
    .filter((match) => match.handle?.crumb)
    //@ts-ignore
    .map((match: any) => {
      return match.handle?.crumb
    })
  const [crumbs, loadingCrumbs] = buildBreadcrumbs(matchCrumbs, navigate)

  const [showHelpRequestModal, setShowHelpRequestModal] = useState(false)
  const [showChangeHospitalModal, setShowChangeHospitalModal] = useState(false)
  const [showMenuDropdown, setShowMenuDropdown] = useState(false)

  useEffect(() => {
    if (clinicianStore.loadState !== 'loaded' && authStore.isAuthenticated()
      && authStore.auth?.attributes.clinicianId) {
      clinicianStore.fetchClinician(authStore.auth?.attributes.clinicianId)
    }
  }, [authStore.loadState])

  const logOut = async () => {
    //FIXME IFE-682 - extended this function to clear all stores on logout
    await authStore.authLogout()
    if (['loadError', 'updateError'].includes(authStore.loadState)) {
      showErrorToast(authStore.error || 'Failed to log out')

      return
    }

    // Shuts down the Intercom instance on logout
    shutdown()

    navigate('/login')
  }

  const hasActiveRoot = (location: Location, root: string) => {
    if (authStore.loadState === 'loaded') {
      return location.pathname === root
    }
  }

  const navLinks = (location: Location, patientId?: string): ILink[] => {
    let navLinks = window.env.APP_MODE === 'integrated'
      ? [
        {
          displayText: 'Patient Profile',
          linkHref: patientId ? `/patients/${patientId}` : location.pathname,
          external: false
        }
      ]
      : []

    if (clinicianStore.canViewAnalyticsHub()) {
      if (window.env.SHOW_DMA) {
        navLinks = navLinks.concat([
          {
            displayText: 'DoseMe Analytics',
            linkHref: '/dosemeanalytics',
            external: false
          }
        ])
      }

      navLinks = navLinks.concat([
        {
          displayText: 'Analytics',
          linkHref: '/analytics',
          external: false
        }
      ])
    }

    navLinks = navLinks.concat([
      {
        displayText: 'Resources',
        linkHref: patientId
          ? `/patients/${patientId}/resources`
          : '/resources',
        external: false
      }
    ])

    if (window.env.APP_MODE === 'standalone'
      && (window.env.VENDOR_MODE === 'standalone' || clinicianStore.clinician?.attributes.isSuperAdmin)) {
      navLinks.unshift(
        {
          displayText: 'Patients',
          linkHref: '/patients',
          external: false
        }
      )
    }

    return navLinks
  }

  const getUserNavMenuDropdownItems = (): IListItem[] => {
    const items: IListItem[] = []

    if (window.env.APP_MODE === 'standalone') {
      items.push(
        {
          id: 0,
          text: 'My profile',
          onClick: () => navigate('/cliniciansettings')
        }
      )

      if (clinicianStore.loadState === 'loaded' && clinicianStore.clinician?.attributes.hospitals.length
        && clinicianStore.clinician?.attributes.hospitals.length > 1) {
        items.push(
          {
            id: 1,
            text: 'Change hospital',
            onClick: () => setShowChangeHospitalModal(true)
          }
        )
      }
    }

    if (clinicianStore.canViewAdminHub()) {
      items.push(
        {
          id: 2,
          text: 'Admin hub',
          onClick: () => navigate('/admin')
        }
      )
    }

    if (window.env.APP_MODE === 'standalone') {
      items.push(
        {
          id: 4,
          icon: <Icons.Logout width={24} height={24} />,
          text: 'Log out',
          onClick: () => logOut()
        }
      )
    }

    return items
  }

  const getUserNavMenuDropdown = (): JSX.Element => {
    if (window.env.APP_MODE === 'standalone') {
      const clinicianName =
        authStore.auth?.attributes.clinicianFirstName && authStore.auth?.attributes.clinicianLastName
          ? decode(authStore.auth?.attributes.clinicianFirstName + ' ' + authStore.auth?.attributes.clinicianLastName)
          : ''

      const initials = clinicianName ? clinicianName[0] + clinicianName.split(' ')[1][0] : ''

      return (
        <div key='user-nav-menu-dropdown' className='top-menu-bar-drop-down'>
          <MenuDropdown
            setShow={setShowMenuDropdown}
            show={showMenuDropdown}
            dropdownItems={getUserNavMenuDropdownItems()}
            header={clinicianName}
            iconRight
            icon={initials ? initials : <FontAwesomeIcon className='' size='lg' color={DOSEME_BLUE} icon={faUser} />}
          />
        </div>
      )
    }

    return <></>
  }

  const getRequestHelpButton = (patientId?: string): JSX.Element => {
    if (window.env.INTERCOM_APP_ID && !['cerner', 'epic'].includes(window.env.VENDOR_MODE)) {
      return <HelpRequestButtonIntercom key='help-request-button-intercom' patientId={patientId} />
    }

    return <HelpRequestButton key='help-request-button' setShowHelpRequestModal={setShowHelpRequestModal} />
  }

  const constructNavElements = (location: Location, patientId?: string): JSX.Element[] => {
    if (window.location.toString().includes('/help')) {
      return [<div key='help-page-no-links'></div>]
    }

    if (!authStore.isAuthenticated()) {
      return window.env.APP_MODE === 'standalone'
        ? [
          <NavUtils.LinkWrapper key={'nav-link-0'}>
            <Link to={'/login'} onClick={logOut}>Log in</Link>
          </NavUtils.LinkWrapper>
        ]
        : [
          getRequestHelpButton(patientId)
        ]
    }

    const elements = navLinks(location, patientId)

    let navElements = elements.map((navLink, idx) => {
      if (navLink.external) {
        return (
          <NavUtils.LinkWrapper key={`nav-link-${idx}`}>
            <a href={navLink.linkHref}>{navLink.displayText}</a>
          </NavUtils.LinkWrapper>
        )
      }

      if (hasActiveRoot(location, navLink.linkHref)) {
        return (
          <NavUtils.LinkWrapper key={`nav-link-${idx}`} active>
            <Link to={navLink.linkHref}>{navLink.displayText}</Link>
          </NavUtils.LinkWrapper>
        )
      }

      return (
        <NavUtils.LinkWrapper key={`nav-link-${idx}`}>
          <Link to={navLink.linkHref}>{navLink.displayText}</Link>
        </NavUtils.LinkWrapper>
      )
    })

    // Display the "Request Help" button if the APP_MODE is integrated
    if (window.env.APP_MODE === 'integrated') {
      navElements = navElements.concat(getRequestHelpButton(patientId))
    }

    return navElements.concat(getUserNavMenuDropdown())
  }

  return (
    <>
      <ChangeHospitalModal show={showChangeHospitalModal} setShow={setShowChangeHospitalModal} />
      <HelpRequestModal show={showHelpRequestModal} setShow={setShowHelpRequestModal} />
      <NavMenuBar
        logoVariant={window.env.INSTANCE_TYPE}
        navElements={constructNavElements(location, authStore.auth?.attributes.patientId || patientStore.patient?.id)} />
      {crumbs.length > 0 &&
        <BreadcrumbsBar breadcrumbs={crumbs} loading={loadingCrumbs} />
      }
    </>
  )
})
