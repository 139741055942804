import { AnalyticsPlotSkeleton, MessageOverlay } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCog } from '@fortawesome/free-solid-svg-icons'
// import { ListButton } from '@doseme/cohesive-ui'
import { useAnalyticsChartDailyUsageStore } from '../../../../hooks/useStore'
import { IBaseChartProps } from '../../types'
import { DailyUsagePlot } from './components'
import { formatErrorMessage } from '../../../PatientRouter/components/CourseProfile/components/DoseRecommendation/utils'

export const DailyUsage: React.FC<IBaseChartProps> = observer((props) => {
  const dailyUsageChartStore = useAnalyticsChartDailyUsageStore()

  useEffect(() => {
    dailyUsageChartStore.fetchAnalyticsChart(
      props.startDate,
      props.endDate,
      props.hospitals
    )
  }, [props.hospitals, props.startDate, props.endDate])

  const plotContent = () => {
    if (
      dailyUsageChartStore.analyticsChartData
      && dailyUsageChartStore.loadState === 'loaded'
    ) {
      return (
        <div className='doseme-analytics-chart-plot'>
          <DailyUsagePlot
            plotData={dailyUsageChartStore.analyticsChartData.attributes.plotData.dataPoints}
            plotSeries={dailyUsageChartStore.analyticsChartData.attributes.plotData.plotSeries}
            plotName={dailyUsageChartStore.analyticsChartData.attributes.name}
          />
        </div>
      )
    }

    if (['loadError, updateError'].includes(dailyUsageChartStore.loadState)) {
      return (
        <MessageOverlay
          type='error'
          headerText='Failed to load analytics chart'
          messageText={
            <div className='panel-error-overlay-text'>
              {formatErrorMessage(dailyUsageChartStore.error) || 'Something went wrong'}
            </div>
          }
          showBlur={false}
        >
          <AnalyticsPlotSkeleton
            loading={false}
            style={{ width: '924px', height: '284px' }}
          />
        </MessageOverlay>
      )
    }

    return <AnalyticsPlotSkeleton loading={dailyUsageChartStore.loadState === 'loading'} style={{ width: '924px', height: '284px' }}/>
  }

  // Normally we would be issuing a GET request via our axiosClient to the appropriate chart endpoint
  // For now we have some mockData to pass on to the Plot component
  return (
    <div className='doseme-analytics-chart'>
      <div className='header-row'>
        <div className='title md-8'>Daily total usage trend</div>
        <div className='view-options md-4'>
          {/*<ListButton disabled={true}>*/}
          {/*  <FontAwesomeIcon icon={faCog} />&nbsp;View Options*/}
          {/*</ListButton>*/}
        </div>
      </div>
      {plotContent()}
    </div>
  )
})
