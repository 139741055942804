import { neutrals900 } from '@doseme/cohesive-ui'

interface IProps {
  isLegendIcon?: boolean
  markerWidth?: number
  markerHeight?: number
  cx?: number
  cy?: number
}

export const ExcludedObservationMarker: React.FC<IProps> = (props) => {
  const viewBox = [0, 0, 40, 40]

  if (!props.isLegendIcon) {
    if (!props.cx || isNaN(props.cx)) {
      return null
    }

    if (!props.cy || isNaN(props.cy)) {
      return null
    }
  }

  const width = props.markerWidth || 16
  const height = props.markerHeight || 16

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox.join(' ')}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      x={props.cx ? props.cx - width / 2 : undefined}
      y={props.cy ? props.cy - height / 2 : undefined}
    >
      <g aria-label='dot' fill='none' stroke={neutrals900} stroke-width='5' transform='translate(0.5,0.5)'>
        <path transform='translate(20,19.5)' d='M0,-13.32L13.32,0L0,13.32L-13.32,0Z'></path>
      </g>
    </svg>
  )
}
