import { ObservableMap, makeAutoObservable, observable } from 'mobx'

import axiosClient, { AxiosError, AxiosResponse } from '../../../utils/axiosClient'
import { RootStore } from '../../RootStore'
import { TLoadState } from '../../types'
import { adminVendorsDeleteUrl, adminVendorsGetUrl, adminVendorPostUrl } from '../../../constants/api'
import { IAdminVendorListItem } from './types'
import { AdminVendorListItem } from './AdminVendorListItem'
import { IAdministration } from '../../administrations/types'

export class AdminVendorListStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  error: string = ''

  adminVendors: ObservableMap<string, IAdminVendorListItem> = observable.map({}, { deep: false })

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  resetStore() {
    this.adminVendors = observable.map({}, { deep: false })
    this.error = ''

    this.setLoadState('initial')
  }

  removeSingleVendor(id: string) {
    this.adminVendors.delete(id)
  }

  async deleteVendor(vendorId: string) {
    this.setLoadState('updating')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .delete<AxiosResponse<IAdministration>>(adminVendorsDeleteUrl(vendorId), { headers })
      .then(() => {
        this.removeSingleVendor(vendorId)
        this.setLoadState('loaded')

        return null
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)

        return null
      })

    return null
  }

  resetAdminVendors(adminVendors: IAdminVendorListItem[]) {
    this.adminVendors = observable.map({}, { deep: false })
    adminVendors.forEach((vendor) => this.adminVendors.set(vendor.id, new AdminVendorListItem(this, vendor)))
  }

  async fetchVendors() {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IAdminVendorListItem[]>>(adminVendorsGetUrl(), { headers })
      .then((response: AxiosResponse) => {
        this.resetAdminVendors(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  async createVendor(vendorName: string, vendorKey: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    return await axiosClient
      .post<AxiosResponse<IAdminVendorListItem[]>>(
        adminVendorPostUrl(),
        {
          data: {
            type: 'adminVendorNewWrite',
            attributes: {
              name: vendorName,
              key: vendorKey
            }
          }
        },
        { headers }
      )
      .then((response: AxiosResponse) => {
        this.resetAdminVendors(response.data.data)
        this.setLoadState('loaded')
        if (response.data.data.length) {
          return response.data.data[0].id
        }

        return null
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }
}
