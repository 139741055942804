export const akiRiskIndicatorsMockData = {
  data: {
    type: '',
    attributes: {
      name: 'AKI risk indicators',
      plotData: {
        plotSeries: {
          kdigo: { label: 'KDIGO', type: 'line', color: '#89d59b' },
          akin: { label: 'AKIN', type: 'line', color: '#116985' },
          idsa: { label: 'IDSA', type: 'line', color: '#E15759' }
        },
        dataPoints: [
          {
            name: 'June 2024',
            date: '2024-06-01',
            kdigo: 6,
            akin: 8,
            idsa: 4,
            tooltip: {
              kdigo: { title: 'June 2024', prefix: 'KDIGO* courses', suffix: '(4.88%)', value: '6/123' },
              akin: { title: 'June 2024', prefix: 'AKIN* courses', suffix: '(6.5%)', value: '8/123' },
              idsa: { title: 'June 2024', prefix: 'IDSA courses', suffix: '(3.25%)', value: '4/123' }
            }
          },
          {
            name: 'July 2024',
            date: '2024-07-01',
            kdigo: 9,
            akin: 10,
            idsa: 2,
            tooltip: {
              kdigo: { title: 'July 2024', prefix: 'KDIGO* courses', suffix: '(9.78%)', value: '9/92' },
              akin: { title: 'July 2024', prefix: 'AKIN* courses', suffix: '(10.87%)', value: '10/92' },
              idsa: { title: 'July 2024', prefix: 'IDSA courses', suffix: '(2.17%)', value: '2/92' }
            }
          },
          {
            name: 'August 2024',
            date: '2024-08-01',
            kdigo: 14,
            akin: 12,
            idsa: 1,
            tooltip: {
              kdigo: { title: 'August 2024', prefix: 'KDIGO* courses', suffix: '(13.33%)', value: '14/105' },
              akin: { title: 'August 2024', prefix: 'AKIN* courses', suffix: '(11.43%)', value: '12/105' },
              idsa: { title: 'August 2024', prefix: 'IDSA courses', suffix: '(0.95%)', value: '1/105' }
            }
          }
        ]
      }
    }
  },
  metadata: {
    filters: [
      { name: 'hospitals', value: ['1234'] },
      { name: 'startDate', value: '2024-07-01' },
      { name: 'endDate', value: '2024-09-30' }
    ]
  }
}
