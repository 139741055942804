import { action, makeObservable, observable } from 'mobx'

import axiosClient, { AxiosError, AxiosResponse } from '../../utils/axiosClient'
import { AnalyticsRootStore } from '../AnalyticsRootStore/AnalyticsRootStore'
import { TLoadState } from '../types'
import { AnalyticsChart } from './AnalyticsChart'
import { IAnalyticsChartResponse } from './types'

export abstract class AnalyticsChartStore {
  rootStore: AnalyticsRootStore

  loadState: TLoadState = 'initial'
  error: string = ''

  analyticsChartData: AnalyticsChart['data'] | null = null
  analyticsChartMetadata: AnalyticsChart['metadata'] | null = null

  url: string = ''

  constructor(rootStore: AnalyticsRootStore, url?: string) {
    makeObservable(this, {
      analyticsChartData: observable,
      loadState: observable,
      error: observable,
      setAnalyticsChart: action,
      setLoadState: action,
      setError: action,
      resetStore: action,
      fetchAnalyticsChart: action
    })

    this.url = url || ''
    this.rootStore = rootStore
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  resetStore() {
    this.analyticsChartData = null
    this.analyticsChartMetadata = null
    this.error = ''

    this.setLoadState('initial')
  }

  setAnalyticsChart(analyticsChartAttrs: IAnalyticsChartResponse) {
    const analyticsChart = new AnalyticsChart(this, analyticsChartAttrs)
    this.analyticsChartData = analyticsChart.data
    this.analyticsChartMetadata = analyticsChart.metadata
  }

  //FIXME - IFE-1316 - add the filters as queryparams (or however the back-end is expecting them to be sent)
  async fetchAnalyticsChart(startDate: Date, endDate: Date, hospitals: string[]) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    const params = new URLSearchParams()
    params.append('startDate', startDate.toISOString().slice(0, 10))
    params.append('endDate', endDate.toISOString().slice(0, 10))
    hospitals.forEach((hospital) => {
      params.append('hospitals', hospital)
    })

    await axiosClient
      .get<AxiosResponse<IAnalyticsChartResponse>>(this.url, { headers, params })
      .then((response: AxiosResponse) => {
        this.setAnalyticsChart(response.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.analyticsErrorStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }
}
