import { useEffect, useState } from 'react'
import { Skeleton } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'

import { useAuthStore, usePatientCoursesStore } from '../../hooks/useStore'

import './components/PatientProfile/index.scss'

export const PatientLoadingPage: React.FC = observer(() => {
  const authStore = useAuthStore()
  const patientCoursesStore = usePatientCoursesStore()

  const [firstLoad, setFirstLoad] = useState<boolean>(true)
  const [subtitle, setSubtitle] = useState<string>('')
  const [message, setMessage] = useState<string | undefined>('')

  useEffect(() => {
    if (authStore.auth?.attributes.loadMessage) {
      const message1 = setTimeout(() => {
        setSubtitle('Searching for relevant data, please wait a moment.')
        setMessage(authStore.auth?.attributes.loadMessage)
      }, 10000)

      const message2 = setTimeout(() => {
        setSubtitle('Fetching all relevant data, this may take some time.')
        setMessage(undefined)
      }, 30000)

      const message3 = setTimeout(() => {
        setSubtitle('We’re still working on it, please don’t close this window just yet.')
      }, 60000)

      const message4 = setTimeout(() => {
        setSubtitle('Still searching through older records, please bear with us.')
      }, 150000)

      return () => {
        clearTimeout(message1)
        clearTimeout(message2)
        clearTimeout(message3)
        clearTimeout(message4)
      }
    }
  }, [])

  useEffect(() => {
    if (patientCoursesStore.loadState === 'loaded' && firstLoad) {
      setFirstLoad(false)
    }
  }, [patientCoursesStore.loadState])

  if (patientCoursesStore.loadState === 'initial' || patientCoursesStore.loadState === 'loading') {
    if (firstLoad && authStore.auth?.attributes.loadMessage && window.env.VENDOR_MODE !== 'standalone') {
      return (
        <div className='h-100'>
          <Skeleton.InitialLoad title='Processing EHR data...' subtitle={subtitle} message={message} />
        </div>
      )
    }

    return (
      <div className='h-100'>
        <Skeleton.InitialLoad />
      </div>
    )
  }

  return (
    <>
      <Skeleton.PatientProfile showConnectionStatus={window.env.APP_MODE === 'integrated'} />
    </>
  )
})
