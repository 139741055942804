import { makeAutoObservable } from 'mobx'

import axiosClient, { AxiosError, AxiosResponse } from '../../utils/axiosClient'
import {
  AUTH_GET_PASSWORD_RULES
} from '../../constants/api'
import { RootStore } from '../RootStore'
import { TLoadState } from '../types'
import { PasswordRules } from './PasswordRules'
import { IPasswordRules } from './types'

export class PasswordRulesStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  error: string = ''

  passwordRules: PasswordRules | null = null

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  resetStore() {
    this.passwordRules = null
    this.error = ''

    this.setLoadState('initial')
  }
  setPasswordRules(passwordRules: IPasswordRules) {
    this.passwordRules = new PasswordRules(this, passwordRules)
  }

  async getPasswordRules() {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    return axiosClient
      .get<AxiosResponse<IPasswordRules>>(AUTH_GET_PASSWORD_RULES, { headers })
      .then((response: AxiosResponse) => {
        if (response.data.data) {
          const passwordRules = response.data.data
          this.setPasswordRules(passwordRules)
        }

        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }
}
