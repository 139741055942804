import { useSearchParams } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Icons } from '@doseme/cohesive-ui'

import { getDoseMeRxIntegratedURL } from './utils'
import { dosemeEmail, dosemePhoneNumber, IntercomCustomAttributes } from './constants'

import './index.scss'

export const HelpPage: React.FC = observer(() => {
  const { boot, show } = useIntercom()

  const [searchParams] = useSearchParams()

  useEffect(() => {
    const showChat = searchParams.get('chat')
    if (showChat === 'show') {
      displayIntercom()
    }
  }, [])

  const displayIntercom = () => {
    let customAttributes: IntercomCustomAttributes = {
      instance: window.env.INSTALLATION,
      vendor: window.env.VENDOR_MODE
    }

    const clinicianName = searchParams.get('clinicianName')
    const clinicianId = searchParams.get('clinicianId')
    const dosemeHospitalId = searchParams.get('dosemeHospitalId')
    const dosemeHospitalName = searchParams.get('dosemeHospitalName')
    const dosemePatientId = searchParams.get('dosemePatientId')
    const dosemeCourseId = searchParams.get('dosemeCourseId')
    const instance = searchParams.get('instance')
    const vendor = searchParams.get('vendor')

    if (
      clinicianName &&
      clinicianId &&
      dosemeHospitalId &&
      dosemeHospitalName &&
      dosemePatientId &&
      instance &&
      vendor
    ) {
      const path = `/patients/${dosemePatientId}${dosemeCourseId ? `/courses/${dosemeCourseId}` : ''}`
      const helpRequestLink = getDoseMeRxIntegratedURL() + path

      customAttributes = {
        patient_id: dosemePatientId,
        instance: instance,
        vendor: vendor,
        ehr_support_link: helpRequestLink,
        company: {
          company_id: `${window.env.INSTALLATION}/${dosemeHospitalId}`,
          name: dosemeHospitalName
        }
      }
    }

    boot({
      name: clinicianName || undefined,
      customAttributes
    })

    show()
  }

  return (
    <div className='help-page-panel'>
      <div className='help-page-title-block'>Contact the DoseMeRx team</div>
      <div className='help-page-content-block'>
        <div className='help-page-customer-subtitle'>24/7 customer support</div>
        <div className='help-page-contact-subtitle'>Contact us anytime, day or night, We're here to help!</div>
        <div className='help-page-contact-block'>
          <div className='contact-block-icon'>
            <Icons.MailIcon />
          </div>
          <div className='contact-block-info'>
            <div className='title'>Email:</div>
            <div className='link'>{<a href={`mailto:${dosemeEmail}`}>{dosemeEmail}</a>}</div>
          </div>
        </div>
        <div className='help-page-contact-block'>
          <div className='contact-block-icon'>
            <Icons.PhoneIcon />
          </div>
          <div className='contact-block-info'>
            <div className='title'>Phone:</div>
            <div className='link'>
              <a href={`tel:${dosemePhoneNumber}`}>{dosemePhoneNumber}</a>
            </div>
          </div>
        </div>
        <div className='help-page-contact-block'>
          <div className='contact-block-icon'>
            <Icons.ChatIcon />
          </div>
          <div className='contact-block-info'>
            <div className='title'>Live chat:</div>
            <div className='link' onClick={() => displayIntercom()}>
              Chat to us live now ↘
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
