import { Icons } from '@doseme/cohesive-ui'

import { IProps } from './types'

export const ModelFitIndicatorIcons: React.FC<IProps> = (props) => {
  const unavailableModelFit = !props.modelFitStatus || props.modelFitStatus?.message

  if (unavailableModelFit) {
    return <Icons.UnavailableCircle width={16} height={16}/>
  }

  if (props.modelFitStatus?.hasGoodModelFit) {
    return <Icons.SuccessCircle width={16} height={16} />
  }

  return <Icons.AlertCircle width={16} height={16} />
}
