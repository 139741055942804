import { decode } from 'he'
import { Icons, neutrals200, Tag } from '@doseme/cohesive-ui'

import './index.scss'

interface ITitleProps {
  patientName: string
  isArchived: boolean
  patientId?: string
}

export const PatientNameTitle: React.FC<ITitleProps> = (props) => {
  return (
    <div className='patient-info-name-div'>
      <span className='patient-info-name'>{decode(props.patientName || '')}</span>
      {window.env.VENDOR_MODE === 'standalone' && props.patientId &&
        <span className='patient-info-id'>ID:&nbsp;{props.patientId}</span>
      }
      {window.env.VENDOR_MODE === 'standalone' && props.isArchived &&
        <Tag
          icon={<Icons.Archive />}
          color={neutrals200}
          size='large'
          text='Archived'
        />
      }
    </div>
  )
}
