import React from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis
} from 'recharts'
import { blueSteel500, neutrals300, neutrals50, neutrals500, neutrals700 } from '@doseme/cohesive-ui'

import { AnalyticsPlot, AnalyticsTooltip } from '../../AnalyicsPlot'
import { IBasePlotProps } from '../../../types'

export const DrugModelUsageOverTimePlot: React.FC<IBasePlotProps> = (props) => {
  const drugModelsKeyNames: string[] = Object.keys(props.plotSeries)

  const renderPlotContent = () => {
    return (
      <ResponsiveContainer
        debounce={1}
        width={576}
        minHeight={240}
        height={'100%'}
      >
        <BarChart
          data={props.plotData}
          barCategoryGap={1}
          width={576}
        >
          <CartesianGrid
            stroke={neutrals300}
            strokeWidth={1}
            fill={neutrals50}
            vertical={false}
          />
          <XAxis
            dataKey='name'
            stroke={neutrals500}
            tick={{ stroke: neutrals700, fill: neutrals700 }}
            tickLine={{ stroke: neutrals500, fill: neutrals500 }}
            interval='equidistantPreserveStart'
            padding={{ left: 12, right: 12 }}
            height={30} // We don't have an X Axis label, so lower the default height down from 60
          />
          <YAxis
            stroke={neutrals500}
            tick={{ stroke: neutrals700, fill: neutrals700 }}
            tickLine={{ stroke: neutrals500, fill: neutrals500 }}
            interval='preserveStartEnd'
            width={30} // We don't have a Y Axis label, so we can reduce the default width down from 60
          />

          <Tooltip
            shared={false}
            content={(props) => (
              <AnalyticsTooltip
                active={props.active}
                payload={props.payload}
                label={props.label}
                dataName={drugModelsKeyNames}
              />
            )}
            cursor={{ stroke: blueSteel500, opacity: 0.4 }}
          />

          { props.plotSeries &&
              Object.entries(props.plotSeries).map(([key, value]) => {
                return (
                  <Bar
                    key={key}
                    dataKey={key}
                    stackId='a'
                    fill={value.color}
                    opacity={0.9}
                  />
                )
              })
          }
        </BarChart>
      </ResponsiveContainer>
    )
  }

  return (
    <AnalyticsPlot
      plotId='doseme-analytics-drug-model-usage-over-time-graph'
      plotData={props.plotData}
      plotSeries={props.plotSeries}
      renderPlotContent={renderPlotContent()}
    />
  )
}
