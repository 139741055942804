export const ChangeOverTimeToTherapeuticTargetMockData = {
  data: {
    type: '',
    attributes: {
      name: 'Change Over Time Time to Therapeutic Target by drug model',
      plotData: {
        plotSeries: {
          zeroToTwentyFourH: { label: '0-24h', type: 'bar', color: '#305d8a' },
          twentyFourToFortyEightH: { label: '24-48h', type: 'bar', color: '#a4a4d5' },
          fortyEightToSeventyTwoH: { label: '48-72h', type: 'bar', color: '#bcc8e5' },
          seventyTwoPlus: { label: '72h+', type: 'bar', color: '#f2882c' },
          didNotReach: { label: 'didNotReach', type: 'bar', color: '#c7c7c7' }
        },
        dataPoints: [
          {
            name: 'October',
            zeroToTwentyFourH: 30,
            twentyFourToFortyEightH: 30,
            fortyEightToSeventyTwoH: 10,
            seventyTwoPlus: 20,
            didNotReach: 10,
            tooltip: {
              zeroToTwentyFourH: { title: 'October 2023', prefix: '0-24h to target', suffix: '(30 courses)', value: '30%' },
              twentyFourToFortyEightH: { title: 'October 2023', prefix: '24-48h to target', suffix: '(30 courses)', value: '30%' },
              fortyEightToSeventyTwoH: { title: 'October 2023', prefix: '48-72h to target', suffix: '(10 courses)', value: '10%' },
              seventyTwoPlus: { title: 'October 2023', prefix: '72h+ to target', suffix: '(20 courses)', value: '20%' },
              didNotReach: { title: 'October 2023', prefix: 'Did not reach', suffix: '(10 courses)', value: '10%' }
            }
          },
          {
            name: 'November',
            zeroToTwentyFourH: 75,
            twentyFourToFortyEightH: 0,
            fortyEightToSeventyTwoH: 0,
            seventyTwoPlus: 0,
            didNotReach: 25,
            tooltip: {
              zeroToTwentyFourH: { title: 'November 2023', prefix: '0-24h to target', suffix: '(75 courses)', value: '75%' },
              twentyFourToFortyEightH: { title: 'November 2023', prefix: '24-48h to target', suffix: '(0 courses)', value: '0%' },
              fortyEightToSeventyTwoH: { title: 'November 2023', prefix: '48-72h to target', suffix: '(0 courses)', value: '0%' },
              seventyTwoPlus: { title: 'November 2023', prefix: '72h+ to target', suffix: '(0 courses)', value: '0%' },
              didNotReach: { title: 'November 2023', prefix: 'Did not reach', suffix: '(25 courses)', value: '25%' }
            }
          },
          {
            name: 'December',
            zeroToTwentyFourH: 29,
            twentyFourToFortyEightH: 22,
            fortyEightToSeventyTwoH: 12,
            seventyTwoPlus: 37,
            didNotReach: 0,
            tooltip: {
              zeroToTwentyFourH: { title: 'December 2023', prefix: '0-24h to target', suffix: '(29 courses)', value: '29%' },
              twentyFourToFortyEightH: { title: 'December 2023', prefix: '24-48h to target', suffix: '(22 courses)', value: '22%' },
              fortyEightToSeventyTwoH: { title: 'December 2023', prefix: '48-72h to target', suffix: '(12 courses)', value: '12%' },
              seventyTwoPlus: { title: 'December 2023', prefix: '72h+ to target', suffix: '(37 courses)', value: '37%' },
              didNotReach: { title: 'December 2023', prefix: 'Did not reach', suffix: '(0 courses)', value: '0%' }
            }
          }
        ]
      }
    }
  },
  metadata: {
    filters: [
      { name: 'hospitals', value: ['1234'] },
      { name: 'startDate', value: '2024-07-01' },
      { name: 'endDate', value: '2024-09-30' }
    ]
  }
}
