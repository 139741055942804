import { makeAutoObservable } from 'mobx'

import axiosClient, { AxiosError, AxiosResponse, isAxiosError } from '../../utils/axiosClient'
import {
  AUTH_POST_URL,
  AUTH_CHECK_URL,
  AUTH_SESSION_URL,
  AUTH_LOGIN_URL,
  AUTH_LOGOUT_URL,
  AUTH_RESET_PASSWORD_URL,
  AUTH_VERIFY_TOKEN,
  AUTH_2FA_URL,
  AUTH_CHECK_SSO
} from '../../constants/api'
import { IAuthQueryParams } from '../../types/auth'
import { RootStore } from '../RootStore'
import { TLoadState } from '../types'
import { Auth } from './Auth'
import { IAuth, IAuth2FAPost, IAuth2FASetUp, IAuthLogin, IAuthCheckSSO } from './types'
import { InvitationDetails } from './InvitationDetails'
import { TwoFASetUp } from './TwoFASetUp'
import { buildSearchParams } from '../utils'

export class AuthStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  error: string = ''

  auth: Auth | null = null
  invitationDetails: InvitationDetails | null = null
  twoFASetUp: TwoFASetUp | null = null
  ssoRedirectURL: string | null = null

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError' | 'updateError', error: Error | AxiosError) {
    let loggableError = this.rootStore.errorsStore.parseLoggableError(error)
    if (isAxiosError(error)) {
      if (error.response?.status && error.response?.status >= 500) {
        loggableError = 'Something went wrong'
      }
    }

    this.error = loggableError
    this.setLoadState(errorState)
  }

  resetStore() {
    this.error = ''
    this.resetAuth()
    this.invitationDetails = null
    this.twoFASetUp = null
    this.ssoRedirectURL = null

    this.setLoadState('initial')
  }

  isAuthenticated() {
    // authStore.auth?.attributes.is2faAuthenticated indicates whether 2fa is required and authenticated
    // undefined = not required
    // false = required, not authenticated
    // true = required, authenticated
    return this.loadState === 'loaded'
      && this.auth && this.auth.attributes.is2faAuthenticated !== false
  }

  setAuth(auth: IAuth) {
    this.auth = new Auth(this, auth)
  }

  resetAuth() {
    this.auth = null
  }

  setLaunchError(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  setInvitationDetails(invitationDetails: InvitationDetails) {
    this.invitationDetails = new InvitationDetails(this, invitationDetails)
  }

  setSSORedirectURL(ssoRedirectURL: string | null) {
    this.ssoRedirectURL = ssoRedirectURL
  }

  setTwoFASetUp(twoFASetUp: IAuth2FASetUp) {
    this.twoFASetUp = new TwoFASetUp(this, twoFASetUp)
  }

  async authenticate(payload: IAuthQueryParams) {
    this.setLoadState('loading')
    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    return axiosClient
      .post<AxiosResponse<IAuth>>(AUTH_POST_URL, payload, { headers })
      .then((response: AxiosResponse) => {
        const auth = new Auth(this, response.data.data)
        this.setAuth(auth)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        this.setError('loadError', error)
      })
  }

  async authCheck(patientId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    const params = buildSearchParams({
      patientId: patientId,
      appMode: window.env.APP_MODE
    })

    return axiosClient
      .get<AxiosResponse<IAuth>>(AUTH_CHECK_URL, { headers, params })
      .then((response: AxiosResponse) => {
        const auth = new Auth(this, response.data.data)
        this.setAuth(auth)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        this.setError('loadError', error)
      })
  }

  async authSession(patientId?: string, lastActivityEpoch?: number) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    const params = buildSearchParams({
      patientId,
      lastActivityEpoch: lastActivityEpoch?.toString(),
      appMode: window.env.APP_MODE
    })

    return axiosClient
      .get<AxiosResponse<IAuth>>(AUTH_SESSION_URL, { headers, params })
      .then((response: AxiosResponse) => {
        const auth = new Auth(this, response.data.data)
        this.setAuth(auth)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        this.setError('loadError', error)
      })
  }

  async authLogin(email: string, password: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    const data: IAuthLogin = {
      type: 'authLogin',
      attributes: {
        username: email,
        password: password
      }
    }

    return axiosClient
      .post<AxiosResponse<IAuth>>(AUTH_LOGIN_URL, { data }, { headers })
      .then((response: AxiosResponse) => {
        const auth = new Auth(this, response.data.data)
        this.setAuth(auth)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        this.setError('loadError', error)
      })
  }

  async authLogout() {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    return axiosClient
      .get<AxiosResponse<IAuth>>(AUTH_LOGOUT_URL, { headers })
      .then(() => {
        this.resetAuth()
        this.setLoadState('loaded')
        this.rootStore.resetOnLogout()
      })
      .catch(() => {
        this.resetAuth()
        this.setLoadState('loaded')
        this.rootStore.resetOnLogout()
      })
  }

  async statelessAuthLogout() {
    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    return axiosClient
      .get<AxiosResponse<IAuth>>(AUTH_LOGOUT_URL, { headers })
      .finally(() => {
        this.resetAuth()
        this.rootStore.resetOnLogout()
      })
  }

  async authResetPasswordEmail(email: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    const params = {
      email
    }

    return axiosClient
      .get<AxiosResponse<IAuth>>(AUTH_RESET_PASSWORD_URL, { headers, params })
      .then(() => {
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        this.setError('loadError', error)
      })
  }

  async authResetPassword(email: string, password: string, code: string, token: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json',
      Authorization: 'Bearer ' + token
    }

    const data = {
      type: 'authResetPassword',
      attributes: {
        email: email,
        password: password,
        code: code
      }
    }

    return axiosClient
      .post<AxiosResponse<IAuth>>(AUTH_RESET_PASSWORD_URL, { data }, { headers })
      .then(() => {
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        this.setError('updateError', error)
      })
  }

  async authVerifyToken(token: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json',
      Authorization: 'Bearer ' + token
    }

    return axiosClient
      .get<AxiosResponse<InvitationDetails>>(AUTH_VERIFY_TOKEN, { headers })
      .then((response: AxiosResponse) => {
        if (response.data.data) {
          this.setInvitationDetails(response.data.data)
        }

        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        this.setError('loadError', error)
      })
  }

  async authCheck2FA() {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    return axiosClient
      .get<AxiosResponse<IAuth2FASetUp | undefined>>(AUTH_2FA_URL, { headers })
      .then((response: AxiosResponse) => {
        if (response.status === 200 && response.data.data) {
          this.setTwoFASetUp(response.data.data)
        }
        this.setLoadState('loaded')

        return response.status
      })
      .catch((error: Error | AxiosError) => {
        this.setError('loadError', error)
      })
  }

  async authPost2FA(code: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    const data: IAuth2FAPost = {
      type: 'authLogin2fa',
      attributes: {
        code: code
      }
    }

    return axiosClient
      .post<AxiosResponse>(AUTH_2FA_URL, { data }, { headers })
      .then(() => {
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        this.setError('loadError', error)
      })
  }

  async authCheckSSO(email: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    // Encode the email address
    const encodedEmail = encodeURIComponent(email)

    return axiosClient
      .get<AxiosResponse<IAuthCheckSSO>>(`${AUTH_CHECK_SSO}?email=${encodedEmail}`, { headers })
      .then((response: AxiosResponse) => {
        this.setLoadState('loaded')

        // The email domain is part of the SSO realms and user needs to get redirected
        if (response.status === 200 && response.data.data.attributes.redirectURL) {
          this.setSSORedirectURL(response.data.data.attributes.redirectURL)

          return response.data.data
        }
        this.setSSORedirectURL(null)

        return
      })
      .catch((error: Error | AxiosError) => {
        this.setError('loadError', error)
      })
  }
}
