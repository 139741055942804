import React from 'react'
import {
  CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis
} from 'recharts'
import { blueSteel500, neutrals300, neutrals50, neutrals500, neutrals700 } from '@doseme/cohesive-ui'

import { AnalyticsPlot, AnalyticsMultiTooltip } from '../../AnalyicsPlot'
import { IBasePlotProps, IChartDataSeries } from '../../../types'

export const ChangeOverTimeToTherapeuticTargetPlot: React.FC<IBasePlotProps> = (props) => {
  const troughOutcomesKeyNames: string[] = Object.keys(props.plotSeries)

  const renderPlotContent = () => {
    return (
      <ResponsiveContainer
        debounce={1}
        width={925}
        minHeight={240}
        height={'100%'}
      >
        <LineChart
          data={props.plotData}
          barCategoryGap={1}
          width={940}
        >
          <CartesianGrid
            stroke={neutrals300}
            strokeWidth={1}
            fill={neutrals50}
            vertical={false}
          />
          <XAxis
            dataKey='name'
            stroke={neutrals500}
            tick={{ stroke: neutrals700, fill: neutrals700 }}
            tickLine={{ stroke: neutrals500, fill: neutrals500 }}
            interval='equidistantPreserveStart'
            padding={{ left: 12, right: 12 }}
            height={30} // We don't have an X Axis label, so lower the default height down from 60
          />
          <YAxis
            stroke={neutrals500}
            tick={{ stroke: neutrals700, fill: neutrals700 }}
            tickLine={{ stroke: neutrals500, fill: neutrals500 }}
            tickFormatter={(tick) => `${tick}%`}
            interval='preserveStartEnd'
            width={30} // We don't have a Y Axis label, so we can reduce the default width down from 60
          />

          <Tooltip
            shared={false}
            content={(props) => (
              <AnalyticsMultiTooltip
                active={props.active}
                payload={props.payload}
                label={props.label}
                subLabel=''
                dataName={troughOutcomesKeyNames}
              />
            )}
            cursor={{ stroke: blueSteel500, opacity: 0.4 }}
          />

          <Legend
            layout='vertical'
            align='right'
            verticalAlign='top'
            iconType={'square'}
            height={36}
            width={215}
            wrapperStyle={{ paddingLeft: '20px' }}
            formatter={(value: string) => {
              return props.plotSeries[value as keyof IChartDataSeries].label
            }}
          />

          {
            Object.entries(props.plotSeries).map(([key, value]) => {
              return (
                <Line
                  key={key}
                  dataKey={key}
                  stroke={value.color}
                  strokeWidth={3}
                  fill={value.color}
                  opacity={0.9}
                  activeDot={{ r: 7 }}
                  dot={false}
                  width={50}
                />
              )
            })
          }
        </LineChart>
      </ResponsiveContainer>
    )
  }

  return (
    <AnalyticsPlot
      plotId='doseme-analytics-trough-risk-thresholds'
      plotData={props.plotData}
      plotSeries={props.plotSeries}
      renderPlotContent={renderPlotContent()}
    />
  )
}
