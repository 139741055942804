import {
  InfoModal,
  Modal,
  Button,
  TimeInput,
  TextInput,
  ITimeState,
  ValidationMessage,
  InfoBubble
} from '@doseme/cohesive-ui'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import classnames from 'classnames'
import moment from 'moment'

import { TSimulationPanelType } from '../../../../types'
import { rawDateOnly } from '../../../../../../../../../../constants/timeFormat'
import { IFormState } from '../../../../../../../../../../types/validation'
import { useFormValidation } from '../../../../../../../../../../hooks/useFormValidation'
import { modalHDSessionDates, simulateHDScheduleFormFields } from './constants'
import {
  IDrugSpecificAttr,
  IHDData,
  TModelType,
  TWeekDays
} from '../../../../../../../../../../store/dosingRecommendation/types'
import { useDosingRecommendationStore } from '../../../../../../../../../../hooks/useStore'
import { formatToDisplayDate } from '../../../../../../../../../../utils/dates'

import './index.scss'

interface HDSessionsItems {
  change: () => void
  checked: boolean
  day: TWeekDays
  form: IFormState
}

interface IProps {
  setDrugSpecificAttr: (drugSpecificAttr: IDrugSpecificAttr) => void
  setShowSimulatePanel: (showSimulatePanel: TSimulationPanelType) => void
  showSimulatePanel: TSimulationPanelType
  hospitalTimezone: string
  nextDoseAt: Date
  currentModel: TModelType
}

interface IDaysOfWeek {
  Monday: boolean
  Tuesday: boolean
  Wednesday: boolean
  Thursday: boolean
  Friday: boolean
  Saturday: boolean
  Sunday: boolean
}

export const SimulateHDScheduleModal: React.FC<IProps> = observer((props) => {
  const dosingRecommendationStore = useDosingRecommendationStore()
  const [scheduledSessions, setScheduledSessions] = useState<boolean>(true)
  const [daysChecked, setDaysChecked] = useState<IDaysOfWeek>({
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    Sunday: false
  })

  useEffect(() => {
    resetForm(true)
  }, [props.nextDoseAt])

  const resetForm = (reset: boolean) => {
    const allDaysUnchecked = Object.assign({}, daysChecked)
    allDaysUnchecked.Monday = false
    allDaysUnchecked.Tuesday = false
    allDaysUnchecked.Wednesday = false
    allDaysUnchecked.Thursday = false
    allDaysUnchecked.Friday = false
    allDaysUnchecked.Saturday = false
    allDaysUnchecked.Sunday = false

    HDSessionsData.forEach((e) => {
      e.form.reset()
    })

    if (!reset) {
      const currentSimulationModelResults =
        dosingRecommendationStore.dosingRecommendation[props.currentModel]?.attributes.modelResults
      const formValues =
        currentSimulationModelResults?.[props.currentModel]?.drugSpecificResults?.hdScheduleParts

      formValues?.map((e) => {
        let index = HDSessionsData.map((e) => e.day).indexOf(e.dayOfWeek)
        HDSessionsData[index].form.validateFields([
          {
            field: 'timeAdministered',
            input: {
              hh: formatToDisplayDate(e.hemodialysisDateTime, props.hospitalTimezone).split(', ')[1].split(':')[0],
              mm: formatToDisplayDate(e.hemodialysisDateTime, props.hospitalTimezone).split(', ')[1].split(':')[1]
            }
          },
          {
            field: 'duration',
            input: e.hemodialysisDuration.value,
            constraints: {
              max: {
                value: 24
              },
              min: {
                value: 0.02
              }
            }
          }
        ])
        allDaysUnchecked[e.dayOfWeek] = true
      })
    }

    setDaysChecked(allDaysUnchecked)
    props.setShowSimulatePanel(undefined)
  }

  const toggleDay = (day: TWeekDays) => {
    const newDaysChecked = Object.assign({}, daysChecked)
    newDaysChecked[day] = !daysChecked[day]

    setDaysChecked(newDaysChecked)
  }

  const HDModalDates = modalHDSessionDates(props.nextDoseAt, 7)
  let HDSessionsData: HDSessionsItems[] = [
    {
      day: 'Monday',
      checked: daysChecked.Monday,
      change: () => toggleDay('Monday'),
      form: useFormValidation(simulateHDScheduleFormFields(HDModalDates[0]))
    },
    {
      day: 'Tuesday',
      checked: daysChecked.Tuesday,
      change: () => toggleDay('Tuesday'),
      form: useFormValidation(simulateHDScheduleFormFields(HDModalDates[1]))
    },
    {
      day: 'Wednesday',
      checked: daysChecked.Wednesday,
      change: () => toggleDay('Wednesday'),
      form: useFormValidation(simulateHDScheduleFormFields(HDModalDates[2]))
    },
    {
      day: 'Thursday',
      checked: daysChecked.Thursday,
      change: () => toggleDay('Thursday'),
      form: useFormValidation(simulateHDScheduleFormFields(HDModalDates[3]))
    },
    {
      day: 'Friday',
      checked: daysChecked.Friday,
      change: () => toggleDay('Friday'),
      form: useFormValidation(simulateHDScheduleFormFields(HDModalDates[4]))
    },
    {
      day: 'Saturday',
      checked: daysChecked.Saturday,
      change: () => toggleDay('Saturday'),
      form: useFormValidation(simulateHDScheduleFormFields(HDModalDates[5]))
    },
    {
      day: 'Sunday',
      checked: daysChecked.Sunday,
      change: () => toggleDay('Sunday'),
      form: useFormValidation(simulateHDScheduleFormFields(HDModalDates[6]))
    }
  ]

  const submitValues = () => {
    if (scheduledSessions) {
      let nextDoseAtDate = new Date(props.nextDoseAt)
      nextDoseAtDate.setDate(nextDoseAtDate.getDate() - 1)
      const hdData = HDSessionsData.reduce<IHDData[]>((acc, curr) => {
        nextDoseAtDate.setDate(nextDoseAtDate.getDate() + 1)
        let formattedCurrentDate = moment(nextDoseAtDate).format(rawDateOnly)
        if (daysChecked[curr.day] && curr.form.values.timeAdministered && curr.form.values.duration) {
          return acc.concat({
            dayOfWeek: curr.day,
            hemodialysisDateTime: moment
              .tz(formattedCurrentDate + 'T' + curr.form.values.timeAdministered, props.hospitalTimezone)
              .utc()
              .toISOString(),
            hemodialysisDuration: { value: curr.form.values.duration, unit: { id: '6', name: 'hours' } }
          })
        }

        return acc
      }, [])
      props.setDrugSpecificAttr({ drugModelId: '55', attributes: { plannedHemodialysisSessions: hdData } })

      return
    }

    props.setDrugSpecificAttr({ drugModelId: '55', attributes: { plannedHemodialysisSessions: [] } })
  }

  const formContentOneTimeDose = (): JSX.Element => {
    return (
      <div>
        <div onClick={() => scheduledSessions && setScheduledSessions(!scheduledSessions)} className='selectable-checkbox'>
          <div>
            <input type='checkbox' readOnly checked={!scheduledSessions} id='checkbox-round' />
          </div>
          <div className={classnames('', { disabled: scheduledSessions })}>
            <b>One-time dose</b> &nbsp;(no HD sessions)
          </div>
        </div>
        {!scheduledSessions && (
          <div className='mt-3'>
            <InfoBubble
              bubbleTitle="Note: The guideline dose recommendation specifies its own HD session schedule, and ignores 'no HD sessions'."
              borderRadius={5}
            />
          </div>
        )}
      </div>
    )
  }

  const returnCorrectDateTime = (date: Date, time: ITimeState): Date => {
    // date and time are seperate so we have to set the hours/minutes manually.
    date.setHours(parseInt(time.hh))
    date.setMinutes(parseInt(time.mm))

    return date
  }

  const HDSessionsInputForms = (): JSX.Element[] => {
    // ensure the first day listed is the next dose at
    let nextDoseAtDate = new Date(props.nextDoseAt)

    const firstHalf = HDSessionsData.slice(0, nextDoseAtDate.getDay() - 1)
    const secondHalf = HDSessionsData.slice(nextDoseAtDate.getDay() - 1)
    HDSessionsData = secondHalf.concat(firstHalf)

    return HDSessionsData.map((e, i) => {
      const date = new Date(HDModalDates[i])

      return (
        <div key={e.day}>
          <div className='schedule-hd-sessions-section' id={`hd-sessions-${e.day.toLowerCase()}`}>
            <div className='hd-sessions-selection-div' onClick={() => e.change()}>
              <input
                className='simulate-hd-sessions-checkbox'
                type='checkbox'
                checked={e.checked}
                id={`${e.day.toLowerCase()}-checkbox-round`}
                readOnly
              />
              <div className={classnames('hd-sessions-day', { disabled: !e.checked })}>{e.day}</div>
              <div className={classnames('hd-sessions-date', { disabled: !e.checked })}>
                {' ' + date.toString().split(' ')[1] + ' ' + date.toString().split(' ')[2]}
              </div>
            </div>

            <div className='hd-sessions-start-time'>
              <TimeInput
                label={<span>&#8205;</span>}
                fieldState={!e.checked ? 'valid' : e.form.getValidState('timeAdministered')}
                hasValidation={false}
                testIdHh='time-input-hh'
                testIdMm='time-input-mm'
                data-testid='time-input'
                value={e.form.inputs['timeAdministered']}
                disabled={!e.checked}
                onChange={(value: ITimeState) => {
                  e.form.validateFields([
                    {
                      field: 'timeAdministered',
                      input: value,
                      constraints: {
                        date: returnCorrectDateTime(date, value),
                        minDate: props.nextDoseAt
                      }
                    }
                  ])
                }}
                onBlur={() => {
                  {
                    e.form.updateFieldsDisplay(['timeAdministered'])
                  }
                }}
              />
            </div>

            <div className='hd-sessions-duration'>
              <TextInput
                fieldState={!e.checked ? 'valid' : e.form.getValidState('duration')}
                value={!isNaN(e.form.inputs['duration']) ? e.form.inputs['duration'] : ''}
                onChange={(value) =>
                  e.form.validateFields([
                    {
                      field: 'duration',
                      input: value,
                      constraints: {
                        max: {
                          value: 24
                        },
                        min: {
                          value: 0.02
                        }
                      }
                    }
                  ])
                }
                onBlur={() => {
                  {
                    e.form.updateFieldsDisplay(['duration'])
                  }
                }}
                units='hours'
                disabled={!e.checked}
              />
            </div>
          </div>
          {e.checked &&
            (e.form.getValidState('timeAdministered') === 'error' || e.form.getValidState('duration') === 'error') && (
            <div className='validation-message'>
              <ValidationMessage
                labelState='error'
                message={e.form.getValidationMsg('timeAdministered') || e.form.getValidationMsg('duration')}
              />
            </div>
          )}
        </div>
      )
    })
  }

  const formContentHDSessions = (): JSX.Element => {
    return (
      <>
        <div onClick={() => !scheduledSessions && setScheduledSessions(!scheduledSessions)} className='selectable-checkbox'>
          <div>
            <input type='checkbox' readOnly checked={scheduledSessions} id='checkbox-round' />
          </div>
          <b className={classnames('', { disabled: !scheduledSessions })}>Scheduled HD sessions</b>
          {!scheduledSessions && (
            <div className='simulate-hd-schedule-chevron'>
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          )}
        </div>

        {scheduledSessions && (
          <div>
            <div className='mt-3'>
              <InfoBubble
                bubbleTitle='Note: The guideline dose recommendation specifies its own HD session schedule, and ignores any custom sessions entered here.'
                borderRadius={5}
              />
            </div>
            <div className='scheduled-hd-sessions-headings'>
              <div>Scheduled day:</div>
              <div className='scheduled-hd-sessions-start-time'>Start time:</div>
              <div className='scheduled-hd-sessions-duration'>Duration:</div>
            </div>
            {HDSessionsInputForms()}
          </div>
        )}
      </>
    )
  }

  const checkSessionsValidity = (): boolean => {
    let returnValue = false
    // check if any fields are in an error state or an empty state
    HDSessionsData.forEach((e) => {
      if (
        (e.checked && (e.form.values.timeAdministered === ':' || !e.form.values.duration)) ||
        (e.checked &&
          (e.form.getValidState('timeAdministered') === 'error' || e.form.getValidState('duration') === 'error'))
      ) {
        returnValue = true

        return
      }
    })

    return returnValue
  }

  const messages: JSX.Element[] = [
    <div key='one-time-dose' className='position-relative w-100'>
      {formContentOneTimeDose()}
    </div>,
    <div key='hd-sessions' className='position-relative w-100'>
      {formContentHDSessions()}
    </div>
  ]

  return (
    <Modal
      show={!!props.showSimulatePanel}
      onHide={() => {
        resetForm(props.showSimulatePanel === 'initial')
      }}
      messageOnly
    >
      <InfoModal
        customClassName='simulate-hd-sessions-info-modal'
        size='variable'
        draggable
        linkComponent={
          <Button
            variant='primary'
            onClick={submitValues}
            disabled={scheduledSessions && (checkSessionsValidity() || Object.values(daysChecked).every((item) => !item))}
          >
            Continue
          </Button>
        }
        title={'Simulate HD schedule'}
        message={messages}
        onDismiss={() => {
          resetForm(props.showSimulatePanel === 'initial')
        }}
      />
    </Modal>
  )
})
