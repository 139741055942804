import { makeAutoObservable, observable, ObservableMap } from 'mobx'

import axiosClient, { AxiosError, AxiosResponse } from '../../utils/axiosClient'
import { patientCourseFeaturesGetUrl, patientCourseFeaturesPatchUrl } from '../../constants/api'
import { RootStore } from '../RootStore'
import { TLoadState } from '../types'
import { CourseFeatureSelection, CourseFeatureText } from './CourseFeature'
import { ICourseFeatureSelection, ICourseFeatureText, IUpdateCourseFeature } from './types'

export class CourseFeaturesStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  error: string = ''
  courseFeatures: ObservableMap<string, CourseFeatureText | CourseFeatureSelection> = observable.map({}, { deep: false })

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  resetStore() {
    this.courseFeatures = observable.map({}, { deep: false })
    this.error = ''

    this.setLoadState('initial')
  }

  setCourseFeatures(courseFeatures: Array<ICourseFeatureText | ICourseFeatureSelection>) {
    this.courseFeatures = observable.map({}, { deep: false })
    courseFeatures.forEach((courseFeature) => {

      if (courseFeature.type === 'text') {
        this.courseFeatures.set(courseFeature.id, new CourseFeatureText(this, courseFeature))
      }

      if (courseFeature.type === 'selection') {
        this.courseFeatures.set(courseFeature.id, new CourseFeatureSelection(this, courseFeature))
      }
    })
  }

  async fetchCourseFeatures(patientId: string, courseId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<Array<ICourseFeatureText | ICourseFeatureSelection>>>(patientCourseFeaturesGetUrl(patientId, courseId), { headers })
      .then((response: AxiosResponse) => {
        this.setCourseFeatures(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  async updateCourseFeatures(patientId: string, courseId: string, data: IUpdateCourseFeature[]) {
    this.setLoadState('updating')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .patch<AxiosResponse<Array<ICourseFeatureText | ICourseFeatureSelection>>>(
        patientCourseFeaturesPatchUrl(patientId, courseId),
        { data },
        { headers }
      )
      .then((response: AxiosResponse) => {
        this.setCourseFeatures(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)
      })
  }
}
