import { AnalyticsPlotSkeleton, MessageOverlay } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCog } from '@fortawesome/free-solid-svg-icons'
// import { ListButton } from '@doseme/cohesive-ui'
import { useAnalyticsChartChangeOverTimeToTherapeuticTargetStore } from '../../../../hooks/useStore'
import { IBaseChartProps } from '../../types'
import { ChangeOverTimeToTherapeuticTargetPlot } from './components'
import { formatErrorMessage } from '../../../PatientRouter/components/CourseProfile/components/DoseRecommendation/utils'

export const ChangeOverTimeToTherapeuticTarget: React.FC<IBaseChartProps> = observer((props) => {
  const analyticsChartChangeOverTimeToTherapeuticTargetStore = useAnalyticsChartChangeOverTimeToTherapeuticTargetStore()

  useEffect(() => {
    analyticsChartChangeOverTimeToTherapeuticTargetStore.fetchAnalyticsChart()
  }, [props.hospitals, props.startDate, props.endDate])

  const plotContent = () => {
    if (
      analyticsChartChangeOverTimeToTherapeuticTargetStore.analyticsChartData
      && analyticsChartChangeOverTimeToTherapeuticTargetStore.loadState === 'loaded'
    ) {
      return (
        <div className='doseme-analytics-chart-plot'>
          <ChangeOverTimeToTherapeuticTargetPlot
            plotData={analyticsChartChangeOverTimeToTherapeuticTargetStore.analyticsChartData.attributes.plotData.dataPoints}
            plotSeries={analyticsChartChangeOverTimeToTherapeuticTargetStore.analyticsChartData.attributes.plotData.plotSeries}
            plotName={analyticsChartChangeOverTimeToTherapeuticTargetStore.analyticsChartData.attributes.name}
          />
        </div>
      )
    }

    if (['loadError, updateError'].includes(analyticsChartChangeOverTimeToTherapeuticTargetStore.loadState)) {
      return (
        <MessageOverlay
          type='error'
          headerText='Failed to load analytics chart'
          messageText={
            <div className='panel-error-overlay-text'>
              {formatErrorMessage(analyticsChartChangeOverTimeToTherapeuticTargetStore.error) || 'Something went wrong'}
            </div>
          }
          showBlur={false}
        >
          <AnalyticsPlotSkeleton
            loading={false}
            style={{ width: '924px', height: '284px' }}
          />
        </MessageOverlay>
      )
    }

    return <AnalyticsPlotSkeleton loading={analyticsChartChangeOverTimeToTherapeuticTargetStore.loadState === 'loading'} style={{ width: '924px', height: '284px' }}/>
  }

  // Normally we would be issuing a GET request via our axiosClient to the appropriate chart endpoint
  // For now we have some mockData to pass on to the Plot component
  return (
    <div className='doseme-analytics-chart' key='change-over-time-to-therapeutic-target'>
      <div className='header-row'>
        <div className='title md-8'>Change Over Time</div>
        <div className='view-options md-4'>
          {/*<ListButton disabled={true}>*/}
          {/*  <FontAwesomeIcon icon={faCog} />&nbsp;View Options*/}
          {/*</ListButton>*/}
        </div>
      </div>
      {plotContent()}
    </div>
  )
})
