import React from 'react'
import {
  ResponsiveContainer,
  ComposedChart,
  CartesianGrid,
  Bar,
  Line,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { neutrals50, neutrals300, neutrals500, neutrals700, blueSteel500 } from '@doseme/cohesive-ui'

import { AnalyticsPlot, AnalyticsTooltip } from '../../AnalyicsPlot'
import { IBasePlotProps } from '../../../types'

export const UniqueUsersPlot: React.FC<IBasePlotProps> = (props) => {
  const plotData = props.plotData
  const plotSeries = props.plotSeries

  const renderPlotContent = () => {

    return (
      <ResponsiveContainer
        debounce={1}
        width={940}
        minHeight={240}
        height={'100%'}
      >
        <ComposedChart
          data={plotData}
          barCategoryGap={1}
          width={940}
        >
          <CartesianGrid
            stroke={neutrals300}
            strokeWidth={1}
            fill={neutrals50}
            vertical={false}
          />
          <XAxis
            dataKey='name'
            stroke={neutrals500}
            tick={{ stroke: neutrals700, fill: neutrals700 }}
            tickLine={{ stroke: neutrals500, fill: neutrals500 }}
            interval='equidistantPreserveStart'
            padding={{ left: 12, right: 12 }}
            height={30} // We don't have an X Axis label, so lower the default height down from 60
          />
          <YAxis
            stroke={neutrals500}
            tick={{ stroke: neutrals700, fill: neutrals700 }}
            tickLine={{ stroke: neutrals500, fill: neutrals500 }}
            interval='preserveStartEnd'
            width={30} // We don't have a Y Axis label, so we can reduce the default width down from 60
          />
          <Tooltip
            content={(props) => (
              <AnalyticsTooltip
                active={props.active}
                payload={props.payload}
                label={props.label}
                dataName={['uniqueUsers']}
              />)}
            cursor={{ stroke: blueSteel500, strokeWidth: 8, opacity: 0.4 }}
          />
          <Bar
            dataKey='uniqueUsers'
            layout='vertical'
            fill={plotSeries.uniqueUsers.color}
            stroke={plotSeries.uniqueUsers.borderColor}
            opacity={0.9}
            activeBar={true}
          />
          <Line
            dataKey='trend'
            type='linear'
            stroke={plotSeries.trend.color}
            dot={false}
            activeDot={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    )
  }

  return (
    <AnalyticsPlot
      plotId='doseme-analytics-unique-users-graph'
      plotData={plotData}
      plotSeries={plotSeries}
      renderPlotContent={renderPlotContent()}
    />
  )
}
