import { AnalyticsPlotSkeleton, MessageOverlay } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCog } from '@fortawesome/free-solid-svg-icons'
// import { ListButton } from '@doseme/cohesive-ui'
import { useAnalyticsAucVsTroughOutcomesStore } from '../../../../hooks/useStore'
import { IBaseChartProps } from '../../types'
import { AucVsTroughOutcomesPlot } from './components'
import { formatErrorMessage } from '../../../PatientRouter/components/CourseProfile/components/DoseRecommendation/utils'

export const AucVsTroughOutcomes: React.FC<IBaseChartProps> = observer((props) => {
  const aucVsTroughOutcomesChartStore = useAnalyticsAucVsTroughOutcomesStore()

  useEffect(() => {
    aucVsTroughOutcomesChartStore.fetchAnalyticsChart()
  }, [props.hospitals, props.startDate, props.endDate])

  const plotContent = () => {
    if (
      aucVsTroughOutcomesChartStore.analyticsChartData
      && aucVsTroughOutcomesChartStore.loadState === 'loaded'
    ) {
      return (
        <div className='doseme-analytics-chart-plot'>
          <AucVsTroughOutcomesPlot
            plotData={aucVsTroughOutcomesChartStore.analyticsChartData.attributes.plotData.dataPoints}
            plotSeries={aucVsTroughOutcomesChartStore.analyticsChartData.attributes.plotData.plotSeries}
          />
        </div>
      )
    }

    if (['loadError, updateError'].includes(aucVsTroughOutcomesChartStore.loadState)) {
      return (
        <MessageOverlay
          type='error'
          headerText='Failed to load analytics chart'
          messageText={
            <div className='panel-error-overlay-text'>
              {formatErrorMessage(aucVsTroughOutcomesChartStore.error) || 'Something went wrong'}
            </div>
          }
          showBlur={false}
        >
          <AnalyticsPlotSkeleton
            loading={false}
            style={{ width: '924px', height: '284px' }}
          />
        </MessageOverlay>
      )
    }

    return <AnalyticsPlotSkeleton loading={aucVsTroughOutcomesChartStore.loadState === 'loading'} style={{ width: '924px', height: '284px' }}/>
  }

  // Normally we would be issuing a GET request via our axiosClient to the appropriate chart endpoint
  // For now we have some mockData to pass on to the Plot component
  return (
    <div className='doseme-analytics-chart' key='auc-vs-trough-outcomes'>
      <div className='header-row'>
        <div className='title md-8'>AUC vs Trough outcomes</div>
        <div className='view-options md-4'>
          {/*<ListButton disabled={true}>*/}
          {/*  <FontAwesomeIcon icon={faCog} />&nbsp;View Options*/}
          {/*</ListButton>*/}
        </div>
      </div>
      {plotContent()}
    </div>
  )
})
