import { InfoModal, Modal, Button, Tag, BLACK, WHITE, green500, neutrals100 } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'

import { useAdminHospitalDetailsStore } from '../../../../../../../hooks/useStore'
import { showErrorToast, showSuccessToast } from '../../../../../../../shared/toast'
import {
  IPutAdminHospitalSuperAdminInfoAnalyticsSettings,
  IPutAdminHospitalSuperAdminInfoAnalyticSettingsWrapper
} from '../../../../../../../store/Admin/AdminHospitalDetails/types'
import { IModalProps } from '../../../../../../PatientRouter/types'
import { buildFormFields, buildInputs } from '../../../../../../../shared/buildForms'
import { useFormValidation } from '../../../../../../../hooks/useFormValidation'

interface IProps extends IModalProps {
  hospitalId: string
  hospitalName?: string
}

const HospitalSuperAdminInfoAnalyticsSettingsModal: React.FC<IProps> = observer((props) => {
  const [hasTableauAnalytics, toggleTableauAnalytics] = useState<boolean>(false)
  const [hasDoseMeAnalytics, toggleDoseMeAnalytics] = useState<boolean>(false)

  const adminHospitalDetailsStore = useAdminHospitalDetailsStore()

  useEffect(() => {
    if (props.show) {
      adminHospitalDetailsStore.fetchAdminHospitalSuperAdminInfoAnalyticsSettings(props.hospitalId)

      if (adminHospitalDetailsStore.adminHospitalSuperAdminInfoAnalyticsSettings !== null) {
        toggleTableauAnalytics(!!adminHospitalDetailsStore.adminHospitalSuperAdminInfoAnalyticsSettings.attributes.superAdminInfo.analytics.hasTableauAnalytics.attributes.currentValue)
        toggleDoseMeAnalytics(!!adminHospitalDetailsStore.adminHospitalSuperAdminInfoAnalyticsSettings.attributes.superAdminInfo.analytics.hasDoseMeAnalytics.attributes.currentValue)
      }
    }
  }, [props.hospitalId, props.show])

  const onToggleTableauAnalytics = () => {
    toggleTableauAnalytics(!hasTableauAnalytics)
  }

  const onToggleDoseMeAnalytics = () => {
    toggleDoseMeAnalytics(!hasDoseMeAnalytics)
  }

  const formFields = useMemo(() => {
    if (['loaded', 'updateError'].includes(adminHospitalDetailsStore.settingsLoadStates.superAdminInfoAnalyticsSettings)
      && adminHospitalDetailsStore.adminHospitalSuperAdminInfoAnalyticsSettings !== null
    ) {

      const fields = { attributes: { ...adminHospitalDetailsStore.adminHospitalSuperAdminInfoAnalyticsSettings.attributes.superAdminInfo.analytics } }

      return buildFormFields(fields)
    }

    return {}
  }, [adminHospitalDetailsStore.settingsLoadStates.superAdminInfoAnalyticsSettings])

  const form = useFormValidation(formFields)

  useEffect(() => {
    if (adminHospitalDetailsStore.settingsLoadStates.superAdminInfoAnalyticsSettings === 'loaded') {
      form.reset()
    }
  }, [adminHospitalDetailsStore.settingsLoadStates.superAdminInfoAnalyticsSettings, formFields])

  const formContent = (): JSX.Element => {
    const displayOrder = ['hasTableauAnalytics', 'hasDoseMeAnalytics']

    if (adminHospitalDetailsStore.adminHospitalSuperAdminInfoAnalyticsSettings !== null) {
      const inputs = {
        attributes: {
          hasTableauAnalytics: {
            id: adminHospitalDetailsStore.adminHospitalSuperAdminInfoAnalyticsSettings.attributes.superAdminInfo
              .analytics.hasTableauAnalytics.id,
            type: 'booleanSlider',
            attributes: {
              ...adminHospitalDetailsStore.adminHospitalSuperAdminInfoAnalyticsSettings.attributes.superAdminInfo
                .analytics.hasTableauAnalytics.attributes,
              label: '',
              description: (
                <div>
                  <div className='d-flex'>
                    Tableau Analytics set up:
                    <div className='optional-additional-features-tag'>
                      <Tag color={neutrals100} textColor={BLACK} size='small' text='ANA' />
                    </div>
                  </div>
                </div>
              ),
              currentValue: hasTableauAnalytics,
              onToggle: onToggleTableauAnalytics
            }
          },
          hasDoseMeAnalytics: {
            id: adminHospitalDetailsStore.adminHospitalSuperAdminInfoAnalyticsSettings.attributes.superAdminInfo
              .analytics.hasDoseMeAnalytics.id,
            type: 'booleanSlider',
            attributes: {
              ...adminHospitalDetailsStore.adminHospitalSuperAdminInfoAnalyticsSettings.attributes.superAdminInfo
                .analytics.hasDoseMeAnalytics.attributes,
              label: '',
              description: (
                <div>
                  <div className='d-flex'>
                    DoseMe Analytics enabled:
                    <div className='optional-additional-features-tag'>
                      <Tag color={green500} textColor={WHITE} size='small' text='DMA' />
                    </div>
                  </div>
                </div>
              ),
              currentValue: hasDoseMeAnalytics,
              onToggle: onToggleDoseMeAnalytics
            }
          }
        }
      }

      if (adminHospitalDetailsStore.adminHospitalSuperAdminInfoAnalyticsSettings) {

        return (
          <div className='position-relative w-100'>
            {buildInputs(inputs, form, displayOrder, formFields)}
          </div>
        )
      }
    }

    return <></>
  }

  const handleSubmit = async () => {
    const putValues: IPutAdminHospitalSuperAdminInfoAnalyticSettingsWrapper =
      { superAdminInfo: { analytics: { hasTableauAnalytics: hasTableauAnalytics, hasDoseMeAnalytics: hasDoseMeAnalytics } as IPutAdminHospitalSuperAdminInfoAnalyticsSettings  } }

    await adminHospitalDetailsStore.putAdminHospitalSuperAdminInfoAnalyticsSettings(props.hospitalId, putValues)

    if (['loadError', 'updateError'].includes(adminHospitalDetailsStore.settingsLoadStates.superAdminInfoAnalyticsSettings)) {
      showErrorToast(adminHospitalDetailsStore.settingsErrors.superAdminInfoAnalyticsSettings || 'Failed to update Hospital SuperAdmin Analytics Info Settings')

      return
    }

    showSuccessToast('Hospital Analytics settings updated')
    await adminHospitalDetailsStore.fetchAdminHospitalSuperAdminInfoAnalyticsSettings(props.hospitalId)
    props.setShow(false)
    form.reset()
  }

  const handleClose = () => {
    props.setShow(false)
    form.reset()
  }

  return (
    <Modal show={props.show} onHide={handleClose}>
      <InfoModal
        size='s'
        loading={['loading', 'updating'].includes(
          adminHospitalDetailsStore.settingsLoadStates.superAdminInfoAnalyticsSettings
        )}
        linkComponent={
          <>
            <Button
              className='mr-3'
              data-testid='cancel-btn'
              disabled={false}
              loading={['loading', 'updating'].includes(
                adminHospitalDetailsStore.settingsLoadStates.superAdminInfoAnalyticsSettings
              )}
              onClick={handleClose}
              variant='primary-outline'
            >
              Cancel
            </Button>
            <Button
              data-testid='save-btn'
              disabled={
                !form.valid ||
                ['loading', 'updating'].includes(
                  adminHospitalDetailsStore.settingsLoadStates.superAdminInfoAnalyticsSettings
                )
              }
              loading={['loading', 'updating'].includes(
                adminHospitalDetailsStore.settingsLoadStates.superAdminInfoAnalyticsSettings
              )}
              onClick={handleSubmit}
              variant='primary'
            >
              Save
            </Button>
          </>
        }
        title='Analytics status'
        subtitle={
          <div className='superadmin-information-subtitle'>
            <div className='info-bold-font pb-2'>Superadmin information</div>
            <div>{props.hospitalName}</div>
          </div>
        }
        message={formContent()}
      />
    </Modal>
  )
})

export { HospitalSuperAdminInfoAnalyticsSettingsModal }
