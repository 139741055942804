import React from 'react'
import { createRoot } from 'react-dom/client'
import { IntercomProvider } from 'react-use-intercom'
import { TInstanceType } from '@doseme/cohesive-ui'
import { I18nextProvider } from 'react-i18next'

import { StandaloneApp } from './StandaloneApp'
import { IntegratedApp } from './IntegratedApp'
import { TVendor } from './constants/api'
import i18n from './i18n'

import 'react-toastify/dist/ReactToastify.css'
import '@doseme/cohesive-ui/dist/style/global.css'
import '@doseme/cohesive-ui/dist/cohesive-ui.esm.css'
import './index.scss'

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    env: {
      API_BASE_URL: string
      VENDOR_MODE: TVendor
      FHIR_CLIENT_ID: string
      AUTH_CHECK_REFRESH_MINUTES: number
      INTERCOM_APP_ID: string
      INSTALLATION: string
      AZURE_DOCUMENT_CONTAINER: string
      AZURE_APP_INSIGHTS_ID?: string
      INSTANCE_TYPE?: TInstanceType
      COMMIT_HASH: string
      VERSION: string
      DEBUG_FHIR: string | null
      SHOW_DMA?: boolean
      DOB_DATE_FORMAT: 'MM/DD/YYYY' | 'DD/MM/YYYY' | null
      APP_MODE: 'integrated' | 'standalone'
    }
  }
}

const getAppComponent = (): JSX.Element => {
  if (window.env.APP_MODE === 'integrated') {
    return (
      <IntegratedApp />
    )
  }

  return (
    <StandaloneApp />
  )
}

const app = (): JSX.Element => {
  const appComponent = getAppComponent()

  if (window.env.APP_MODE === 'integrated' && window.env.VENDOR_MODE === 'cerner') {
    (async () => {
      await import('cerner-smart-embeddable-lib')
    })()
  }

  return (
    <IntercomProvider
      appId={window.env.INTERCOM_APP_ID}
    >
      <I18nextProvider i18n={i18n}>
        {appComponent}
      </I18nextProvider>
    </IntercomProvider>
  )
}

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(<React.StrictMode>{app()}</React.StrictMode>)
