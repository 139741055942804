import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, DOSEME_BLUE, DOSEME_GOLD, Icons, InfoBubble, LOADING_GREY, NAVY, Spinner, TextInput, WHITE } from '@doseme/cohesive-ui'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import { useAuthStore, useClinicianStore } from '../../../../hooks/useStore'
import { buildFormFields } from '../../../../shared/buildForms'
import { useFormValidation } from '../../../../hooks/useFormValidation'
import { TOSFooter } from '../TOSFooter'
import { loginPageEmailFields, loginPagePasswordFields } from './constants'
import { loginRedirect } from './utils'

import './index.scss'

export const LoginPage: React.FC = observer(() => {
  const [isShowPasswordChecked, setIsShowPasswordChecked] = useState<boolean>(false)
  const [displayLoginError, setDisplayLoginError] = useState<boolean>(false)
  const [lockedOut, setLockedOut] = useState<boolean>(false)
  const [displayPanel, setDisplayPanel] = useState<'email' | 'password' | 'sso'>('email')

  const navigate = useNavigate()

  const authStore = useAuthStore()
  const clinicianStore = useClinicianStore()

  const { search } = useLocation()

  const { t } = useTranslation()

  const searchParams = new URLSearchParams(search)

  const emailFormFields = buildFormFields(loginPageEmailFields)
  const passwordFormFields = buildFormFields(loginPagePasswordFields)

  const emailForm = useFormValidation(emailFormFields)
  const passwordForm = useFormValidation(passwordFormFields)

  useEffect(() => {
    if (authStore.isAuthenticated()) {
      loginRedirect(authStore.auth, clinicianStore).then((redirectURL) =>
        navigate(redirectURL)
      )
    }

    // Needed to prefill and email passed to this page as a searchParam
    const linkEmail = searchParams.get('email')
    if (linkEmail !== null && linkEmail !== '') {
      emailForm.validateFields(
        [
          {
            field: 'email',
            input: linkEmail.replace(' ', '+')
          }
        ],
        'updateFieldsDisplay'
      )
    }
  }, [])

  useEffect(() => {
    if (displayPanel === 'email') {
      const emailInputs = document.getElementsByName('emailInput') as NodeListOf<HTMLInputElement>
      if (emailInputs[0]) {
        emailInputs[0].focus()
        emailInputs[0].select()
      }
    } else if (displayPanel === 'password') {
      const passwordInputs = document.getElementsByName('passwordInput') as NodeListOf<HTMLInputElement>
      if (passwordInputs[0]) {
        passwordInputs[0].focus()
        passwordInputs[0].select()
      }
    }
  }, [displayPanel])

  useEffect(() => {
    if (displayLoginError) {
      authStore.error.includes('For security, your DoseMeRx account has been locked')
        ? setLockedOut(true)
        : setLockedOut(false)
    }
  }, [authStore.error, displayLoginError])

  const handleEnter = (event: React.FormEvent) => {
    event.preventDefault()
    if (!['loading', 'updating'].includes(authStore.loadState)) {
      if (displayPanel === 'email' && emailForm.valid) {
        checkSSORealm()
      } else if (displayPanel === 'password' && passwordForm.valid) {
        submitLogin()
      }
    }
  }

  const displayError = () => {
    if (lockedOut) {
      return (
        <div className='account-locked-down'>
          <InfoBubble
            type='error'
            bubbleTitle={
              <div className='error-title'>
                Your account has been locked down.
                <div className='error-text'>{authStore.error}</div>
              </div>
            }
          />
        </div>
      )
    }

    if (authStore.error.includes('your account may be locked if you continue to enter incorrect credentials')) {
      return (
        <div className='account-locked-down-warning'>
          <InfoBubble
            type='error'
            bubbleTitle={
              <div className='error-title'>
                Incorrect email or password entered.
                <div className='error-text'>{authStore.error}</div>
              </div>
            }
          />
        </div>
      )
    }

    return (
      <div className='mt-3'>
        <InfoBubble type='error' bubbleTitle={<b>{authStore.error}</b>} />
      </div>
    )
  }

  const checkSSORealm = async () => {
    const emailValue = emailForm.values.email.trim()

    if (!emailValue) {
      return
    }

    const response = await authStore.authCheckSSO(emailValue)

    if (['loadError', 'updateError'].includes(authStore.loadState)) {
      setDisplayLoginError(true)

      return
    }

    // If the email domain is in the SSO realms and a redirect url has been return, redirect the user
    if (response && response.attributes.redirectURL) {
      setDisplayPanel('sso')

      window.location.href = response.attributes.redirectURL

      return
    }

    // If the email domain is not in the SSO realms, display the password field
    setDisplayPanel('password')
  }

  const submitLogin = async () => {
    await authStore.authLogin(emailForm.values.email.trim(), passwordForm.values.password)

    if (['loadError', 'updateError'].includes(authStore.loadState)) {
      setDisplayLoginError(true)
      passwordForm.reset()

      return
    }

    if (authStore.auth?.attributes.redirectTo) {
      navigate(authStore.auth?.attributes.redirectTo)
    }

    // authStore.auth?.attributes.is2faAuthenticated indicates whether 2fa is required and authenticated
    // undefined = not required
    // false = required, not authenticated
    // true = required, authenticated
    if (authStore.auth?.attributes.is2faAuthenticated === false) {
      const twoFACheckStatus = await authStore.authCheck2FA()
      // 200 code means 2FA has not been set up for this user
      // Redirect to 2FA setup page
      if (twoFACheckStatus === 200) {
        navigate('/twofasetup')

        return
      }

      // 204 code means 2FA has been set up for this user
      // Redirect to 2FA verification page
      if (twoFACheckStatus === 204) {
        navigate('/twofa')

        return
      }
    }

    loginRedirect(authStore.auth, clinicianStore).then((redirectURL) =>
      navigate(redirectURL)
    )
  }

  const backToEmail = () => {
    passwordForm.reset()
    setDisplayLoginError(false)
    setDisplayPanel('email')
  }

  const forgotPasswordLink = () => {
    return '../forgot_password?email=' + emailForm.values.email?.trim()
  }

  const SSORedirectionPanelContent: JSX.Element = (
    <div className='login-page-panel sso-page-panel'>
      <div className='login-page-title'>Redirecting to SSO site...</div>
      <div className='login-page-subtitle'>Please wait a moment</div>
      <div className='sso-loading-spinner'>
        <Icons.ThinSpinner strokeWidth={16} r={30} stroke={LOADING_GREY} width='32px' />
      </div>
    </div>
  )

  const EmailPanelContent: JSX.Element = (
    <div className='login-page-panel smaller-width'>
      <div className='login-page-title'>{t('auth.welcome')}</div>
      <div className='login-page-subtitle'>{t('auth.welcomeSubtitle')}</div>
      <div className='login-page-email-input-label'>Email:</div>
      <form onSubmit={handleEnter}>
        <TextInput
          type='text'
          fieldState='valid'
          value={emailForm.inputs['email']}
          name='emailInput'
          onChange={(value) => {
            setDisplayLoginError(false)
            setLockedOut(false)
            emailForm.validateFields([
              {
                field: 'email',
                input: value
              }
            ])
          }}
          onBlur={() => emailForm.updateFieldsDisplay(['email'])}
        />
      </form>
      {displayLoginError && displayError()}
      <Button
        onClick={() => checkSSORealm()}
        variant='primary-alt'
        disabled={!emailForm.valid || authStore.loadState === 'loading' || clinicianStore.loadState === 'loading'}
      >
        Continue&nbsp;
        <FontAwesomeIcon color={emailForm.valid ? DOSEME_BLUE : WHITE} icon={faArrowRight} />
      </Button>
    </div>
  )

  const PasswordPanelContent: JSX.Element = (
    <div className='login-page-panel smaller-width password-panel'>
      <div className='login-page-password-back-arrow' onClick={() => backToEmail()}>
        <FontAwesomeIcon color={NAVY} icon={faArrowLeft} /> <div className='ml-2'>Back to email</div>
      </div>
      <div className='login-page-title'>Enter your password</div>
      <div className='login-page-password-subtitle'>{emailForm.values.email}</div>
      <div className='login-page-password-label'>
        <div className='login-page-email-input-label'>Password:</div>
        <div className='login-page-show-password' onClick={() => setIsShowPasswordChecked(!isShowPasswordChecked)}>
          <input className='login-page-show-password' readOnly type='checkbox' checked={isShowPasswordChecked} />
          <div className='login-page-show-password-label'>Show password</div>
        </div>
      </div>
      <form onSubmit={handleEnter}>
        <TextInput
          showCapsLock={true}
          type={isShowPasswordChecked ? 'text' : 'password'}
          fieldState='valid'
          value={passwordForm.inputs['password']}
          name='passwordInput'
          disabled={lockedOut || ['loading', 'updating'].includes(authStore.loadState)}
          onChange={(value) => {
            setDisplayLoginError(false)
            passwordForm.validateFields([
              {
                field: 'password',
                input: value
              }
            ])
          }}
          onBlur={() => passwordForm.updateFieldsDisplay(['password'])}
        />
      </form>
      <Link
        className={classnames('login-page-forgot-password-link', {
          'login-page-forgot-password-link-disabled': lockedOut
        })}
        to={forgotPasswordLink()}
      >
        <div className='login-page-forgot-password'>Forgot your password?</div>
      </Link>
      {displayLoginError && displayError()}
      {!lockedOut && (
        <Button
          onClick={() => submitLogin()}
          variant='primary-alt'
          disabled={!passwordForm.valid || ['loading', 'updating'].includes(authStore.loadState) }
          loading={['loading', 'updating'].includes(authStore.loadState)}
          loadingContent={
            <div className='login-page-submit-btn-loading-style'>
              <div className='mr-2'>Authenticating...</div>
              <div>
                <Spinner color={DOSEME_GOLD} size='1x' />
              </div>
            </div>
          }
        >
          Submit&nbsp;
          <FontAwesomeIcon color={passwordForm.valid ? DOSEME_BLUE : WHITE} icon={faArrowRight} />
        </Button>
      )}
    </div>
  )

  const getPanelContent = (): JSX.Element | null => {
    if (displayPanel === 'email') {
      return EmailPanelContent
    }

    if (displayPanel === 'sso') {
      return SSORedirectionPanelContent
    }

    if (displayPanel === 'password') {
      return PasswordPanelContent
    }

    return null
  }

  return (
    <div>
      {getPanelContent()}
      <div className='w-440'><TOSFooter /></div>
    </div>
  )
})
