import { makeAutoObservable } from 'mobx'

import { dosingRecommendationGetUrl, dosingRecommendationRXBEGetUrl } from '../../constants/api'
import axiosClient, { AxiosError, AxiosResponse } from '../../utils/axiosClient'
import { RootStore } from '../RootStore'
import { TLoadState } from '../types'
import { HistoricalSimulation } from './HistoricalSimulation'
import { IHistoricalSimulation } from './types'
import { IComputedOutcomesPerDose } from '../dosingRecommendation/types'

export class HistoricalSimulationStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  error: string = ''
  historicalSimulationData: HistoricalSimulation | null = null

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }
  resetStore() {
    this.historicalSimulationData = null
    this.error = ''

    this.setLoadState('initial')
  }

  resetError() {
    this.error = ''
  }

  setHistoricalSimulationData(historicalSimulationAttrs: IHistoricalSimulation) {
    this.historicalSimulationData = new HistoricalSimulation(this, historicalSimulationAttrs)
  }

  getHistoricalOutcomes(): IComputedOutcomesPerDose | null {
    const modelResults = this.historicalSimulationData?.attributes.modelResults

    if (['loading', 'updating'].includes(this.loadState) || !modelResults?.historicalPerDoseOutcomes) {
      return null
    }

    return modelResults.historicalPerDoseOutcomes.reduce<IComputedOutcomesPerDose>((acc, curr) => {
      acc[curr.administrationId] = {
        auc: curr.auc,
        auc24: curr.auc24,
        peak: curr.peak,
        trough: curr.trough,
        cumulative_auc: curr.cumulative_auc,
        cumulative_auc_percent_to_target: curr.cumulative_auc_percent_to_target
      }

      return acc
    }, {})
  }

  async fetchHistoricalDosingRecommendation(
    patientId: string,
    courseId: string,
    drugModelId: string
  ): Promise<IHistoricalSimulation | null> {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    const url = drugModelId === '54'
      ? dosingRecommendationRXBEGetUrl(patientId, courseId, drugModelId)
      : dosingRecommendationGetUrl(patientId, courseId, drugModelId)

    return await axiosClient
      .get<AxiosResponse<IHistoricalSimulation>>(url, { headers })
      .then((response: AxiosResponse) => {
        this.setHistoricalSimulationData(response.data.data)
        this.setLoadState('loaded')
        this.resetError()

        return response.data.data
      })
      .catch((error: Error | AxiosError) => {
        this.resetStore()

        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)

        return null
      })
  }
}
