import { ObservableMap, makeAutoObservable, observable } from 'mobx'

import axiosClient, { AxiosError, AxiosResponse } from '../../../utils/axiosClient'
import { RootStore } from '../../RootStore'
import { TLoadState } from '../../types'
import { adminVendorSitePostUrl, adminVendorSitesDeleteUrl, adminVendorSitesGetUrl } from '../../../constants/api'
import { IAdminVendorSiteListItem } from './types'
import { AdminVendorSiteListItem } from './AdminVendorSiteListItem'
import { IAdministration } from '../../administrations/types'

export class AdminVendorSiteListStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  error: string = ''

  adminVendorSites: ObservableMap<string, IAdminVendorSiteListItem> = observable.map({}, { deep: false })

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  resetStore() {
    this.adminVendorSites = observable.map({}, { deep: false })
    this.error = ''

    this.setLoadState('initial')
  }

  removeSingleVendorSite(id: string) {
    this.adminVendorSites.delete(id)
  }

  async deleteVendorSite(vendorId: string, vendorSiteId: string) {
    this.setLoadState('updating')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .delete<AxiosResponse<IAdministration>>(adminVendorSitesDeleteUrl(vendorId, vendorSiteId), { headers })
      .then(() => {
        this.removeSingleVendorSite(vendorSiteId)
        this.setLoadState('loaded')

        return null
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)

        return null
      })

    return null
  }

  resetAdminVendorSites(adminVendorSites: IAdminVendorSiteListItem[]) {
    this.adminVendorSites = observable.map({}, { deep: false })
    adminVendorSites.forEach((vendorSite) =>
      this.adminVendorSites.set(vendorSite.id, new AdminVendorSiteListItem(this, vendorSite))
    )
  }

  async fetchVendorSites(vendorId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IAdminVendorSiteListItem[]>>(adminVendorSitesGetUrl(vendorId), { headers })
      .then((response: AxiosResponse) => {
        this.resetAdminVendorSites(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  async createVendorSite(vendorId: string, vendorSiteKey: string, vendorSiteName: string, hospitalId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    return await axiosClient
      .post<AxiosResponse<IAdminVendorSiteListItem[]>>(
        adminVendorSitePostUrl(vendorId),
        {
          data: {
            type: 'adminVendorSiteNewWrite',
            attributes: {
              name: vendorSiteName,
              hospital: hospitalId,
              key: vendorSiteKey
            }
          }
        },
        { headers }
      )
      .then((response: AxiosResponse) => {
        this.setLoadState('loaded')
        if (response.data.data.length) {
          return response.data.data[0].id
        }

        return null
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }
}
