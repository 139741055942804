export const medianHoursAndDosesToTargetMockData = {
  data: {
    type: '',
    attributes: {
      name: 'Median hours and doses to target',
      plotData: {
        plotSeries: {},
        dataPoints: [
          {
            name: '1-comp.',
            medianHoursToTarget: 24.9,
            medianDosesToTarget: 4.5,
            coursesToTarget: 78,
            coursesNotToTarget: 7
          },
          {
            name: '2-comp.',
            medianHoursToTarget: 22.1,
            medianDosesToTarget: 3.6,
            coursesToTarget: 58,
            coursesNotToTarget: 4
          },
          {
            name: 'HD',
            medianHoursToTarget: 24.9,
            medianDosesToTarget: 4.5,
            coursesToTarget: 78,
            coursesNotToTarget: 7
          },
          {
            name: 'HD: Hui',
            medianHoursToTarget: 22.1,
            medianDosesToTarget: 3.6,
            coursesToTarget: 58,
            coursesNotToTarget: 4
          },
          {
            name: 'Obese: Enhanced(Adult)',
            medianHoursToTarget: 22.1,
            medianDosesToTarget: 3.6,
            coursesToTarget: 58,
            coursesNotToTarget: 4
          },
          {
            name: 'Overall',
            medianHoursToTarget: 22.1,
            medianDosesToTarget: 3.6,
            coursesToTarget: 58,
            coursesNotToTarget: 4
          }
        ]
      }
    }
  },
  metadata: {
    filters: [
      { name: 'hospitals', value: ['1234'] },
      { name: 'startDate', value: '2024-07-01' },
      { name: 'endDate', value: '2024-09-30' }
    ]
  }
}
