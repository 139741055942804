import { AnalyticsPlotSkeleton, MessageOverlay } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCog } from '@fortawesome/free-solid-svg-icons'
// import { ListButton } from '@doseme/cohesive-ui'
import { useAnalyticsChartReportsByTypeStore } from '../../../../hooks/useStore'
import { IBaseChartProps } from '../../types'
import { ReportsByTypePlot } from './components'
import { formatErrorMessage } from '../../../PatientRouter/components/CourseProfile/components/DoseRecommendation/utils'

export const ReportsByType: React.FC<IBaseChartProps> = observer((props) => {
  const analyticsChartReportsByTypeStore = useAnalyticsChartReportsByTypeStore()

  useEffect(() => {
    analyticsChartReportsByTypeStore.fetchAnalyticsChart(
      props.startDate,
      props.endDate,
      props.hospitals
    )
  }, [props.hospitals, props.startDate, props.endDate])

  const plotContent = () => {
    if (
      analyticsChartReportsByTypeStore.analyticsChartData
      && analyticsChartReportsByTypeStore.loadState === 'loaded'
    ) {
      return (
        <div className='doseme-analytics-chart-plot'>
          <ReportsByTypePlot
            plotData={analyticsChartReportsByTypeStore.analyticsChartData.attributes.plotData.dataPoints}
            plotSeries={analyticsChartReportsByTypeStore.analyticsChartData.attributes.plotData.plotSeries}
            plotName={analyticsChartReportsByTypeStore.analyticsChartData.attributes.name}
          />
        </div>
      )
    }

    if (['loadError, updateError'].includes(analyticsChartReportsByTypeStore.loadState)) {
      return (
        <MessageOverlay
          type='error'
          headerText='Failed to load analytics chart'
          messageText={
            <div className='panel-error-overlay-text'>
              {formatErrorMessage(analyticsChartReportsByTypeStore.error) || 'Something went wrong'}
            </div>
          }
          showBlur={false}
        >
          <AnalyticsPlotSkeleton
            loading={false}
            style={{ width: '924px', height: '400px' }}
          />
        </MessageOverlay>
      )
    }

    return <AnalyticsPlotSkeleton
      loading={analyticsChartReportsByTypeStore.loadState === 'loading'}
      style={{ width: '924px', height: '400px' }}
    />
  }

  // Normally we would be issuing a GET request via our axiosClient to the appropriate chart endpoint
  // For now we have some mockData to pass on to the Plot component
  return (
    <div className='doseme-analytics-chart' key='reports-by-type'>
      <div className='header-row'>
        <div className='title md-8'>Reports Generated by Type</div>
        <div className='view-options md-4'>
          {/*<ListButton disabled={true}>*/}
          {/*  <FontAwesomeIcon icon={faCog} />&nbsp;View Options*/}
          {/*</ListButton>*/}
        </div>
      </div>
      {plotContent()}
    </div>
  )
})
