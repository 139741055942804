import { makeObservable, override } from 'mobx'

import { aucRiskThresholdsMockData } from '../../../components/DoseMeAnalytics/components/AucRiskThresholds/mockData'
import { analyticsChartsAucRiskThresholdsGetUrl } from '../../../constants/api'
import { AnalyticsRootStore } from '../../AnalyticsRootStore/AnalyticsRootStore'
import { AnalyticsChartStore } from '../AnalyticsChartsStore'

export class AnalyticsChartAucRiskThresholdsStore extends AnalyticsChartStore {
  //FIXME - IFE-1316 Remove once endpoint is implemented. Will use the fetchAnalyticsChart in AnalyticsChartStore
  async fetchAnalyticsChart() {
    this.setLoadState('loading')

    this.setAnalyticsChart(aucRiskThresholdsMockData)
    this.setLoadState('loaded')
  }

  constructor(rootStore: AnalyticsRootStore) {
    super(rootStore, analyticsChartsAucRiskThresholdsGetUrl())
    makeObservable(this, {
      fetchAnalyticsChart: override
    })
  }
}
