import React from 'react'
import {
  CartesianGrid
  , Legend, ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip, XAxis, YAxis
} from 'recharts'
import {
  blueSteel500,
  neutrals300, neutrals50, neutrals500, neutrals700 } from '@doseme/cohesive-ui'

import {
  AnalyticsMultiTooltipsSingleDataPoint,
  AnalyticsPlot
} from '../../AnalyicsPlot'
import { IBasePlotProps, IChartDataPoint, IChartDataSeries } from '../../../types'

export const AucVsTroughOutcomesPlot: React.FC<IBasePlotProps> = (props) => {

  const renderPlotContent = () => {
    return (
      <ResponsiveContainer
        debounce={1}
        width={925}
        minHeight={240}
        height={'100%'}
      >
        <ScatterChart
          barCategoryGap={1}
          width={940}
        >
          <CartesianGrid
            stroke={neutrals300}
            strokeWidth={1}
            fill={neutrals50}
            vertical={false}
          />
          <XAxis
            dataKey='auc24'
            type='number'
            name='AUC24'
            stroke={neutrals500}
            tick={{ stroke: neutrals700, fill: neutrals700 }}
            tickLine={{ stroke: neutrals500, fill: neutrals500 }}
            interval='equidistantPreserveStart'
            padding={{ left: 12, right: 12 }}
            height={30} // We don't have an X Axis label, so lower the default height down from 60
          />
          <YAxis
            dataKey='trough'
            type='number'
            name='Trough'
            stroke={neutrals500}
            tick={{ stroke: neutrals700, fill: neutrals700 }}
            tickLine={{ stroke: neutrals500, fill: neutrals500 }}
            interval='preserveStartEnd'
            width={30} // We don't have a Y Axis label, so we can reduce the default width down from 60
          />

          <Tooltip
            shared={false}
            content={(props) => (
              <AnalyticsMultiTooltipsSingleDataPoint
                active={props.active}
                payload={props.payload}
                label={props.label}
                dataName={['auc24', 'trough']}
              />)}
            cursor={{ stroke: blueSteel500, strokeWidth: 8, opacity: 0.4 }}
          />

          <Legend
            layout='vertical'
            align='right'
            verticalAlign='top'
            iconType={'circle'}
            height={36}
            width={228}
            wrapperStyle={{ paddingLeft: '20px' }}
            formatter={(value: string) => {
              return props.plotSeries[value as keyof IChartDataSeries].label || ''
            }}
          />

          {Object.entries(props.plotSeries).map(([key, value]) => {
            const dataPoints: { [index: string]: IChartDataPoint[] } = props.plotData as { [index: string]: IChartDataPoint[] }

            return <Scatter
              key={key}
              data={dataPoints[key]}
              dataKey={key}
              stroke={value.color}
              fill={value.color}
              strokeWidth={3}
              opacity={0.9}
              width={50}
            />
          })}
        </ScatterChart>
      </ResponsiveContainer>
    )
  }

  return (
    <AnalyticsPlot
      plotId='doseme-analytics-auc-vs-trough-outcomes'
      plotData={props.plotData}
      plotSeries={props.plotSeries}
      renderPlotContent={renderPlotContent()}
    />
  )
}
