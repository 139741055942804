import React from 'react'
import {
  BarChart,
  CartesianGrid,
  Bar,
  Tooltip,
  XAxis,
  YAxis,
  Legend
} from 'recharts'
import {
  neutrals50,
  neutrals300,
  neutrals500,
  neutrals700,
  blueSteel500
} from '@doseme/cohesive-ui'

import { IBasePlotProps, IChartDataSeries } from '../../../types'
import { AnalyticsPlot, AnalyticsTooltip } from '../../AnalyicsPlot'

export const DosesSimulatedByUserPlot: React.FC<IBasePlotProps> = (props) => {
  const dosesSimulatedByUserKeyNames: string[] = Object.keys(props.plotSeries) || []

  // Calculate the chart height dynamically based on the number of data points
  const barSize = 24
  const maxBarSize = 12
  const barCategoryGap = 8
  const axisPadding = 8
  const chartHeight = Math.max(
    150,
    (props.plotData.length * (maxBarSize + barCategoryGap * 3)) + (2 * axisPadding)
  )

  const renderPlotContent = () => {
    const chartWidth = 652
    const legendWidth = 272
    const totalWidth = chartWidth + legendWidth

    return (
      <div
        style={{
          minHeight: '240px',
          maxHeight: '360px',
          width: totalWidth,
          overflowY: 'auto',
          overflowX: 'hidden'
        }}
      >
        <BarChart
          layout='vertical'
          data={props.plotData}
          barCategoryGap={barCategoryGap}
          width={940}
          height={chartHeight}
        >
          <CartesianGrid
            stroke={neutrals300}
            strokeWidth={1}
            fill={neutrals50}
            vertical={true}
            horizontal={false}
          />
          <XAxis
            type='number'
            stroke={neutrals500}
            tick={{ stroke: neutrals700, fill: neutrals700 }}
            tickLine={{ stroke: neutrals500, fill: neutrals500 }}
            height={30}
          />
          <YAxis
            type='category'
            dataKey='name'
            interval='preserveStart'
            stroke={neutrals500}
            tick={{ stroke: neutrals700, fill: neutrals700 }}
            tickLine={{ stroke: neutrals500, fill: neutrals500 }}
            padding={{ top: axisPadding, bottom: axisPadding }}
            width={140}
          />
          <Tooltip
            shared={false}
            content={(props) => (
              <AnalyticsTooltip
                active={props.active}
                payload={props.payload}
                label={props.label}
                dataName={dosesSimulatedByUserKeyNames}
              />
            )}
            cursor={{ stroke: blueSteel500, opacity: 0.4 }}
          />
          <Legend
            layout='vertical'
            align='right'
            verticalAlign='top'
            height={36}
            width={legendWidth}
            className='doseme-analytics-legend-panel'
            content={() => (
              <div className='doseme-analytics-legend-panel legend-right'>
                {props.plotName}
                {Object.keys(props.plotSeries).map((key) => (
                  <div key={key} className='doseme-analytics-legend-item legend-right'>
                    <div className='doseme-analytics-legend-item-icon' style={{ backgroundColor: props.plotSeries[key].color }}/>
                    {props.plotSeries[key as keyof IChartDataSeries].label || key}
                  </div>
                ))}
              </div>
            )}
          />
          {Object.entries(props.plotSeries).map(([key, value]) => {
            return (
              <Bar
                key={key}
                dataKey={key}
                stackId='a'
                fill={value.color}
                opacity={0.9}
                barSize={barSize}
                maxBarSize={maxBarSize}
              />
            )
          })}
        </BarChart>
      </div>
    )
  }

  return (
    <AnalyticsPlot
      plotId='doseme-analytics-doses-simulated-by-user-graph'
      plotData={props.plotData}
      plotSeries={props.plotSeries}
      renderPlotContent={renderPlotContent()}
    />
  )
}
