import React from 'react'
import {
  ResponsiveContainer,
  CartesianGrid,
  Bar,
  BarChart,
  Cell,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { blueSteel500, neutrals300, neutrals50 } from '@doseme/cohesive-ui'

import { AnalyticsPlot, AnalyticsTooltip } from '../../AnalyicsPlot'
import { IBasePlotProps } from '../../../types'

export const UsageByDayPlot: React.FC<IBasePlotProps> = (props) => {
  const renderPlotContent = () => {
    return (
      <ResponsiveContainer width='100%' height={300}>
        <BarChart
          data={props.plotData}
          margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
        >
          <CartesianGrid
            stroke={neutrals300}
            strokeWidth={1}
            fill={neutrals50}
            vertical={false}
          />

          <XAxis dataKey='name' />

          <YAxis tickFormatter={(tick) => `${tick}%`} />

          <Tooltip
            content={(props) => (
              <AnalyticsTooltip
                active={props.active}
                payload={props.payload}
                label={props.label}
                dataName={['usage']}
              />
            )}
            cursor={{ stroke: blueSteel500, opacity: 0.8 }}
          />

          <Bar dataKey='usage' radius={[5, 5, 0, 0]}>
            {props.plotData.map((entry, index) => {
              return <Cell key={`cell-${index}`} fill={entry.color} />
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    )
  }

  return (
    <AnalyticsPlot
      plotId='doseme-analytics-usage-by-day-graph'
      plotData={props.plotData}
      plotSeries={props.plotSeries}
      renderPlotContent={renderPlotContent()}
    />
  )
}
