export const courseTimeToTherapeuticTargetMockData = {
  data: {
    type: '',
    attributes: {
      name: 'Course Time to Therapeutic Target by drug model',
      plotData: {
        plotSeries: {
          zeroToTwentyFourH: { label: '0-24h', type: 'bar', color: '#305d8a' },
          twentyFourToFortyEightH: { label: '24-48h', type: 'bar', color: '#a4a4d5' },
          fortyEightToSeventyTwoH: { label: '48-72h', type: 'bar', color: '#bcc8e5' },
          seventyTwoPlus: { label: '72h+', type: 'bar', color: '#f2882c' },
          didNotReach: { label: 'didNotReach', type: 'bar', color: '#c7c7c7' }
        },
        dataPoints: [
          {
            name: '1-Comp (Adult)',
            zeroToTwentyFourH: 30,
            twentyFourToFortyEightH: 30,
            fortyEightToSeventyTwoH: 10,
            seventyTwoPlus: 20,
            didNotReach: 10,
            tooltip: {
              zeroToTwentyFourH: { title: '1-Comp (Adult)', prefix: '0-24h to target', suffix: '(30 courses)', value: '30%' },
              twentyFourToFortyEightH: { title: '1-Comp (Adult)', prefix: '24-48h to target', suffix: '(30 courses)', value: '30%' },
              fortyEightToSeventyTwoH: { title: '1-Comp (Adult)', prefix: '48-72h to target', suffix: '(10 courses)', value: '10%' },
              seventyTwoPlus: { title: '1-Comp (Adult)', prefix: '72h+ to target', suffix: '(20 courses)', value: '20%' },
              didNotReach: { title: '1-Comp (Adult)', prefix: 'Did not reach', suffix: '(10 courses)', value: '10%' }
            }
          },
          {
            name: '2-Comp (Adult)',
            zeroToTwentyFourH: 75,
            twentyFourToFortyEightH: 0,
            fortyEightToSeventyTwoH: 0,
            seventyTwoPlus: 0,
            didNotReach: 25,
            tooltip: {
              zeroToTwentyFourH: { title: '2-Comp (Adult)', prefix: '0-24h to target', suffix: '(75 courses)', value: '75%' },
              twentyFourToFortyEightH: { title: '2-Comp (Adult)', prefix: '24-48h to target', suffix: '(0 courses)', value: '0%' },
              fortyEightToSeventyTwoH: { title: '2-Comp (Adult)', prefix: '48-72h to target', suffix: '(0 courses)', value: '0%' },
              seventyTwoPlus: { title: '2-Comp (Adult)', prefix: '72h+ to target', suffix: '(0 courses)', value: '0%' },
              didNotReach: { title: '2-Comp (Adult)', prefix: 'Did not reach', suffix: '(25 courses)', value: '25%' }
            }
          },
          {
            name: 'Obese: Enhanced (Adult)',
            zeroToTwentyFourH: 29,
            twentyFourToFortyEightH: 22,
            fortyEightToSeventyTwoH: 12,
            seventyTwoPlus: 37,
            didNotReach: 0,
            tooltip: {
              zeroToTwentyFourH: { title: 'Obese: Enhanced (Adult)', prefix: '0-24h to target', suffix: '(29 courses)', value: '29%' },
              twentyFourToFortyEightH: { title: 'Obese: Enhanced (Adult)', prefix: '24-48h to target', suffix: '(22 courses)', value: '22%' },
              fortyEightToSeventyTwoH: { title: 'Obese: Enhanced (Adult)', prefix: '48-72h to target', suffix: '(12 courses)', value: '12%' },
              seventyTwoPlus: { title: 'Obese: Enhanced (Adult)', prefix: '72h+ to target', suffix: '(37 courses)', value: '37%' },
              didNotReach: { title: 'Obese: Enhanced (Adult)', prefix: 'Did not reach', suffix: '(0 courses)', value: '0%' }
            }
          },
          {
            name: 'All Selected',
            zeroToTwentyFourH: 24,
            twentyFourToFortyEightH: 31,
            fortyEightToSeventyTwoH: 3,
            seventyTwoPlus: 32,
            didNotReach: 10,
            tooltip: {
              zeroToTwentyFourH: { title: 'All Selected', prefix: '0-24h to target', suffix: '(24 courses)', value: '24%' },
              twentyFourToFortyEightH: { title: 'All Selected', prefix: '24-48h to target', suffix: '(31 courses)', value: '31%' },
              fortyEightToSeventyTwoH: { title: 'All Selected', prefix: '48-72h to target', suffix: '(3 courses)', value: '3%' },
              seventyTwoPlus: { title: 'All Selected', prefix: '72h+ to target', suffix: '(32 courses)', value: '32%' },
              didNotReach: { title: 'All Selected', prefix: 'Did not reach', suffix: '(10 courses)', value: '10%' }
            }
          }
        ]
      }
    }
  },
  metadata: {
    filters: [
      { name: 'hospitals', value: ['1234'] },
      { name: 'startDate', value: '2024-07-01' },
      { name: 'endDate', value: '2024-09-30' }
    ]
  }
}
