import { AnalyticsPlotSkeleton, MessageOverlay } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { InfoBubble } from '@doseme/cohesive-ui'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCog } from '@fortawesome/free-solid-svg-icons'
// import { ListButton } from '@doseme/cohesive-ui'
import { useAnalyticsChartAKIRiskFactorsStore } from '../../../../hooks/useStore'
import { IBaseChartProps } from '../../types'
import { AKIRiskIndicatorsPlot } from './components'
import { formatErrorMessage } from '../../../PatientRouter/components/CourseProfile/components/DoseRecommendation/utils'

export const AKIRiskIndicators: React.FC<IBaseChartProps> = observer((props) => {
  const analyticsChartAKIRiskFactorsStore = useAnalyticsChartAKIRiskFactorsStore()

  useEffect(() => {
    analyticsChartAKIRiskFactorsStore.fetchAnalyticsChart()
  }, [props.hospitals, props.startDate, props.endDate])

  const plotContent = () => {
    if (
      analyticsChartAKIRiskFactorsStore.analyticsChartData
      && analyticsChartAKIRiskFactorsStore.loadState === 'loaded'
    ) {
      return (
        <>
          <div className='doseme-analytics-chart-plot'>
            <AKIRiskIndicatorsPlot
              plotData={analyticsChartAKIRiskFactorsStore.analyticsChartData.attributes.plotData.dataPoints}
              plotSeries={analyticsChartAKIRiskFactorsStore.analyticsChartData.attributes.plotData.plotSeries}
            />
          </div>

          <InfoBubble type='info' bubbleTitle={'Note:'} borderRadius={6} >
            <div className='doseme-analytics-chart-bubble'>
              Courses using adult hemodialysis, pediatric and neonate models have been excluded from AKI risk data.
            </div>
          </InfoBubble>
        </>
      )
    }

    if (['loadError, updateError'].includes(analyticsChartAKIRiskFactorsStore.loadState)) {
      return (
        <MessageOverlay
          type='error'
          headerText='Failed to load analytics chart'
          messageText={
            <div className='panel-error-overlay-text'>
              {formatErrorMessage(analyticsChartAKIRiskFactorsStore.error) || 'Something went wrong'}
            </div>
          }
          showBlur={false}
        >
          <AnalyticsPlotSkeleton
            loading={false}
            style={{ width: '924px', height: '284px' }}
          />
        </MessageOverlay>
      )
    }

    return <AnalyticsPlotSkeleton
      loading={analyticsChartAKIRiskFactorsStore.loadState === 'loading'}
      style={{ width: '924px', height: '284px' }}
    />
  }

  // Normally we would be issuing a GET request via our axiosClient to the appropriate chart endpoint
  // For now we have some mockData to pass on to the Plot component
  return (
    <div className='doseme-analytics-chart' key='aki-risk-indicators'>
      <div className='header-row'>
        <div className='title md-8'>AKI risk indicators</div>
        <div className='view-options md-4'>
          {/*<ListButton disabled={true}>*/}
          {/*  <FontAwesomeIcon icon={faCog} />&nbsp;View Options*/}
          {/*</ListButton>*/}
        </div>
      </div>
      {plotContent()}
    </div>
  )
})
