import { makeAutoObservable } from 'mobx'

import { IAdminHospitalSuperAdminInfoAnalyticsSettings } from './types'
import { AdminHospitalDetailsStore } from './AdminHospitalDetailsStore'

export class AdminHospitalSuperAdminInfoAnalyticsSettings implements IAdminHospitalSuperAdminInfoAnalyticsSettings {
  store: AdminHospitalDetailsStore

  id: string
  type: string
  attributes: IAdminHospitalSuperAdminInfoAnalyticsSettings['attributes']

  constructor(store: AdminHospitalDetailsStore, entity: IAdminHospitalSuperAdminInfoAnalyticsSettings) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
