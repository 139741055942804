export const aucRiskThresholdsMockData = {
  data: {
    type: '',
    attributes: {
      name: 'AUC risk thresholds',
      plotData: {
        plotSeries: {
          sixHundred: { label: '600 mg.h/L Threshold', type: 'line', color: '#f4d166' },
          sevenHundred: { label: '700 mg.h/L Threshold', type: 'line', color: '#f78a4b' },
          eightHundred: { label: '800 mg.h/L Threshold', type: 'line', color: '#b71d3e' }
        },
        dataPoints: [
          {
            name: 'June 2024',
            date: '2024-06-01',
            sixHundred: 6,
            sevenHundred: 8,
            eightHundred: 4,
            tooltip: {
              sixHundred: { title: 'June 2024', prefix: '600 mg.h/L Threshold', suffix: '(4.88%)', value: '6/123' },
              sevenHundred: { title: 'June 2024', prefix: '700 mg.h/L Threshold', suffix: '(6.5%)', value: '8/123' },
              eightHundred: { title: 'June 2024', prefix: '800 mg.h/L Threshold', suffix: '(3.25%)', value: '4/123' }
            }
          },
          {
            name: 'July 2024',
            date: '2024-07-01',
            sixHundred: 9,
            sevenHundred: 10,
            eightHundred: 2,
            tooltip: {
              sixHundred: { title: 'July 2024', prefix: '600 mg.h/L Threshold', suffix: '(9.78%)', value: '9/92' },
              sevenHundred: { title: 'July 2024', prefix: '700 mg.h/L Threshold', suffix: '(10.87%)', value: '10/92' },
              eightHundred: { title: 'July 2024', prefix: '800 mg.h/L Threshold', suffix: '(2.17%)', value: '2/92' }
            }
          },
          {
            name: 'August 2024',
            date: '2024-08-01',
            sixHundred: 14,
            sevenHundred: 12,
            eightHundred: 1,
            tooltip: {
              sixHundred: { title: 'August 2024', prefix: '600 mg.h/L Threshold', suffix: '(13.33%)', value: '14/105' },
              sevenHundred: { title: 'August 2024', prefix: '700 mg.h/L Threshold', suffix: '(11.43%)', value: '12/105' },
              eightHundred: { title: 'August 2024', prefix: '800 mg.h/L Threshold', suffix: '(0.95%)', value: '1/105' }
            }
          }
        ]
      }
    }
  },
  metadata: {
    filters: [
      { name: 'hospitals', value: ['1234'] },
      { name: 'startDate', value: '2024-07-01' },
      { name: 'endDate', value: '2024-09-30' }
    ]
  }
}
