import React from 'react'
import {
  BarChart,
  CartesianGrid,
  Bar,
  Tooltip,
  XAxis,
  YAxis,
  LabelList,
  LabelProps
} from 'recharts'
import {
  neutrals50,
  neutrals300,
  neutrals500,
  neutrals700,
  blueSteel500
} from '@doseme/cohesive-ui'

import { AnalyticsPlot, AnalyticsTooltip } from '../../AnalyicsPlot'
import { IBasePlotProps } from '../../../types'

export const UserLoginNumbersPlot: React.FC<IBasePlotProps> = (props) => {
  const plotData = props.plotData
  const plotSeries = props.plotSeries

  // As this chart needs to be scrollable, we need to calculate the overall height based on the number
  // of datapoints, and factor in the bar sizes, gaps and padding
  const barSize = 12
  const maxBarSize = 12
  const barCategoryGap = 4
  const axisPadding = 8
  const chartHeight = Math.max(
    150,
    (props.plotData.length * (maxBarSize + barCategoryGap * 3)) + (2 * axisPadding)
  )

  const renderPlotContent = () => {

    // Once we need to re-use this it should move to AnalyticsPlot.tsx and the section types.ts files
    interface CustomLabelProps extends LabelProps {
      type?: string
      bgColor?: string
      borderColor?: string
      textColor?: string
    }

    const renderCustomizedLabel = (props: CustomLabelProps) => {
      const { x, y, width, height, offset, value, type, bgColor, borderColor, textColor  } = props
      const borderStroke = borderColor || neutrals300
      const bgFill = bgColor || 'snow'
      const textFill = textColor || 'black'
      const labelType = type || 'pill'

      const svgWidth = (!width || (typeof width === 'string') ? 20 : width)
      const svgHeight = (!height || (typeof height === 'string') ? 8 : height)
      const svgOffset = (!offset ? 5 : offset)

      const svgX = svgWidth + (!x ? 0 : (typeof x === 'string' ? parseFloat(x) : x))
      const svgY = svgHeight + (!y ? 0 : (typeof y === 'string' ? parseFloat(y) : y))

      if (labelType === 'circle') {
        const radius = ((svgHeight && svgHeight < 8) ? svgHeight : 8)

        return (
          <g style={{ fontFamily: 'Arial', fontSize: '8px' }}>
            <circle cx={svgX + svgOffset * 3} cy={svgY + (radius + svgOffset) / 2} r={radius} fill={bgFill}
              stroke={borderStroke}/>
            <text x={svgX + svgOffset * 3} y={svgY + (radius + svgOffset) / 2} dy={1} fill={textFill} textAnchor="middle"
              dominantBaseline="middle">
              {value}
            </text>
          </g>
        )
      }

      return (
        <g style={{ fontFamily: 'Arial', fontSize: '10px' }}>
          <rect x={svgX + svgOffset} y={svgY - (svgOffset * 2) - 1.5} width={20 + svgOffset} height={height} rx={svgHeight / 2}
            style={{ fill: bgFill, stroke: borderStroke, strokeWidth: 1 }}/>
          <text x={svgX + svgOffset * 4} y={svgY - (svgOffset* 1.5) -1.5} dx={-2} dy={svgHeight / 3} fill={textFill} textAnchor="middle"
            dominantBaseline="middle">
            {value}
          </text>
        </g>
      )
    }

    return (
      <div
        style={{
          minHeight: '240px',
          maxHeight: '360px',
          width: '100%',
          overflowY: 'auto',
          overflowX: 'hidden'
        }}
      >
        <BarChart
          layout='vertical'
          data={plotData}
          barCategoryGap={barCategoryGap}
          width={940}
          height={chartHeight}
        >
          <CartesianGrid
            stroke={neutrals300}
            strokeWidth={1}
            fill={neutrals50}
            vertical={true}
            horizontal={false}
          />
          <XAxis
            type='number'
            dataKey='userLogins'
            stroke={neutrals500}
            tick={{ stroke: neutrals700, fill: neutrals700 }}
            tickLine={{ stroke: neutrals500, fill: neutrals500 }}
            height={30} // We don't have an Axis label, so we can reduce the default width down from 60
          />
          <YAxis
            type='category'
            dataKey='name'
            interval='preserveStart'
            stroke={neutrals500}
            tick={{ stroke: neutrals700, fill: neutrals700 }}
            tickLine={{ stroke: neutrals500, fill: neutrals500 }}
            padding={{ top: axisPadding, bottom: axisPadding }}
            width={140}
          />
          <Tooltip
            content={(props) => (
              <AnalyticsTooltip
                active={props.active}
                payload={props.payload}
                label={props.label}
                dataName={['userLogins']}
              />)}
            cursor={{ stroke: blueSteel500, opacity: 0.4 }}
          />
          <Bar
            dataKey='userLogins'
            fill={plotSeries.userLogins.color}
            stroke={plotSeries.userLogins.borderColor}
            opacity={0.9}
            activeBar={true}
            barSize={barSize}
            maxBarSize={maxBarSize}
          >
            <LabelList
              dataKey='userLogins'
              position='right'
              content={renderCustomizedLabel}
            />
          </Bar>
        </BarChart>
      </div>
    )
  }

  return (
    <AnalyticsPlot
      plotId='doseme-analytics-user-logins-graph'
      plotData={plotData}
      plotSeries={plotSeries}
      renderPlotContent={renderPlotContent()}
    />
  )
}
