import { AnalyticsPlotSkeleton, MessageOverlay } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import { useAnalyticsChartUsageByHourStore } from '../../../../hooks/useStore'
import { IBaseChartProps } from '../../types'
import { UsageByHourPlot } from './components'
import { formatErrorMessage } from '../../../PatientRouter/components/CourseProfile/components/DoseRecommendation/utils'

export const UsageByHour: React.FC<IBaseChartProps> = observer((props) => {
  const usageByHourChartStore = useAnalyticsChartUsageByHourStore()

  useEffect(() => {
    usageByHourChartStore.fetchAnalyticsChart(
      props.startDate,
      props.endDate,
      props.hospitals
    )
  }, [props.hospitals, props.startDate, props.endDate])

  const plotContent = () => {
    if (
      usageByHourChartStore.analyticsChartData
      && usageByHourChartStore.loadState === 'loaded'
    ) {
      return (
        <div className='doseme-analytics-chart-plot'>
          <UsageByHourPlot
            plotData={usageByHourChartStore.analyticsChartData.attributes.plotData.dataPoints}
            plotSeries={usageByHourChartStore.analyticsChartData.attributes.plotData.plotSeries}
            plotName={usageByHourChartStore.analyticsChartData.attributes.name}
          />
        </div>
      )
    }

    if (['loadError, updateError'].includes(usageByHourChartStore.loadState)) {
      return (
        <MessageOverlay
          type='error'
          headerText='Failed to load analytics chart'
          messageText={
            <div className='panel-error-overlay-text'>
              {formatErrorMessage(usageByHourChartStore.error) || 'Something went wrong'}
            </div>
          }
          showBlur={false}
        >
          <AnalyticsPlotSkeleton
            loading={false}
            style={{ width: '924px', height: '284px' }}
          />
        </MessageOverlay>
      )
    }

    return <AnalyticsPlotSkeleton
      loading={usageByHourChartStore.loadState === 'loading'}
      style={{ width: '924px', height: '284px' }}
    />
  }

  // Normally we would be issuing a GET request via our axiosClient to the appropriate chart endpoint
  // For now we have some mockData to pass on to the Plot component
  return (
    <div className='doseme-analytics-chart' key='usage-by-hour'>
      <div className='header-row'>
        <div className='title md-8'>Usage by hour of the day</div>
      </div>
      {plotContent()}
    </div>
  )
})
