import { AnalyticsPlotSkeleton, BasicList, IHeaderItem, IRowElement } from '@doseme/cohesive-ui'
import React, { useEffect, useMemo } from 'react'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCog } from '@fortawesome/free-solid-svg-icons'
// import { ListButton } from '@doseme/cohesive-ui'
import { IBaseChartProps, IChartDataPoint } from '../../types'
import { useAnalyticsChartMedianHoursAndDosesToTargetStore } from '../../../../hooks/useStore'

import './index.scss'

export const MedianHoursAndDosesToTarget: React.FC<IBaseChartProps> = observer((props) => {
  const medianHoursAndDosesToTargetChartStore = useAnalyticsChartMedianHoursAndDosesToTargetStore()

  useEffect(() => {
    medianHoursAndDosesToTargetChartStore.fetchAnalyticsChart()
  }, [props.hospitals, props.startDate, props.endDate])

  const columns: IHeaderItem[] = [
    { name: 'Drug Model', className: 'drug-column' },
    { name: 'Median hours to target', className: 'data-column' },
    { name: 'Median doses to target', className: 'data-column' },
    { name: 'Courses to reach target', className: 'data-column' },
    { name: 'Courses to not hit target', className: 'data-column' }
  ]

  const rows: IRowElement[] = useMemo(() => {
    if (medianHoursAndDosesToTargetChartStore.loadState === 'loaded' && medianHoursAndDosesToTargetChartStore.analyticsChartData) {
      const dataPoints = medianHoursAndDosesToTargetChartStore.analyticsChartData.attributes.plotData.dataPoints as IChartDataPoint[]

      return dataPoints.map((row: any) => {
        return {
          id: row.name,
          className: classnames({ 'overall-row': row.name === 'Overall' }),
          columns: [
            {
              name: 'Drug Model', text: row.name,
              className: 'drug-column',
              element: (
                <span className='drug-column'>
                  {row.name}
                </span>
              )
            },
            { name: 'Median hours to target', text: row.medianHoursToTarget, className: 'data-column' },
            { name: 'Median doses to target', text: row.medianDosesToTarget, className: 'data-column' },
            { name: 'Courses to reach target', text: row.numberOfCoursesToTarget, className: 'data-column' },
            { name: 'Courses to not hit target', text: row.coursesDidNotReach, className: 'data-column' }
          ]
        }
      })
    }

    return []
  }, [medianHoursAndDosesToTargetChartStore.loadState])

  const plotContent = () => {
    if (
      medianHoursAndDosesToTargetChartStore.analyticsChartData
      && medianHoursAndDosesToTargetChartStore.loadState === 'loaded'
    ) {
      return (
        <BasicList
          cols={columns}
          minRowsToShow={3}
          data={rows}
          header={true}
          textIfEmpty='No drug models selected'
          cellClassName='ife-basic-list-cell-height'
        />
      )
    }

    return <AnalyticsPlotSkeleton
      loading={medianHoursAndDosesToTargetChartStore.loadState === 'loading'}
      style={{ height: '300px' }}
    />
  }

  // Normally we would be issuing a GET request via our axiosClient to the appropriate chart endpoint
  // For now we have some mockData to pass on to the Plot component
  return (
    <div className='doseme-analytics-chart median-hours-and-doses-to-target' key='median-hours-and-doses-to-target'>
      <div className='header-row'>
        <div className='title md-8'>Median hours and doses to target</div>
        {/* <div className='view-options md-4'>
          <ListButton disabled={true}>
           <FontAwesomeIcon icon={faCog} />&nbsp;View Options
          </ListButton>
        </div> */}
      </div>
      {plotContent()}
    </div>
  )
})
