import { useEffect, useState } from 'react'
import { useIntercom } from 'react-use-intercom'
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { ToastContainer } from 'react-toastify'
import useScreenSize from 'use-screen-size'
import { observer } from 'mobx-react-lite'

import { StoreProvider } from './store/StoreProvider'
import { reactPlugin } from './components/AzureAppInsights'
import { AppInsightsErrorBoundary } from './components/AppInsightsErrorBoundary'
import { UnsupportedDeviceWidth, minSupportedWidth } from './components/UnsupportedDeviceWidth'
import { TopMenuBar } from './components/TopMenuBar'
import { IntegratedFooter } from './components/IntegratedFooter'
import { StandaloneSessionMiddleware } from './components/SessionMiddleware'
import { ScrollToTop } from './shared/ScrollToTop'
import {
  buildAuthRoutes,
  buildAdminRoutes,
  buildAnalyticsRoutes,
  buildDoseMeAnalyticsRoutes,
  buildPatientsRoutes,
  buildResourcesRoutes,
  buildRedirectRoute
} from './routing/routeBuilder'
import { ClinicianSettings } from './components/ClinicianSettings'
import { useAuthStore, useClinicianStore, useHospitalStore } from './hooks/useStore'
import { IMatchCrumb } from './routing/types'

import './index.scss'

interface IWrapperProps {
  width: number
}

const NavWrapper: React.FC<IWrapperProps> = (props) => {
  return (
    <div>
      <ScrollToTop />
      <div className='app-wrapper'>
        <AppInsightsContext.Provider value={reactPlugin}>
          <TopMenuBar />
          {props.width < minSupportedWidth ? (
            <UnsupportedDeviceWidth />
          ) : (
            <AppInsightsErrorBoundary>
              <StandaloneSessionMiddleware>
                <Outlet />
              </StandaloneSessionMiddleware>
            </AppInsightsErrorBoundary>
          )}
          <IntegratedFooter width={props.width} />
        </AppInsightsContext.Provider>
      </div>
    </div>
  )
}

const AuthNavWrapper: React.FC<IWrapperProps> = (props) => {
  return (
    <div>
      <ScrollToTop />
      <div className='app-wrapper'>
        <AppInsightsContext.Provider value={reactPlugin}>
          <TopMenuBar />
          {props.width < minSupportedWidth ? (
            <UnsupportedDeviceWidth />
          ) : (
            <AppInsightsErrorBoundary>
              <div className='login-wrapper'>
                <Outlet />
              </div>
            </AppInsightsErrorBoundary>
          )}
          <IntegratedFooter width={props.width} />
        </AppInsightsContext.Provider>
      </div>
    </div>
  )
}

const StandaloneApp: React.FC = observer(() => {
  const [hasIntercomBooted, setHasIntercomBooted] = useState(false)

  const size = useScreenSize()

  const authStore = useAuthStore()
  const clinicianStore = useClinicianStore()
  const hospitalStore = useHospitalStore()

  useEffect(() => {
    if (clinicianStore.loadState === 'initial' && authStore.auth && authStore.isAuthenticated()) {
      clinicianStore.fetchClinician(authStore.auth.attributes.clinicianId)
    }

    if (hospitalStore.loadState === 'initial' && authStore.auth && authStore.isAuthenticated()) {
      hospitalStore.fetchHospital(authStore.auth.attributes.hospitalId)
    }
  }, [authStore.auth, authStore.loadState])

  const standaloneAppRoutes = [
    {
      path: '/cliniciansettings',
      handle: {
        crumb: {
          label: () => 'My profile'
        } as IMatchCrumb
      },
      element: <ClinicianSettings />
    },
    buildPatientsRoutes(),
    buildResourcesRoutes(false),
    buildAdminRoutes(false)
  ]

  if (clinicianStore.canViewAnalyticsHub()) {
    standaloneAppRoutes.push(...[
      buildAnalyticsRoutes(false)
    ])

    if (window.env.SHOW_DMA) {
      standaloneAppRoutes.push(...[
        buildDoseMeAnalyticsRoutes(false)
      ])
    }
  }

  if (!hasIntercomBooted && window.env.INTERCOM_APP_ID) {
    const { boot, shutdown } = useIntercom()

    // Delete any existing Intercom session before booting the new instance
    shutdown()

    boot({
      customAttributes: {
        instance: window.env.INSTALLATION,
        vendor: window.env.VENDOR_MODE
      }
    })

    setHasIntercomBooted(true)
  }

  const router = createBrowserRouter([
    {
      path: '',
      element: <NavWrapper width={size.width} />,
      children: [buildRedirectRoute('')]
    },
    {
      path: '/',
      element: <AuthNavWrapper width={size.width} />,
      loader: () => {
        if (authStore.loadState === 'initial') {
          authStore.authSession()
        }

        return null
      },
      children: [
        ...buildAuthRoutes()
      ]
    },
    {
      path: '/',
      element: <NavWrapper width={size.width} />,
      children: standaloneAppRoutes
    },
    {
      path: '*',
      element: <NavWrapper width={size.width} />,
      children: [buildRedirectRoute('*')]
    }
  ])

  return (
    <>
      <ToastContainer newestOnTop={true} closeOnClick={true} />
      <StoreProvider>
        <RouterProvider router={router} />
      </StoreProvider>
    </>
  )
})

export { StandaloneApp }
