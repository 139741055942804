import { Container, Row, ControlButton, Modal, InfoModal, BackArrowButton, Col } from '@doseme/cohesive-ui'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import useScreenSize from 'use-screen-size'
import classnames from 'classnames'

import { Header } from '../Header'
import { handleBackButton } from '../../../../utils/navigation'
import { CourseList } from './components/CourseList'
import { CoursePreviewPanel } from './components/CoursePreviewPanel'
import { useHospitalStore, useClinicianStore, usePatientCoursesStore, usePatientStore } from '../../../../hooks/useStore'
import { PatientLoadingPage } from '../../PatientLoadingPage'

import './index.scss'

const PatientProfile: React.FC = observer(() => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const hospitalStore = useHospitalStore()
  const clinicianStore = useClinicianStore()
  const patientCoursesStore = usePatientCoursesStore()
  const patientStore = usePatientStore()

  const patientId = params.patientId!

  const size = useScreenSize()

  const [selectedCourseId, setSelectedCourseId] = useState<string | undefined>('')
  const [showModal, setShowModal] = useState<boolean>(false)

  const handleAddCourseButton = () => {
    navigate(`/patients/${patientId}/courses/new`, { state: { from: `/patients/${patientId}` } })
  }

  useEffect(() => {
    // FIXME - IFE-1234 - the newPatientId and patientCoursesStore.loadState is used to prevent refetching courses
    // for the same patient. It could be removed by solving whatever is causing courses to load twice when
    // launching in integrated
    if (patientId &&
      (
        !['loading', 'updating'].includes(patientCoursesStore.loadState)
        || patientId !== patientCoursesStore.newPatientId
      )
    ) {
      patientCoursesStore.fetchPatientCourses(patientId)
      patientStore.fetchPatient(patientId)
    }
  }, [patientId])

  if (patientCoursesStore.loadState === 'loadError') {
    if (window.env.APP_MODE === 'standalone') {
      navigate('/patients')

      return null
    }

    throw Error(patientCoursesStore.error || 'Not found')
  }

  if (
    ['initial', 'loading'].includes(hospitalStore.loadState)
    || ['initial', 'loading'].includes(clinicianStore.loadState)
    || ['initial', 'loading'].includes(patientCoursesStore.loadState)
    || ['initial', 'loading'].includes(patientStore.loadState)
  ) {
    return (
      <PatientLoadingPage />
    )
  }

  const isTabletSize = size.width <= 1080

  if (!isTabletSize) {
    return (
      <div>
        <Header patientId={patientId} isOnline={false} showEditButton />
        <div className={classnames('patient-profile-content d-block', {
          'wide': size.width > 1240,
          'medium': size.width <= 1240
        })}>
          <div className='course-history-header'>
            {window.env.VENDOR_MODE === 'standalone' && (
              <div className='patient-profile-back-btn'>
                <BackArrowButton data-testid='back-btn' onClick={() => handleBackButton('/patients', navigate, location)} />
              </div>
            )}
            <span className='course-history-title'>Course List</span>
            <ControlButton icon={faPlus} onClick={handleAddCourseButton}>
              Create new course
            </ControlButton>
          </div>

          <Container fluid={true}>
            <Row>
              <Col className='course-list' key='list-col' width={6}>
                <CourseList
                  patientId={patientId}
                  selectedCourseId={selectedCourseId}
                  setSelectedCourseId={setSelectedCourseId}
                  isTabletSize={isTabletSize}
                />
              </Col>
              <Col className='course-preview' data-testid='course-preview-panel' key='preview-col' width={6}>
                <CoursePreviewPanel
                  patientId={patientId}
                  selectedCourseId={selectedCourseId === undefined ? '' : selectedCourseId}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    )
  }

  return (
    <div>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false)
          setSelectedCourseId(undefined)
        }}
        messageOnly={true}
      >
        <InfoModal
          size='m'
          title=''
          message={
            <div data-testid='course-preview-panel' className='w-100'>
              <CoursePreviewPanel
                patientId={patientId}
                selectedCourseId={selectedCourseId === undefined ? '' : selectedCourseId}
              />
            </div>
          }
          onDismiss={() => {
            setShowModal(false)
            setSelectedCourseId(undefined)
          }}
          messageOnly={true}
        />
      </Modal>

      <Header patientId={patientId} isOnline={false} showEditButton />
      <div className='patient-profile-content narrow d-block'>
        <div className='course-history-header'>
          {window.env.VENDOR_MODE === 'standalone' && (
            <div className='patient-profile-back-btn'>
              <BackArrowButton data-testid='back-btn' onClick={() => handleBackButton('/patients', navigate, location)} />
            </div>
          )}
          <span className='course-history-title'>Course List</span>
          <ControlButton icon={faPlus} onClick={handleAddCourseButton}>
            Create new course
          </ControlButton>
        </div>

        <Container fluid={true}>
          <div className='tablet-view-course-list'>
            <CourseList
              patientId={patientId}
              selectedCourseId={selectedCourseId}
              setSelectedCourseId={setSelectedCourseId}
              setShowCoursePreviewModal={setShowModal}
              isTabletSize={isTabletSize}
            />
          </div>
        </Container>
      </div>
    </div>
  )
})

export { PatientProfile }
