import {
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { INPUT_GREY, LIGHTGREY } from '@doseme/cohesive-ui'

import { SmartXAxisTick } from './SmartXAxisTick'
import {
  daysSincePreviousDose,
  xAxisTickFormatter
} from '../utils'
import { IDataSetRow } from '../types'
import { GCSFMarker } from './GCSFMarker'
import { IPlotAdministration, IPredictedPlotAdministrations } from '../../../types'

import '../index.scss'

interface IProps {
  data: IDataSetRow[]
  brushContent: JSX.Element | null
  hospitalTimezone: string
  historicalAdministrations: IPlotAdministration[] | null
  predictedAdministrations: IPredictedPlotAdministrations
}

export const GCSFPlot: React.FC<IProps> = (props) => {
  const CustomTooltip = ({ payload, label }: any) => {
    const GCSFData = payload[0]?.payload.GCSFData
    if (!GCSFData) {
      return null
    }

    let timeLabel = xAxisTickFormatter(label, props.hospitalTimezone)

    // Add day of cycle (cycle begins again for each dose)
    if (props.historicalAdministrations) {
      const day = daysSincePreviousDose(
        label,
        props.historicalAdministrations,
        props.predictedAdministrations,
        'Docetaxel'
      ) + 1
      timeLabel = `${timeLabel} (day ${day} of cycle)`
    }

    return (
      <div className='custom-tooltip-outer'>
        <div className='time-title'>{timeLabel}</div>
        <div className='GCSF-value'>
          {GCSFData.administrationType.name}:&nbsp;
          <b>
            {`${GCSFData?.amount.value} ${GCSFData?.amount.unit.name}`}
          </b>
        </div>
      </div>
    )
  }

  return (
    <>
      <hr className='dosing-profile-plot-seperator' />
      <div className='dosing-profile-plot-graph-GCSF'>
        <ResponsiveContainer width='99%'>
          <ComposedChart
            height={60}
            syncId='syncPlots'
            data={props.data}
            margin={{
              top: 5,
              right: 0,
              bottom: 10,
              left: -15
            }}
          >
            <CartesianGrid stroke={LIGHTGREY} fill={INPUT_GREY} horizontal={false} />

            <YAxis
              id='GCSF-plot-y-axis'
              unit=''
              type='number'
              domain={['0', '2']}
              scale='linear'
              label={{
                value: 'GCSFs',
                position: 'inside',
                angle: 0,
                dx: 3,
                fontWeight: 'bold'
              }}
              ticks={[1]}
              hide={false}
              orientation='left'
              strokeOpacity={0}
              tickCount={5}
              tick={{ opacity: 0 }}
            />

            <XAxis
              id='GCSF-plot-x-axis'
              dataKey='time'
              type='number'
              stroke={INPUT_GREY}
              includeHidden
              domain={['dataMin', 'dataMax']}
              tick={<SmartXAxisTick />}
              tickFormatter={(value: number) => {
                return xAxisTickFormatter(value, props.hospitalTimezone)
              }}
              tickCount={5}
            />

            <Line
              name='GCSF'
              dataKey='GCSF'
              dot={<GCSFMarker />}
              stroke='none'
              isAnimationActive={false}
              activeDot={false}
            />

            <Tooltip content={CustomTooltip} cursor={{ strokeDasharray: '3 3' }} />

            {props.brushContent}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </>
  )
}
