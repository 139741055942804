import { AnalyticsPlotSkeleton, MessageOverlay } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCog } from '@fortawesome/free-solid-svg-icons';
// import { ListButton } from '@doseme/cohesive-ui';
import { useAnalyticsChartDosesSimulatedByUserStore } from '../../../../hooks/useStore'
import { IBaseChartProps } from '../../types'
import { DosesSimulatedByUserPlot } from './components'
import { formatErrorMessage } from '../../../PatientRouter/components/CourseProfile/components/DoseRecommendation/utils'

export const DosesSimulatedByUser: React.FC<IBaseChartProps> = observer((props) => {
  const analyticsChartDosesSimulatedByUserStore = useAnalyticsChartDosesSimulatedByUserStore()

  useEffect(() => {
    analyticsChartDosesSimulatedByUserStore.fetchAnalyticsChart(
      props.startDate,
      props.endDate,
      props.hospitals
    )
  }, [props.hospitals, props.startDate, props.endDate])

  const plotContent = () => {
    if (
      analyticsChartDosesSimulatedByUserStore.analyticsChartData
      && analyticsChartDosesSimulatedByUserStore.loadState === 'loaded'
    ) {
      return (
        <div className='doseme-analytics-chart-plot'>
          <DosesSimulatedByUserPlot
            plotData={analyticsChartDosesSimulatedByUserStore.analyticsChartData.attributes.plotData.dataPoints}
            plotSeries={analyticsChartDosesSimulatedByUserStore.analyticsChartData.attributes.plotData.plotSeries}
            plotName={analyticsChartDosesSimulatedByUserStore.analyticsChartData.attributes.name}
          />
        </div>
      )
    }

    if (['loadError, updateError'].includes(analyticsChartDosesSimulatedByUserStore.loadState)) {
      return (
        <MessageOverlay
          type='error'
          headerText='Failed to load analytics chart'
          messageText={
            <div className='panel-error-overlay-text'>
              {formatErrorMessage(analyticsChartDosesSimulatedByUserStore.error) || 'Something went wrong'}
            </div>
          }
          showBlur={false}
        >
          <AnalyticsPlotSkeleton
            loading={false}
            style={{ width: '924px', height: '372px' }}
          />
        </MessageOverlay>
      )
    }

    return <AnalyticsPlotSkeleton
      loading={analyticsChartDosesSimulatedByUserStore.loadState === 'loading'}
      style={{ width: '924px', height: '372px' }}
    />
  }

  return (
    <div className='doseme-analytics-chart' key='doses-simulated-by-user'>
      <div className='header-row'>
        <div className='title md-8'>Doses Simulated by User</div>
        <div className='view-options md-4'>
          {/* <ListButton disabled={true}>
          <FontAwesomeIcon icon={faCog} /> View Options
        </ListButton> */}
        </div>
      </div>
      {plotContent()}
    </div>
  )
})
