import moment from 'moment-timezone'

import {
  IDosingPlotPoints,
  INeutropeniaGrade,
  IPlotAdministration,
  IPlotDataPoint,
  IPlotMetaData,
  IPlotObservation,
  IPredictedPlotAdministrations,
  ISecrTrendData
} from '../../types'
import { chartFormat } from '../../../../../../../../../../constants/timeFormat'
import {
  isAdministrationTypeGCSF,
  isObservationTypeANC,
  isObservationTypeDialysis,
  isObservationTypeINR,
  isObservationTypeLevel,
  isObservationTypeSeCr,
  isObservationTypeWBC
} from '../../utils'
import {
  IDataSetDict,
  IDataSetRow,
  IFormattedNeutropeniaGrade,
  ISecrDataSetDict,
  ISecrDataSetRow
} from './types'
import { getMax, getNonZeroMin } from '../../../../../../../../../../utils/minMax'

const timeNameFormatter = (unixTimestamp: number, hospitalTimezone: string) => {
  if (!isFinite(unixTimestamp)) {
    return ''
  }
  const tickDate = moment.unix(unixTimestamp).tz(hospitalTimezone)

  return tickDate.format(chartFormat)
}

const getTimeUnix = (point: IPlotDataPoint | IDataSetRow) => {
  if (typeof point.time === 'string') {
    return moment(point.time).unix()
  }

  return point.time
}

export const binarySearchForIndex = (targetTime: number, plotSimulation: IPlotDataPoint[] | IDataSetRow[]) => {
  if (targetTime <= getTimeUnix(plotSimulation[0])) {
    return 0
  }
  if (targetTime >= getTimeUnix(plotSimulation[plotSimulation.length - 1])) {
    return plotSimulation.length - 1
  }

  let lo = 0
  let hi = plotSimulation.length - 1

  while (lo <= hi) {
    let mid = Math.floor(lo + ((hi - lo) / 2))

    if (targetTime < getTimeUnix(plotSimulation[mid])) {
      hi = mid - 1
    } else if (targetTime > getTimeUnix(plotSimulation[mid])) {
      lo = mid + 1
    } else {
      return mid
    }
  }

  // lo == hi + 1
  return Math.abs(getTimeUnix(plotSimulation[lo]) - targetTime) < Math.abs(getTimeUnix(plotSimulation[hi]) - targetTime)
    ? lo
    : hi
}

const getDoseDataPoint = (targetTime: number, plotSimulation: IPlotDataPoint[]) => {
  let index = 0
  if (!plotSimulation || index >= plotSimulation.length) {
    return
  }

  const closestTimeIndex = binarySearchForIndex(targetTime, plotSimulation)

  return plotSimulation[closestTimeIndex]
}

const getHistoricalPlotData = (
  plotPoints: IDosingPlotPoints | null,
  secrTrendData: ISecrTrendData | null,
  plotObservations: IPlotObservation[],
  plotExcludedObservations: IPlotObservation[],
  historicalAdministrations: IPlotAdministration[] | null,
  futureXDatetime?: number | null
): {
  dataSets: IDataSetDict
  secrDataSets: ISecrDataSetDict
  dialysisData: IPlotObservation[]
  nonZeroMin: number
  max: number
} => {
  let dataSets: IDataSetDict = {}
  let secrDataSets: ISecrDataSetDict = {}
  const dialysisData: IPlotObservation[] = []
  let nonZeroMin = 1
  let max = 0

  if (!plotPoints) {
    return {
      dataSets,
      secrDataSets,
      dialysisData,
      nonZeroMin,
      max
    }
  }

  if (historicalAdministrations) {
    for (let i = 0; i < historicalAdministrations.length; i++) {
      if (isAdministrationTypeGCSF(historicalAdministrations[i].administrationType)) {
        const eUnixDT = moment(historicalAdministrations[i].time).unix()
        if (!(eUnixDT in dataSets)) {
          dataSets[eUnixDT] = { GCSF: 1, GCSFData: historicalAdministrations[i] }
        } else {
          dataSets[eUnixDT].GCSF = 1
          dataSets[eUnixDT].GCSFData = historicalAdministrations[i]
        }
      }
    }
  }

  if (plotPoints.individualized) {
    plotPoints.individualized.forEach((e) => {
      const eUnixDT = moment(e.time).unix()

      if (futureXDatetime && eUnixDT >= futureXDatetime) {
        return
      }

      nonZeroMin = getNonZeroMin(nonZeroMin, e.amount)
      max = getMax(max, e.amount)

      if (!(eUnixDT in dataSets)) {
        dataSets[eUnixDT] = { individualized_historical: e.amount }

        return
      }

      dataSets[eUnixDT].individualized_historical = e.amount
    })

    if (historicalAdministrations) {
      for (let i = 0; i < historicalAdministrations.length; i++) {
        if (!isAdministrationTypeGCSF(historicalAdministrations[i].administrationType)) {
          const desiredTime = moment(historicalAdministrations[i].time).unix()
          const doseDataPoint = getDoseDataPoint(desiredTime, plotPoints.individualized)

          if (doseDataPoint) {
            const dPUnixDT = moment(doseDataPoint.time).unix()
            dataSets[dPUnixDT].individualized_historical_dose = doseDataPoint.amount
          }
        }
      }
    }
  }

  if (plotPoints.population) {
    plotPoints.population.forEach((e) => {
      const eUnixDT = moment(e.time).unix()

      if (futureXDatetime && eUnixDT >= futureXDatetime) {
        return
      }

      nonZeroMin = getNonZeroMin(nonZeroMin, e.amount)
      max = getMax(max, e.amount)

      if (!(eUnixDT in dataSets)) {
        dataSets[eUnixDT] = { population_historical: e.amount }

        return
      }

      dataSets[eUnixDT].population_historical = e.amount
    })

    if (historicalAdministrations) {
      for (let i = 0; i < historicalAdministrations.length; i++) {
        if (!isAdministrationTypeGCSF(historicalAdministrations[i].administrationType)) {
          const desiredTime = moment(historicalAdministrations[i].time).unix()
          const doseDataPoint = getDoseDataPoint(desiredTime, plotPoints.population)

          if (doseDataPoint) {
            const dPUnixDT = moment(doseDataPoint.time).unix()
            dataSets[dPUnixDT].population_historical_dose = doseDataPoint.amount
          }
        }
      }
    }
  }

  if (plotObservations) {
    plotObservations.forEach((e) => {
      if (isObservationTypeLevel(e.observationType) || isObservationTypeINR(e.observationType)) {
        const eUnixDT = moment(e.time).unix()
        if (!(eUnixDT in dataSets)) {
          dataSets[eUnixDT] = { observation: e }

          return
        }

        nonZeroMin = getNonZeroMin(nonZeroMin, e.amount.value)
        max = getMax(max, e.amount.value)

        dataSets[eUnixDT].observation = e
      }

      if (isObservationTypeDialysis(e.observationType)) {
        const eUnixDT = moment(e.time).unix()
        dialysisData.push(e)
        // needed to ensure the end of the dialysis session is rendered if at the end of the x-axis
        const sessionEnd = eUnixDT + e.amount.value * 60 * 60

        if (!(eUnixDT in dataSets)) {
          dataSets[eUnixDT] = {}
        }

        if (!(sessionEnd in dataSets)) {
          dataSets[sessionEnd] = {}
        }
      }

      if (isObservationTypeANC(e.observationType)) {
        const eUnixDT = moment(e.time).unix()
        if (!(eUnixDT in dataSets)) {
          dataSets[eUnixDT] = { ANC: e }

          return
        }

        nonZeroMin = getNonZeroMin(nonZeroMin, e.amount.value)
        max = getMax(max, e.amount.value)

        dataSets[eUnixDT].ANC = e
      }

      if (isObservationTypeWBC(e.observationType)) {
        const eUnixDT = moment(e.time).unix()
        if (!(eUnixDT in dataSets)) {
          dataSets[eUnixDT] = { WBC: e }

          return
        }

        nonZeroMin = getNonZeroMin(nonZeroMin, e.amount.value)
        max = getMax(max, e.amount.value)

        dataSets[eUnixDT].WBC = e
      }
    })
  }

  if (plotExcludedObservations) {
    plotExcludedObservations.forEach((e) => {
      if (isObservationTypeLevel(e.observationType)) {
        const eUnixDT = moment(e.time).unix()
        if (!(eUnixDT in dataSets)) {
          dataSets[eUnixDT] = { excluded_observation: e }

          return
        }

        nonZeroMin = getNonZeroMin(nonZeroMin, e.amount.value)
        max = getMax(max, e.amount.value)

        dataSets[eUnixDT].excluded_observation = e
      }
    })
  }

  if (secrTrendData?.secrObservations) {
    secrTrendData?.secrObservations.forEach((e) => {
      if (isObservationTypeSeCr(e.observationType)) {
        const eUnixDT = moment(e.time).unix()
        if (!(eUnixDT in dataSets)) {
          dataSets[eUnixDT] = { secr: e.amount.value }
          secrDataSets[eUnixDT] = { secr: e.amount.value, percentChange: e.percentChange?.value }

          return
        }

        secrDataSets[eUnixDT] = { secr: e.amount.value, percentChange: e.percentChange?.value }
        dataSets[eUnixDT].secr = e.amount.value
      }
    })
  }

  return {
    dataSets,
    secrDataSets,
    dialysisData,
    nonZeroMin,
    max
  }
}

export const convertHistoricalSimulationPlotData = (
  plotPoints: IDosingPlotPoints | null,
  hospitalTimezone: string,
  secrTrendData: ISecrTrendData | null,
  plotObservations: IPlotObservation[],
  plotExcludedObservations: IPlotObservation[],
  historicalAdministrations: IPlotAdministration[] | null
): [IDataSetRow[], ISecrDataSetRow[], IPlotObservation[], number, number] => {
  if (!plotPoints) {
    return [[], [], [], 0, 0]
  }

  const { dataSets, secrDataSets, dialysisData, nonZeroMin, max } = getHistoricalPlotData(
    plotPoints,
    secrTrendData,
    plotObservations,
    plotExcludedObservations,
    historicalAdministrations
  )

  //sort((a:any, b:any) => b - a)
  return [
    Object.entries(dataSets).reduce<Array<IDataSetRow>>((acc, curr) => {
      return acc.concat({
        ...curr[1],
        time: parseInt(curr[0], 10),
        name: timeNameFormatter(parseInt(curr[0], 10), hospitalTimezone)
      })
    }, []),
    Object.entries(secrDataSets).reduce<Array<ISecrDataSetRow>>((acc, curr) => {
      return acc
        .concat({
          ...curr[1],
          time: parseInt(curr[0], 10)
        })
        .sort((a: any, b: any) => a.time - b.time)
    }, []),
    dialysisData,
    nonZeroMin,
    max
  ]
}

export const convertPlotData = (
  historicalPlotPoints: IDosingPlotPoints | null,
  predictedPlotPoints: IDosingPlotPoints | null,
  hospitalTimezone: string,
  secrTrendData: ISecrTrendData | null,
  plotObservations: IPlotObservation[],
  plotExcludedObservations: IPlotObservation[],
  plotFutureDateUnix: number,
  historicalAdministrations: IPlotAdministration[] | null,
  predictedAdministrations: IPredictedPlotAdministrations,
  plotMetadata: IPlotMetaData | null
): [IDataSetRow[], ISecrDataSetRow[], IPlotObservation[], number, number] => {
  if (!historicalPlotPoints && !predictedPlotPoints) {
    return [[], [], [], 0, 0]
  }

  const futureXDatetime = plotMetadata?.plotFutureDate
    ? moment(plotMetadata?.plotFutureDate).unix()
    : plotFutureDateUnix

  let { dataSets, secrDataSets, dialysisData, nonZeroMin, max } = getHistoricalPlotData(
    historicalPlotPoints,
    secrTrendData,
    plotObservations,
    plotExcludedObservations,
    historicalAdministrations,
    futureXDatetime
  )

  // Add plot future date
  if (!(plotFutureDateUnix in dataSets)) dataSets[plotFutureDateUnix] = {}

  if (predictedPlotPoints?.individualized) {
    const futureXDatetime = plotMetadata?.plotFutureDate
      ? moment(plotMetadata?.plotFutureDate).unix()
      : plotFutureDateUnix

    predictedPlotPoints.individualized.forEach((e) => {
      const eUnixDT = moment(e.time).unix()

      if (eUnixDT < futureXDatetime) {
        return
      }

      nonZeroMin = getNonZeroMin(nonZeroMin, e.amount)
      max = getMax(max, e.amount)

      if (!(eUnixDT in dataSets)) {
        dataSets[eUnixDT] = { individualized_predicted: e.amount }

        return
      }

      dataSets[eUnixDT].individualized_predicted = e.amount
    })

    if (predictedAdministrations.individualized) {
      for (let i = 0; i < predictedAdministrations.individualized.length; i++) {
        if (isAdministrationTypeGCSF(predictedAdministrations.individualized[i].administrationType)) {
          const eUnixDT = moment(predictedAdministrations.individualized[i].time).unix()
          if (!(eUnixDT in dataSets)) {
            dataSets[eUnixDT] = { GCSF: 1, GCSFData: predictedAdministrations.individualized[i] }
          } else {
            dataSets[eUnixDT].GCSF = 1
            dataSets[eUnixDT].GCSFData = predictedAdministrations.individualized[i]
          }
        } else {
          const desiredTime = moment(predictedAdministrations.individualized[i].time).unix()
          const doseDataPoint = getDoseDataPoint(desiredTime, predictedPlotPoints.individualized)

          if (doseDataPoint) {
            const dPUnixDT = moment(doseDataPoint.time).unix()

            // Considering dose time off by a second due to Perl datetime maths
            if (dPUnixDT >= futureXDatetime - 1) {
              if (!(dPUnixDT in dataSets)) {
                dataSets[dPUnixDT] = { individualized_predicted_dose: doseDataPoint.amount }
              } else {
                dataSets[dPUnixDT].individualized_predicted_dose = doseDataPoint.amount
              }
            }
          }
        }
      }
    }
  }

  if (predictedPlotPoints?.population) {
    const futureXDatetime = plotMetadata?.plotFutureDate
      ? moment(plotMetadata?.plotFutureDate).unix()
      : plotFutureDateUnix

    predictedPlotPoints.population.forEach((e) => {
      const eUnixDT = moment(e.time).unix()

      if (eUnixDT < futureXDatetime) {
        return
      }

      nonZeroMin = getNonZeroMin(nonZeroMin, e.amount)
      max = getMax(max, e.amount)

      if (!(eUnixDT in dataSets)) {
        dataSets[eUnixDT] = { population_predicted: e.amount }

        return
      }

      dataSets[eUnixDT].population_predicted = e.amount
    })

    if (predictedAdministrations.population) {
      for (let i = 0; i < predictedAdministrations.population.length; i++) {
        if (isAdministrationTypeGCSF(predictedAdministrations.population[i].administrationType)) {
          const eUnixDT = moment(predictedAdministrations.population[i].time).unix()
          if (!(eUnixDT in dataSets)) {
            dataSets[eUnixDT] = { GCSF: 1, GCSFData: predictedAdministrations.population[i] }
          } else {
            dataSets[eUnixDT].GCSF = 1
            dataSets[eUnixDT].GCSFData = predictedAdministrations.population[i]
          }
        } else {
          const desiredTime = moment(predictedAdministrations.population[i].time).unix()
          const doseDataPoint = getDoseDataPoint(desiredTime, predictedPlotPoints.population)

          if (doseDataPoint) {
            const dPUnixDT = moment(doseDataPoint.time).unix()

            // Considering dose time off by a second due to Perl datetime maths
            if (dPUnixDT >= futureXDatetime - 1) {
              if (!(dPUnixDT in dataSets)) {
                dataSets[dPUnixDT] = { population_predicted_dose: doseDataPoint.amount }
              } else {
                dataSets[dPUnixDT].population_predicted_dose = doseDataPoint.amount
              }
            }
          }
        }
      }
    }
  }

  if (predictedPlotPoints?.custom) {
    predictedPlotPoints.custom.forEach((e) => {
      nonZeroMin = getNonZeroMin(nonZeroMin, e.amount)
      max = getMax(max, e.amount)

      const eUnixDT = moment(e.time).unix()
      if (!(eUnixDT in dataSets)) {
        dataSets[eUnixDT] = {
          custom_predicted: e.amount
        }

        return
      }

      dataSets[eUnixDT].custom_predicted = e.amount
    })

    for (let i = 0; i < predictedAdministrations.custom.length; i++) {
      if (isAdministrationTypeGCSF(predictedAdministrations.custom[i].administrationType)) {
        const eUnixDT = moment(predictedAdministrations.custom[i].time).unix()
        if (!(eUnixDT in dataSets)) {
          dataSets[eUnixDT] = { GCSF: 1, GCSFData: predictedAdministrations.custom[i] }
        } else {
          dataSets[eUnixDT].GCSF = 1
          dataSets[eUnixDT].GCSFData = predictedAdministrations.custom[i]
        }
      } else {
        let desiredTime = moment(predictedAdministrations.custom[i].time).unix()
        const doseDataPoint = getDoseDataPoint(desiredTime, predictedPlotPoints.custom)

        if (doseDataPoint) {
          const dPUnixDT = moment(doseDataPoint.time).unix()

          // Considering dose time off by a second due to Perl datetime maths
          if (dPUnixDT >= futureXDatetime - 1) {
            if (!(dPUnixDT in dataSets)) {
              dataSets[dPUnixDT] = { custom_predicted_dose: doseDataPoint.amount }
            } else {
              dataSets[dPUnixDT].custom_predicted_dose = doseDataPoint.amount
            }
          }
        }
      }
    }
  }

  // We only want the 'predicted' guideline data
  if (predictedPlotPoints?.guideline) {
    predictedPlotPoints.guideline.forEach((e) => {
      nonZeroMin = getNonZeroMin(nonZeroMin, e.amount)
      max = getMax(max, e.amount)

      const eUnixDT = moment(e.time).unix()
      if (!(eUnixDT in dataSets)) {
        dataSets[eUnixDT] = {
          guideline_predicted: e.amount
        }

        return
      }

      dataSets[eUnixDT].guideline_predicted = e.amount
    })

    for (let i = 0; i < predictedAdministrations.guideline.length; i++) {
      if (isAdministrationTypeGCSF(predictedAdministrations.guideline[i].administrationType)) {
        const eUnixDT = moment(predictedAdministrations.guideline[i].time).unix()
        if (!(eUnixDT in dataSets)) {
          dataSets[eUnixDT] = { GCSF: 1, GCSFData: predictedAdministrations.guideline[i] }
        } else {
          dataSets[eUnixDT].GCSF = 1
          dataSets[eUnixDT].GCSFData = predictedAdministrations.guideline[i]
        }
      } else {
        const desiredTime = moment(predictedAdministrations.guideline[i].time).unix()
        const doseDataPoint = getDoseDataPoint(desiredTime, predictedPlotPoints.guideline)

        if (doseDataPoint) {
          const dPUnixDT = moment(doseDataPoint.time).unix()

          // Considering dose time off by a second due to Perl datetime maths
          if (dPUnixDT >= futureXDatetime - 1) {
            if (!(dPUnixDT in dataSets)) {
              dataSets[dPUnixDT] = { guideline_predicted_dose: doseDataPoint.amount }
            } else {
              dataSets[dPUnixDT].guideline_predicted_dose = doseDataPoint.amount
            }
          }
        }
      }
    }
  }

  //sort((a:any, b:any) => b - a)
  return [
    Object.entries(dataSets).reduce<Array<IDataSetRow>>((acc, curr) => {
      return acc.concat({
        ...curr[1],
        time: parseInt(curr[0], 10),
        name: timeNameFormatter(parseInt(curr[0], 10), hospitalTimezone)
      })
    }, []),
    Object.entries(secrDataSets).reduce<Array<ISecrDataSetRow>>((acc, curr) => {
      return acc
        .concat({
          ...curr[1],
          time: parseInt(curr[0], 10)
        })
        .sort((a: any, b: any) => a.time - b.time)
    }, []),
    dialysisData,
    nonZeroMin,
    max
  ]
}

export const getSecrPoints = (
  point1: ISecrDataSetRow,
  point2: ISecrDataSetRow,
  leftLimit: number,
  rightLimit: number,
  maxY: number
) => {
  let leftPoint = { x: point1.time, y: point1.secr }
  let rightPoint = { x: point2.time, y: point2.secr }

  if (point1.time < leftLimit || point2.time > rightLimit) {
    const m = (point2.secr - point1.secr) / (point2.time - point1.time)
    const c = point1.secr - m * point1.time

    if (point1.time < leftLimit) {
      const yIntersection = m * leftLimit + c
      const xIntersection = (maxY! - c) / m
      if (xIntersection && xIntersection > leftLimit) {
        //shouldn't be reached unless we make y height variable
        leftPoint = { x: xIntersection, y: maxY }
      }
      leftPoint = { x: leftLimit, y: yIntersection }
    }

    if (point2.time > rightLimit) {
      const yIntersection = m * rightLimit + c
      const xIntersection = (maxY! - c) / m
      if (xIntersection && xIntersection < rightLimit) {
        //shouldn't be reached unless we make y height variable
        rightPoint = { x: xIntersection, y: maxY }
      }
      rightPoint = { x: rightLimit, y: yIntersection }
    }
  }

  return [leftPoint, rightPoint]
}

// Formats X Axis values into a timezone DT specific string
export const xAxisTickFormatter = (
  unixTimestamp: number,
  hospitalTimezone: string
): string => {
  if (isFinite(unixTimestamp)) {
    const tickDate = moment.unix(unixTimestamp).tz(hospitalTimezone)

    return tickDate.format(chartFormat)
  }

  return ''
}

// Formats X Axis values into days since last dose
export const daysSincePreviousDose = (
  unixTimestamp: number,
  historicalAdministrations: IPlotAdministration[],
  predictedAdministrations: IPredictedPlotAdministrations,
  administrationName: string
): number => {
  let previousDoseTime: number | null = null

  // FIXME - if this is used for something other than docetaxel we may need to change the predicted logic
  // docetaxel only simulates custom doses so this is fine for now
  if (predictedAdministrations.custom?.length) {
    previousDoseTime = predictedAdministrations.custom.reduce((prev, current) => {
      if (current.administrationType?.shortName === administrationName) {
        const currentUnixTime = moment(current.time).unix()

        if (currentUnixTime < unixTimestamp && (!prev || currentUnixTime > prev)) {
          return currentUnixTime
        }
      }

      return prev
    }, null as number | null)
  }

  if (!previousDoseTime) {
    previousDoseTime = historicalAdministrations.reduce((prev, current) => {
      if (current.administrationType?.shortName === administrationName) {
        const currentUnixTime = moment(current.time).unix()

        if (currentUnixTime < unixTimestamp && (!prev || currentUnixTime > prev)) {
          return currentUnixTime
        }
      }

      return prev
    }, null as number | null)
  }

  if (!previousDoseTime) {
    return 0
  }

  const dayInSeconds = 86400
  const days = Math.floor((unixTimestamp - previousDoseTime) / dayInSeconds)

  return days
}

export const getFormattedNeutropeniaGrades = (neutropeniaGrades: INeutropeniaGrade[]): IFormattedNeutropeniaGrade[] => {
  const sortedNeutropeniaGrades = neutropeniaGrades.sort((a, b) => { return a.yPos - b.yPos })

  let lastYPos: number | undefined

  return sortedNeutropeniaGrades.map((neutropeniaGrade: INeutropeniaGrade) => {
    const formattedNeutropeniaGrade: IFormattedNeutropeniaGrade = {
      y2: neutropeniaGrade.yPos,
      y1: lastYPos,
      class: neutropeniaGrade.class,
      label: neutropeniaGrade.label
    }
    lastYPos = neutropeniaGrade.yPos

    return formattedNeutropeniaGrade
  })
}
