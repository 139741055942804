import { TVendor } from '../../../../constants/api'

export const dosemePhoneNumber = '(+1) 832-358-3308'
export const dosemeEmail = 'support@dosemehealth.com'

export interface IntercomCustomAttributes {
  instance: string
  vendor: TVendor | string
  patient_id?: string
  ehr_support_link?: string
  company?: {
    company_id: string
    name: string
  }
}
