import { Tooltip } from 'react-tooltip'
import { IStatistic, Icons } from '@doseme/cohesive-ui'

import { IModelDoseRecommendation } from '../../../../../../../../../../store/dosingRecommendation/types'

export const indPopOrGuidelineDoseStats = (
  model: IModelDoseRecommendation | null,
  isGuideline: boolean,
  dosingIntervalTooltipText?: string | null
): IStatistic[] => {
  const doseStats = [
    {
      id: 'dose',
      title: 'Dose',
      subtitle: (model && !model.loadingDose && model.doseRecommendationAttributes?.amount.unit!.name) || '—',
      value: (model && !model.loadingDose && model.doseRecommendationAttributes?.amount.value.toString()) || '—'
    },
    {
      id: 'infusionLength',
      title: 'Infusion',
      subtitle: (model && !model.loadingDose && model.doseRecommendationAttributes?.infusionLength.unit!.name) || '—',
      value: (model && !model.loadingDose && model.doseRecommendationAttributes?.infusionLength.value.toString()) || '—'
    },
    {
      id: 'dosingInterval',
      title: isGuideline ? 'Interval' : dosingIntervalTitleElement('Interval', dosingIntervalTooltipText),
      subtitle: (model && !model.loadingDose && model.doseRecommendationAttributes?.dosingInterval.unit!.name) || '—',
      value: (model && !model.loadingDose && model.doseRecommendationAttributes?.dosingInterval.value.toString()) || '—'
    },
    {
      id: 'doses',
      title: 'Doses',
      subtitle:
        model && !model.loadingDose && model.doseRecommendationAttributes?.numberOfDoses.toString() ? 'No.' : '—',
      value: (model && !model.loadingDose && model.doseRecommendationAttributes?.numberOfDoses.toString()) || '—'
    }
  ]

  if (isGuideline) {
    return doseStats
  }

  return doseStats.concat({
    id: 'dosingDays',
    title: 'Regimen',
    subtitle: (model && !model.loadingDose && model.doseRecommendationAttributes?.daysOfDosing?.unit!.name) || '—',
    value: (model && !model.loadingDose && model.doseRecommendationAttributes?.daysOfDosing?.value.toString()) || '—'
  })
}

const dosingIntervalTitleElement = (title: string, dosingIntervalTooltipText?: string | null) => {
  return (
    <div className='sim-panel-interval-title-and-info'>
      {title}

      {dosingIntervalTooltipText && (
        <div className='sim-panel-interval-info'>
          <Tooltip id='sim-panel-interval-info-tip' place='top'>
            {dosingIntervalTooltipText}
          </Tooltip>
          <div data-tooltip-id='sim-panel-interval-info-tip' className='sim-panel-interval-info-icon'>
            <Icons.Info />
          </div>
        </div>
      )}
    </div>
  )
}
