import { createContext, ReactNode } from 'react'

import { AnalyticsRootStore } from './AnalyticsRootStore'

interface IProviderProps {
  children: ReactNode
  store?: AnalyticsRootStore
}

const AnalyticsRootAppStore: AnalyticsRootStore = new AnalyticsRootStore()

const AnalyticsRootStoreContext = createContext(AnalyticsRootAppStore)
const AnalyticsChartDailyUsageStoreContext = createContext(AnalyticsRootAppStore.analyticsChartDailyUsageStore)
const AnalyticsChartUsageByHourStoreContext = createContext(AnalyticsRootAppStore.analyticsChartUsageByHourStore)
const AnalyticsChartUsageByDayStoreContext = createContext(AnalyticsRootAppStore.analyticsChartUsageByDayStore)
const AnalyticsChartUserLoginNumbersStoreContext = createContext(AnalyticsRootAppStore.analyticsChartUserLoginNumbersStore)
const AnalyticsChartUniqueUsersStoreContext = createContext(AnalyticsRootAppStore.analyticsChartUniqueUsersStore)
const AnalyticsChartTroughOutcomesStoreContext = createContext(AnalyticsRootAppStore.analyticsChartTroughOutcomesStore)
const AnalyticsChartAKIRiskFactorsStoreContext = createContext(AnalyticsRootAppStore.analyticsChartAKIRiskFactorsStore)
const AnalyticsChartDailyDoseAmountsStoreContext = createContext(AnalyticsRootAppStore.analyticsChartDailyDoseAmountsStore)
const AnalyticsChartDrugModelUsageBreakdownStoreContext = createContext(AnalyticsRootAppStore.analyticsChartDrugModelUsageBreakdownStore)
const AnalyticsChartDrugModelUsageOverTimeStoreContext = createContext(AnalyticsRootAppStore.analyticsChartDrugModelUsageOverTimeStore)
const AnalyticsChartDosesSimulatedByUserStoreContext = createContext(AnalyticsRootAppStore.analyticsChartDosesSimulatedByUserStore)
const AnalyticsChartReportsByTypeStoreContext = createContext(AnalyticsRootAppStore.analyticsChartReportsByTypeStore)
const AnalyticsChartReportsByUserStoreContext = createContext(AnalyticsRootAppStore.analyticsChartReportsByUserStore)
const AnalyticsChartCourseTimeToTherapeuticTargetContext = createContext(AnalyticsRootAppStore.analyticsChartCourseTimeToTherapeuticTargetStore)
const AnalyticsChartAucVsTroughOutcomesStoreContext = createContext(AnalyticsRootAppStore.analyticsChartAucVsTroughOutcomesStore)
const AnalyticsChartMedianHoursAndDosesToTargetStoreContext = createContext(AnalyticsRootAppStore.analyticsChartMedianHoursAndDosesToTargetStore)
const AnalyticsChartAucRiskThresholdsStoreContext = createContext(AnalyticsRootAppStore.analyticsChartAucRiskThresholdsStore)
const AnalyticsChartChangeOverTimeToTherapeuticTargetStoreContext = createContext(AnalyticsRootAppStore.analyticsChartChangeOverTimeToTherapeuticTargetStore)
const AnalyticsErrorStoreContext = createContext(AnalyticsRootAppStore.analyticsErrorStore)

const AnalyticsStoreProvider = ({ children, store }: IProviderProps) => {
  const applicationStore = store || AnalyticsRootAppStore

  return (
    <AnalyticsRootStoreContext.Provider value={applicationStore}>
      <AnalyticsErrorStoreContext.Provider value={applicationStore.analyticsErrorStore}>
        <AnalyticsChartChangeOverTimeToTherapeuticTargetStoreContext.Provider value={applicationStore.analyticsChartChangeOverTimeToTherapeuticTargetStore}>
          <AnalyticsChartMedianHoursAndDosesToTargetStoreContext.Provider value={applicationStore.analyticsChartMedianHoursAndDosesToTargetStore}>
            <AnalyticsChartAucRiskThresholdsStoreContext.Provider value={applicationStore.analyticsChartAucRiskThresholdsStore}>
              <AnalyticsChartAucVsTroughOutcomesStoreContext.Provider value={applicationStore.analyticsChartAucVsTroughOutcomesStore} >
                <AnalyticsChartCourseTimeToTherapeuticTargetContext.Provider value={applicationStore.analyticsChartCourseTimeToTherapeuticTargetStore}>
                  <AnalyticsChartUserLoginNumbersStoreContext.Provider value={applicationStore.analyticsChartUserLoginNumbersStore}>
                    <AnalyticsChartDosesSimulatedByUserStoreContext.Provider value={applicationStore.analyticsChartDosesSimulatedByUserStore}>
                      <AnalyticsChartReportsByTypeStoreContext.Provider value={applicationStore.analyticsChartReportsByTypeStore}>
                        <AnalyticsChartReportsByUserStoreContext.Provider value={applicationStore.analyticsChartReportsByUserStore}>
                          <AnalyticsChartDailyDoseAmountsStoreContext.Provider value={applicationStore.analyticsChartDailyDoseAmountsStore}>
                            <AnalyticsChartDrugModelUsageBreakdownStoreContext.Provider value={applicationStore.analyticsChartDrugModelUsageBreakdownStore}>
                              <AnalyticsChartDrugModelUsageOverTimeStoreContext.Provider value={applicationStore.analyticsChartDrugModelUsageOverTimeStore}>
                                <AnalyticsChartUniqueUsersStoreContext.Provider value={applicationStore.analyticsChartUniqueUsersStore}>
                                  <AnalyticsChartTroughOutcomesStoreContext.Provider value={applicationStore.analyticsChartTroughOutcomesStore}>
                                    <AnalyticsChartAKIRiskFactorsStoreContext.Provider value={applicationStore.analyticsChartAKIRiskFactorsStore}>
                                      <AnalyticsChartDailyUsageStoreContext.Provider value={applicationStore.analyticsChartDailyUsageStore}>
                                        <AnalyticsChartUsageByHourStoreContext.Provider value={applicationStore.analyticsChartUsageByHourStore}>
                                          <AnalyticsChartUsageByDayStoreContext.Provider value={applicationStore.analyticsChartUsageByDayStore}>
                                            {children}
                                          </AnalyticsChartUsageByDayStoreContext.Provider>
                                        </AnalyticsChartUsageByHourStoreContext.Provider>
                                      </AnalyticsChartDailyUsageStoreContext.Provider>
                                    </AnalyticsChartAKIRiskFactorsStoreContext.Provider >
                                  </AnalyticsChartTroughOutcomesStoreContext.Provider >
                                </AnalyticsChartUniqueUsersStoreContext.Provider >
                              </AnalyticsChartDrugModelUsageOverTimeStoreContext.Provider >
                            </AnalyticsChartDrugModelUsageBreakdownStoreContext.Provider >
                          </AnalyticsChartDailyDoseAmountsStoreContext.Provider >
                        </AnalyticsChartReportsByUserStoreContext.Provider >
                      </AnalyticsChartReportsByTypeStoreContext.Provider >
                    </AnalyticsChartDosesSimulatedByUserStoreContext.Provider >
                  </AnalyticsChartUserLoginNumbersStoreContext.Provider>
                </AnalyticsChartCourseTimeToTherapeuticTargetContext.Provider>
              </AnalyticsChartAucVsTroughOutcomesStoreContext.Provider>
            </AnalyticsChartAucRiskThresholdsStoreContext.Provider>
          </AnalyticsChartMedianHoursAndDosesToTargetStoreContext.Provider>
        </AnalyticsChartChangeOverTimeToTherapeuticTargetStoreContext.Provider>
      </AnalyticsErrorStoreContext.Provider>
    </AnalyticsRootStoreContext.Provider >
  )
}

export {
  AnalyticsStoreProvider,
  AnalyticsRootStoreContext,
  AnalyticsChartDailyUsageStoreContext,
  AnalyticsChartUsageByHourStoreContext,
  AnalyticsChartUsageByDayStoreContext,
  AnalyticsChartUniqueUsersStoreContext,
  AnalyticsChartTroughOutcomesStoreContext,
  AnalyticsChartAKIRiskFactorsStoreContext,
  AnalyticsChartDailyDoseAmountsStoreContext,
  AnalyticsChartDrugModelUsageBreakdownStoreContext,
  AnalyticsChartDrugModelUsageOverTimeStoreContext,
  AnalyticsChartDosesSimulatedByUserStoreContext,
  AnalyticsChartReportsByTypeStoreContext,
  AnalyticsChartReportsByUserStoreContext,
  AnalyticsChartUserLoginNumbersStoreContext,
  AnalyticsChartCourseTimeToTherapeuticTargetContext,
  AnalyticsChartAucVsTroughOutcomesStoreContext,
  AnalyticsChartAucRiskThresholdsStoreContext,
  AnalyticsChartMedianHoursAndDosesToTargetStoreContext,
  AnalyticsChartChangeOverTimeToTherapeuticTargetStoreContext,
  AnalyticsErrorStoreContext
}
