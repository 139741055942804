import { green100, yellow100, red100 } from '@doseme/cohesive-ui'
import { ReferenceLine, Label, ReferenceArea } from 'recharts'

import { IFormattedNeutropeniaGrade } from '../types'

import './index.scss'

/**
 * Recharts wasn't happy with having react components as children so the neutropenia grades are
 * being handled with two functions that return arrays of recharts elements
 */

export const renderNeutropeniaGradeLines = (neutropeniaGrades: IFormattedNeutropeniaGrade[]) => {
  return neutropeniaGrades.map((neutropeniaGrade: IFormattedNeutropeniaGrade) => {
    let colour
    let labelClassName
    switch (neutropeniaGrade.class) {
      case 'Good':
        colour = '#458833'
        labelClassName = 'historicalPlotReferenceLineLabelGood'
        break
      case 'Warning':
        colour = '#B47A23'
        labelClassName = 'historicalPlotReferenceLineLabelWarning'
        break
      case 'Bad':
        colour = '#983430'
        labelClassName = 'historicalPlotReferenceLineLabelBad'
        break
    }

    return (
      <ReferenceLine key={`line-${neutropeniaGrade.label}`} y={neutropeniaGrade.y2} stroke={colour} strokeWidth={1.5}>
        <Label value={neutropeniaGrade.label} position='insideRight' className={labelClassName} />
      </ReferenceLine>
    )
  })
}

export const renderNeutropeniaGradeAreas = (neutropeniaGrades: IFormattedNeutropeniaGrade[]) => {
  return neutropeniaGrades.reduce((acc: JSX.Element[], neutropeniaGrade: IFormattedNeutropeniaGrade) => {
    let colour
    switch (neutropeniaGrade.class) {
      case 'Good':
        colour = green100
        break
      case 'Warning':
        colour = yellow100
        break
      case 'Bad':
        colour = red100
        break
    }

    return acc.concat(
      <ReferenceArea
        className='neutropenia-grade-areas'
        key={`area-${neutropeniaGrade.label}`}
        y1={neutropeniaGrade.y1}
        y2={neutropeniaGrade.y2}
        fill={colour}
        fillOpacity={0.8}
        ifOverflow='visible'
      />
    )
  }, [])
}
