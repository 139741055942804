import React from 'react'
import {
  Cell,
  Pie, PieChart, Tooltip
} from 'recharts'
import { blueSteel500 } from '@doseme/cohesive-ui'

import { AnalyticsPlot, AnalyticsTooltip } from '../../AnalyicsPlot'
import { IBasePlotProps } from '../../../types'

export const DrugModelUsageBreakdownPlot: React.FC<IBasePlotProps> = (props) => {
  const renderPlotContent = () => {
    return (
      <PieChart width={320} height={216}>
        <Tooltip
          content={(props) => (
            <AnalyticsTooltip
              active={props.active}
              payload={props.payload}
              label={props.label}
              dataName={['drugModelUsageBreakdown']}
            />)}
          cursor={{ stroke: blueSteel500, strokeWidth: 8, opacity: 0.4 }}
        />
        <Pie
          data={props.plotData}
          dataKey='drugModelUsageBreakdown'
          nameKey='name'
          cx='50%'
          cy='50%'
          innerRadius={40}
          outerRadius={100}
        >
          {props.plotData.map((entry, index) => {
            return <Cell key={`cell-${index}`} fill={entry.color} />
          })}
        </Pie>
      </PieChart>
    )
  }

  return (
    <AnalyticsPlot
      plotId='doseme-analytics-drug-model-usage-breakdown-graph'
      plotData={props.plotData}
      plotSeries={props.plotSeries}
      renderPlotContent={renderPlotContent()}
    />
  )
}
