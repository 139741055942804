import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, DOSEME_BLUE, InfoBubble, NAVY, TextInput, WHITE } from '@doseme/cohesive-ui'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Link, useLocation } from 'react-router-dom'

import { buildFormFields } from '../../../../shared/buildForms'
import { useFormValidation } from '../../../../hooks/useFormValidation'
import { TOSFooter } from '../TOSFooter'
import { loginPageEmailFields } from '../LoginPage/constants'
import { useAuthStore } from '../../../../hooks/useStore'
import { showErrorToast, showSuccessToast } from '../../../../shared/toast'

import './index.scss'

export const ForgotPasswordPage: React.FC = () => {
  const [displayEmailPanel, setDisplayEmailPanel] = useState<boolean>(true)

  const authStore = useAuthStore()

  const { search } = useLocation()

  const searchParams = new URLSearchParams(search)

  const emailFormFields = buildFormFields(loginPageEmailFields)

  const emailForm = useFormValidation(emailFormFields)

  useEffect(() => {
    // Needed to prefill and email passed to this page as a searchParam
    const linkEmail = searchParams.get('email')
    if (linkEmail !== null && linkEmail !== '') {
      emailForm.validateFields(
        [
          {
            field: 'email',
            input: linkEmail.replace(' ', '+')
          }
        ],
        'updateFieldsDisplay'
      )
    }
    const emailInputs = document.getElementsByName('email') as NodeListOf<HTMLInputElement>
    if (emailInputs[0]) {
      emailInputs[0].focus()
      emailInputs[0].select()
    }
  }, [])

  const handleEnter = (event: React.FormEvent) => {
    event.preventDefault()
    if (emailForm.valid) {
      sendResetEmail()
    }
  }

  const sendResetEmail = async () => {
    await authStore.authResetPasswordEmail(emailForm.values.email.trim())

    if (['loadError', 'updateError'].includes(authStore.loadState)) {
      showErrorToast(authStore.error || 'Failed to send password reset email')

      return
    }

    if (displayEmailPanel) {
      setDisplayEmailPanel(false)
    }

    showSuccessToast('Password reset email sent')
  }

  const loginLink = () => {
    return '../login?email=' + emailForm.values.email?.trim()
  }

  const EmailPanelContent: JSX.Element = (
    <div className='login-page-panel smaller-width forgot-password-panel'>
      <Link to={loginLink()}>
        <div className='login-page-password-back-arrow'>
          <FontAwesomeIcon color={NAVY} icon={faArrowLeft} /><div className='ml-2'>Back to log in</div>
        </div>
      </Link>
      <div className='login-page-title'>Forgot your password?</div>
      <div className='login-page-subtitle'>Not to worry, we've got you covered!</div>
      <div className='forgot-password-body'>
        Confirm your email address, and we’ll send you a secure link to set a new password.
      </div>
      <div className='forgot-password-email-input-label'>Email:</div>
      <form onSubmit={handleEnter}>
        <TextInput
          type='text'
          fieldState={emailForm.getValidState('email')}
          value={emailForm.inputs['email']}
          name={'email'}
          onChange={(value) => {
            emailForm.validateFields([
              {
                field: 'email',
                input: value
              }
            ])
          }}
        />
      </form>
      <Button
        onClick={() => sendResetEmail()}
        disabled={!emailForm.valid}
        variant='primary-alt'>
        Email me a reset link&nbsp;
        <FontAwesomeIcon color={emailForm.valid ? DOSEME_BLUE : WHITE} icon={faArrowRight} />
      </Button>
    </div>
  )

  const ResendPanelContent: JSX.Element = (
    <div className='login-page-panel smaller-width forgot-password-panel'>
      <div className='login-page-title'>Check your inbox!</div>
      <div className='forgot-password-subtitle-email'>{emailForm.values.email}</div>
      <div className='forgot-password-body'>
        If this email address belongs to an active account, you will receive an email containing a secure link to reset your password.
      </div>
      <div className='forgot-password-body'>
        You may need to check your spam folder
      </div>
      <div className='forgot-password-info-bubble'>
        <InfoBubble bubbleTitle='You will only be able to use the link once, and it will only be valid for a limited amount of time.' />
      </div>
      <div className='forgot-password-buttons'>
        <div className='login-page-password-back-arrow' onClick={() => setDisplayEmailPanel(true)}>
          <FontAwesomeIcon color={NAVY} icon={faArrowLeft} /><div className='ml-2'>Edit email address</div>
        </div>
        <div className='forgot-password-resend-button'>
          <Button
            onClick={() => sendResetEmail()}
            variant='primary-alt'>
            Re-send link&nbsp;
            <FontAwesomeIcon color={DOSEME_BLUE} icon={faArrowRight} />
          </Button>
        </div>
      </div>
    </div>
  )

  return (
    <div>
      {displayEmailPanel ? EmailPanelContent : ResendPanelContent}
      <TOSFooter />
    </div>
  )
}
