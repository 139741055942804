import { makeAutoObservable, observable, ObservableMap } from 'mobx'

import axiosClient, { AxiosError, AxiosResponse } from '../../utils/axiosClient'
import { activityLogsGetUrl, activityLogsPostUrl } from '../../constants/api'
import { RootStore } from '../RootStore'
import { TLoadState } from '../types'
import { ActivityLog } from './ActivityLog'
import { IActivityLog } from './types'

export class ActivityLogStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  error: string = ''

  activityLogs: ObservableMap<string, ActivityLog> = observable.map({}, { deep: false })

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  resetStore() {
    this.activityLogs = observable.map({}, { deep: false })
    this.error = ''

    this.setLoadState('initial')
  }

  // Create-or-update. Does not delete.
  setActivityLogs(logs: IActivityLog[]) {
    logs.forEach((logs) => this.activityLogs.set(logs.id, new ActivityLog(this, logs)))
  }

  async fetchActivityLogs(patientId: string, courseId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IActivityLog[]>>(activityLogsGetUrl(patientId, courseId), { headers })
      .then((response: AxiosResponse) => {
        this.resetStore()
        this.setActivityLogs(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
        this.setActivityLogs([])
      })
  }

  async createActivityLog(patientId: string, courseId: string, note: string): Promise<IActivityLog | null> {
    this.setLoadState('updating')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    return await axiosClient
      .post<AxiosResponse<IActivityLog>>(
        activityLogsPostUrl(patientId, courseId),
        {
          type: 'ActivityLog',
          attributes: {
            type: 'comment',
            note: note
          }
        },
        { headers }
      )
      .then((response: AxiosResponse) => {
        this.setActivityLogs([response.data.data])
        this.setLoadState('loaded')

        return response.data.data
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)

        return null
      })
  }
}
