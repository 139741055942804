import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { Accordion, FloatingTabBar } from '@doseme/cohesive-ui'
import { decode } from 'he'

import { useHospitalStore, useAuthStore } from '../../hooks/useStore'
import { TDosemeAnalyticsTabs } from './types'
import { DailyUsage } from './components/DailyUsage'
import { AucRiskThresholds } from './components/AucRiskThresholds'
import { AucVsTroughOutcomes } from './components/AucVsTroughOutcomes'
import { AKIRiskIndicators } from './components/AKIRiskIndicators'
import { DrugModelUsageBreakdown } from './components/DrugModelUsageBreakdown'
import { DrugModelUsageOverTime } from './components/DrugModelUsageOverTime'
import { UniqueUsers } from './components/UniqueUsers'
import { UserLoginNumbers } from './components/UserLoginNumbers'
import { UsageByDay } from './components/UsageByDay'
import { UsageByHour } from './components/UsageByHour'
import { ReportsByType } from './components/ReportsByType'
import { ReportsByUser } from './components/ReportsByUser'
import { DosesSimulatedByUser } from './components/DosesSimulatedByUser'
import { TroughOutcomes } from './components/TroughOutcomes'
import { DailyDoseAmounts } from './components/DailyDoseAmounts'
import { DoseMeAnalyticsFilters } from './components/DoseMeAnalyticsFilters'
import { CourseTimeToTherapeuticTarget } from './components/CourseTimeToTherapeuticTarget'
import { ChangeOverTimeToTherapeuticTarget } from './components/ChangeOverTimeToTherapeuticTarget'
import { MedianHoursAndDosesToTarget } from './components/MedianHoursAndDosesToTarget'

import './index.scss'

export const DoseMeAnalytics: React.FC = observer(() => {
  const [activeTab, setActiveTab] = useState<TDosemeAnalyticsTabs>('clinicalStatistics')

  const authStore = useAuthStore()
  const hospitalStore = useHospitalStore()

  useEffect(() => {
    if (authStore.auth && hospitalStore.loadState === 'initial') {
      hospitalStore.fetchHospital(authStore.auth.attributes.hospitalId)
    }
  }, [])

  const displayAccordion = () => {
    if (activeTab ==='clinicalStatistics') {
      return (
        <div key='clinicalStatistics'>
          <Accordion
            type='parent'
            openOnInitial={true}
            title='Time to therapeutic target'
            childData={
              <>
                <div className='doseme-analytics-chart-panel row'>
                  <CourseTimeToTherapeuticTarget
                    // These params should be set from reading the Data Filters to be created in IFE-1278
                    // additionally, these prop will eventually be a list of selected hospitals
                    hospitals={hospitalStore.hospital === null ? [] : [hospitalStore.hospital.id]}
                    startDate={new Date('2024-10-01')}
                    endDate={new Date('2024-12-01')}
                  />
                  <MedianHoursAndDosesToTarget
                    // These params should be set from reading the Data Filters to be created in IFE-1278
                    // additionally, these prop will eventually be a list of selected hospitals
                    hospitals={hospitalStore.hospital === null ? [] : [hospitalStore.hospital.id]}
                    startDate={new Date('2024-10-01')}
                    endDate={new Date('2024-12-01')}
                  />
                </div>
                <div className='doseme-analytics-chart-panel'>
                  <ChangeOverTimeToTherapeuticTarget
                    // These params should be set from reading the Data Filters to be created in IFE-1278
                    // additionally, these prop will eventually be a list of selected hospitals
                    hospitals={hospitalStore.hospital === null ? [] : [hospitalStore.hospital.id]}
                    startDate={new Date('2024-10-01')}
                    endDate={new Date('2024-12-01')}
                  />
                </div>
              </>
            }
          />
          <Accordion
            type='parent'
            title='AKI risk indicators'
            childData={
              <>
                <div className='doseme-analytics-chart-panel aki-risk-indicators'>
                  <AKIRiskIndicators
                    // These params should be set from reading the Data Filters to be created in IFE-1278
                    // additionally, these prop will eventually be a list of selected hospitals
                    hospitals={hospitalStore.hospital === null ? [] : [hospitalStore.hospital.id]}
                    startDate={new Date('2024-10-01')}
                    endDate={new Date('2024-12-01')}
                  />
                </div>
              </>
            }
          />
          <Accordion
            type='parent'
            title='Patient risk thresholds'
            childData={
              <>
                <div className='doseme-analytics-chart-panel'>
                  <AucRiskThresholds
                    // These params should be set from reading the Data Filters to be created in IFE-1278
                    // additionally, these prop will eventually be a list of selected hospitals
                    hospitals={hospitalStore.hospital === null ? [] : [hospitalStore.hospital.id]}
                    startDate={new Date('2024-10-01')}
                    endDate={new Date('2024-12-01')}
                  />
                </div>

                <div className='doseme-analytics-chart-panel'>
                  <TroughOutcomes
                    // These params should be set from reading the Data Filters to be created in IFE-1278
                    // additionally, these prop will eventually be a list of selected hospitals
                    hospitals={hospitalStore.hospital === null ? [] : [hospitalStore.hospital.id]}
                    startDate={new Date('2024-10-01')}
                    endDate={new Date('2024-12-01')}
                  />
                </div>
              </>
            }
          />
          <Accordion
            type='parent'
            title='Daily dose amounts'
            childData={
              <>
                <div className='doseme-analytics-chart-panel'>
                  <DailyDoseAmounts
                    // These params should be set from reading the Data Filters to be created in IFE-1278
                    // additionally, these prop will eventually be a list of selected hospitals
                    hospitals={hospitalStore.hospital === null ? [] : [hospitalStore.hospital.id]}
                    startDate={new Date('2024-10-01')}
                    endDate={new Date('2024-12-01')}
                  />
                </div>
              </>
            }
          />
          <Accordion
            type='parent'
            title='AUC vs Trough outcomes'
            childData={
              <>
                <div className='doseme-analytics-chart-panel'>
                  <AucVsTroughOutcomes
                    // These params should be set from reading the Data Filters to be created in IFE-1278
                    // additionally, these prop will eventually be a list of selected hospitals
                    hospitals={hospitalStore.hospital === null ? [] : [hospitalStore.hospital.id]}
                    startDate={new Date('2024-10-01')}
                    endDate={new Date('2024-12-01')}
                  />
                </div>
              </>
            }
          />
        </div>
      )
    }

    if (activeTab ==='drugModelUsage') {
      return (
        <div key='drugModelUsage'>
          <Accordion
            type='parent'
            title='Drug model usage breakdown'
            childData={
              <>
                <div className='doseme-analytics-chart-panel row'>
                  <DrugModelUsageBreakdown
                    // These params should be set from reading the Data Filters to be created in IFE-1278
                    // additionally, these prop will eventually be a list of selected hospitals
                    hospitals={hospitalStore.hospital === null ? [] : [hospitalStore.hospital.id]}
                    startDate={new Date('2024-10-01')}
                    endDate={new Date('2024-12-01')}
                  />
                  <DrugModelUsageOverTime
                    // These params should be set from reading the Data Filters to be created in IFE-1278
                    // additionally, these prop will eventually be a list of selected hospitals
                    hospitals={hospitalStore.hospital === null ? [] : [hospitalStore.hospital.id]}
                    startDate={new Date('2024-10-01')}
                    endDate={new Date('2024-12-01')}
                  />
                </div>
              </>
            }
            openOnInitial={true}
          />
          <Accordion
            type='parent'
            title='Dose simulations and dosing reports'
            childData={
              <>
                <div className='doseme-analytics-chart-panel'>
                  <ReportsByType
                    // These params should be set from reading the Data Filters to be created in IFE-1278
                    // additionally, these prop will eventually be a list of selected hospitals
                    hospitals={hospitalStore.hospital === null ? [] : [hospitalStore.hospital.id]}
                    startDate={new Date('2024-10-01')}
                    endDate={new Date('2024-12-01')}
                  />
                </div>
                <div className='doseme-analytics-chart-panel'>
                  <ReportsByUser
                    // These params should be set from reading the Data Filters to be created in IFE-1278
                    // additionally, these prop will eventually be a list of selected hospitals
                    hospitals={hospitalStore.hospital === null ? [] : [hospitalStore.hospital.id]}
                    startDate={new Date('2024-10-01')}
                    endDate={new Date('2024-12-01')}
                  />
                </div>
                <div className='doseme-analytics-chart-panel'>
                  <DosesSimulatedByUser
                    // These params should be set from reading the Data Filters to be created in IFE-1278
                    // additionally, these prop will eventually be a list of selected hospitals
                    hospitals={hospitalStore.hospital === null ? [] : [hospitalStore.hospital.id]}
                    startDate={new Date('2024-10-01')}
                    endDate={new Date('2024-12-01')}
                  />
                </div>
              </>
            }
          />
        </div>
      )
    }

    return (
      <div key='platformusage'>
        <Accordion
          type='parent'
          title='User logins'
          childData={
            <>
              <div className='doseme-analytics-chart-panel'>
                <UniqueUsers
                  // These params should be set from reading the Data Filters to be created in IFE-1278
                  // additionally, these prop will eventually be a list of selected hospitals
                  hospitals={hospitalStore.hospital === null ? [] : [hospitalStore.hospital.id]}
                  startDate={new Date('2024-10-01')}
                  endDate={new Date('2024-12-01')}
                />
              </div>
              <div className='doseme-analytics-chart-panel'>
                <UserLoginNumbers
                  // These params should be set from reading the Data Filters to be created in IFE-1278
                  // additionally, these prop will eventually be a list of selected hospitals
                  hospitals={hospitalStore.hospital === null ? [] : [hospitalStore.hospital.id]}
                  startDate={new Date('2024-10-01')}
                  endDate={new Date('2024-12-01')}
                />
              </div>
            </>
          }
          openOnInitial={true}
        />
        <Accordion
          type='parent'
          title='Usage trends'
          childData={
            <>
              <div className='doseme-analytics-chart-panel'>
                <DailyUsage
                  // These params should be set from reading the Data Filters to be created in IFE-1278
                  // additionally, these prop will eventually be a list of selected hospitals
                  hospitals={hospitalStore.hospital === null ? [] : [hospitalStore.hospital.id]}
                  startDate={new Date('2024-10-01')}
                  endDate={new Date('2024-12-01')}
                />

                <UsageByDay
                  // These params should be set from reading the Data Filters to be created in IFE-1278
                  // additionally, these prop will eventually be a list of selected hospitals
                  hospitals={hospitalStore.hospital === null ? [] : [hospitalStore.hospital.id]}
                  startDate={new Date('2024-10-01')}
                  endDate={new Date('2024-12-01')}
                />
              </div>

              <div className='doseme-analytics-chart-panel'>
                <UsageByHour
                  // These params should be set from reading the Data Filters to be created in IFE-1278
                  // additionally, these prop will eventually be a list of selected hospitals
                  hospitals={hospitalStore.hospital === null ? [] : [hospitalStore.hospital.id]}
                  startDate={new Date('2024-10-01')}
                  endDate={new Date('2024-12-01')}
                />
              </div>
            </>
          }
        />
      </div>
    )
  }

  return (
    <div className='doseme-analytics-content'>
      <div className='analytics-header ml-2'>
        <span className='analytics-title'>
          DoseMe Analytics for&nbsp;
          <span className='analytics-hospital-name'>{decode(hospitalStore.hospital?.attributes.name || '')}</span>
        </span>

      </div>
      <DoseMeAnalyticsFilters/>
      <div className='doseme-analytics-panel'>
        <FloatingTabBar
          tabNames={[
            { id: 'clinicalStatistics', displayName: 'Clinical statistics' },
            { id: 'drugModelUsage', displayName: 'Drug model usage' },
            { id: 'platformUsage', displayName: 'Platform usage' }
          ]}
          onSelectTab={setActiveTab}
          activeTab={activeTab}
          disabled={hospitalStore.loadState !== 'loaded'}
        />
        <div className='analytics-accordion-outer'>
          {displayAccordion()}
        </div>
      </div>
    </div>
  )
})
