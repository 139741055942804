import { makeAutoObservable } from 'mobx'

import { AnalyticsChartStore } from './AnalyticsChartsStore'
import { IAnalyticsChartResponse } from './types'

export class AnalyticsChart implements IAnalyticsChartResponse {
  store: AnalyticsChartStore

  type: string
  data: IAnalyticsChartResponse['data']
  metadata: IAnalyticsChartResponse['metadata']

  constructor(store: AnalyticsChartStore, entity: IAnalyticsChartResponse) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store
    this.type = entity.data.type
    this.data = { ...entity.data }
    this.metadata = entity.metadata
  }
}
