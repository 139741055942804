import { makeAutoObservable } from 'mobx'

import axiosClient, { AxiosError, AxiosResponse } from '../../utils/axiosClient'
import { courseGetUrl, coursePutUrl } from '../../constants/api'
import { RootStore } from '../RootStore'
import { TLoadState } from '../types'
import { Course } from './Course'
import { ICourse } from './types'

export class CourseStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  error: string = ''

  course: Course | null = null

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  resetStore() {
    this.course = null
    this.error = ''

    this.setLoadState('initial')
  }

  setCourse(CourseAttrs: ICourse) {
    const course = new Course(this, CourseAttrs)
    this.course = course

    return course
  }
  isDocetaxelCourse(): boolean {
    return this.course?.attributes.drugModel.id === '54'
  }

  async fetchCourse(patientId: string, courseId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<ICourse>>(courseGetUrl(patientId, courseId), { headers })
      .then((response: AxiosResponse) => {
        this.setCourse(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  async editCourse(patientId: string, courseId: string, drugModelId: string): Promise<ICourse | null> {
    this.setLoadState('updating')
    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    return await axiosClient
      .put<AxiosResponse<ICourse>>(
        coursePutUrl(patientId, courseId),
        {
          data: {
            id: courseId,
            type: 'Course',
            attributes: {
              drugModel: {
                id: drugModelId
              }
            }
          }
        },

        { headers }
      )
      .then((response: AxiosResponse) => {
        this.setCourse(response.data.data)
        this.setLoadState('loaded')

        return response.data.data
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)

        return null
      })
  }
}
