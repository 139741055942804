import React from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import {
  CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis
} from 'recharts'
import { blueSteel500, Icons, neutrals300, neutrals50, neutrals500, neutrals700 } from '@doseme/cohesive-ui'

import { AnalyticsMultiTooltip, AnalyticsPlot } from '../../AnalyicsPlot'
import { IBasePlotProps, IChartDataSeries } from '../../../types'

export const AKIRiskIndicatorsPlot: React.FC<IBasePlotProps> = (props) => {
  const akiRiskIndicatorsKeyNames: string[] = Object.keys(props.plotSeries)
  const akiRiskDisplayText = {
    kdigo: '(Increase in SeCr ≥ 0.3 mg/dL within 48 hours, or ≥ 50% in 7 days.)',
    akin: '(Increase in SeCr ≥ 0.3 mg/dL or ≥ 50% within 48 hours.)',
    idsa: '(Increase in SeCr ≥ 0.5 mg/dL from baseline, or ≥ 50% from baseline over 2 or more consecutive SeCr levels.)'
  }

  const renderPlotContent = () => {
    const chartWidth = 652
    const legendWidth = 272
    const totalWidth = chartWidth + legendWidth

    return (
      <ResponsiveContainer
        debounce={1}
        width={totalWidth}
        minHeight={284}
        height={'100%'}
      >
        <LineChart
          data={props.plotData}
          barCategoryGap={1}
          width={940}
        >
          <CartesianGrid
            stroke={neutrals300}
            strokeWidth={1}
            fill={neutrals50}
            vertical={false}
          />
          <XAxis
            dataKey='name'
            stroke={neutrals500}
            tick={{ stroke: neutrals700, fill: neutrals700 }}
            tickLine={{ stroke: neutrals500, fill: neutrals500 }}
            interval='equidistantPreserveStart'
            padding={{ left: 12, right: 12 }}
            height={30} // We don't have an X Axis label, so lower the default height down from 60
          />
          <YAxis
            stroke={neutrals500}
            tick={{ stroke: neutrals700, fill: neutrals700 }}
            tickLine={{ stroke: neutrals500, fill: neutrals500 }}
            interval='preserveStartEnd'
            width={30} // We don't have a Y Axis label, so we can reduce the default width down from 60
          />

          <Tooltip
            shared={false}
            content={(props) => (
              <AnalyticsMultiTooltip
                active={props.active}
                payload={props.payload}
                label={props.label}
                subLabel='No. of courses considered AKI risk:'
                dataName={akiRiskIndicatorsKeyNames}
              />
            )}
            cursor={{ stroke: blueSteel500, opacity: 0.4 }}
          />

          <Legend
            layout='vertical'
            align='right'
            verticalAlign='top'
            height={36}
            width={legendWidth}
            content={() => (
              <div className='doseme-analytics-legend-panel aki-risk'>
                {Object.keys(props.plotSeries).map((key) => (
                  <div key={key} className='doseme-analytics-legend-item legend-right'>
                    <div className='d-flex'>
                      <div className='doseme-analytics-legend-item-icon' style={{ backgroundColor: props.plotSeries[key].color }}/>
                      {props.plotSeries[key as keyof IChartDataSeries].label}
                      {['akin', 'kdigo'].includes(key) &&
                        <>
                          <ReactTooltip
                            id={`aki-risk-indicators-tooltip-${key}`}
                            place='top'
                            className='course-variables-tooltip-width'
                          >
                            When multiple SeCr levels fall within the specified time window,<br/>
                            the outermost levels are compared. Otherwise, SeCr change is<br/>
                            normalized to the specified time window duration.
                          </ReactTooltip>
                          <div className='aki-risk-info-icon' data-tooltip-id={`aki-risk-indicators-tooltip-${key}`}><Icons.Info/></div>
                        </>
                      }
                    </div>
                    {akiRiskDisplayText[key as keyof typeof akiRiskDisplayText] || ''}
                  </div>
                ))}
              </div>
            )}
          />

          { props.plotSeries &&
              Object.entries(props.plotSeries).map(([key, value]) => {
                return (
                  <Line
                    key={key}
                    dataKey={key}
                    stroke={value.color}
                    strokeWidth={3}
                    fill={value.color}
                    opacity={0.9}
                    activeDot={{ r: 7 }}
                    width={50}
                  />
                )
              })
          }
        </LineChart>
      </ResponsiveContainer>
    )
  }

  return (
    <AnalyticsPlot
      plotId='doseme-analytics-aki-risk-indicators'
      plotData={props.plotData}
      plotSeries={props.plotSeries}
      renderPlotContent={renderPlotContent()}
    />
  )
}
