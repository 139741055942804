export const dailyDoseAmountsMockData = {
  data: {
    type: '',
    attributes: {
      name: 'Daily Dose Amounts',
      plotData: {
        plotSeries: {
          avgTotal: {
            label: 'Avg. Total Amount per Course',
            type: 'line',
            color: '#00528C'
          },
          avgIndividual: {
            label: 'Avg. Individual Dose Amount',
            type: 'line',
            color: '#60B5A9'
          }
        },
        dataPoints: [
          {
            name: 'October 2023',
            date: '2023-10-01',
            avgTotal: 1630.9,
            avgIndividual: 1239.54,
            tooltip: {
              avgTotal: {
                suffix: 'Avg. Total Amount per Course',
                prefix: '1,630.9 mg'
              },
              avgIndividual: {
                suffix: 'Avg. Individual Dose Amount',
                prefix: '1,239.54 mg'
              }
            }
          },
          {
            name: 'November 2023',
            date: '2023-11-01',
            avgTotal: 1502.3,
            avgIndividual: 1145.76,
            tooltip: {
              avgTotal: {
                suffix: 'Avg. Total Amount per Course',
                prefix: '1,502.3 mg'
              },
              avgIndividual: {
                suffix: 'Avg. Individual Dose Amount',
                prefix: '1,145.76 mg'
              }
            }
          },
          {
            name: 'December 2023',
            date: '2023-12-01',
            avgTotal: 1400.2,
            avgIndividual: 1002.45,
            tooltip: {
              avgTotal: {
                suffix: 'Avg. Total Amount per Course',
                prefix: '1,400.2 mg'
              },
              avgIndividual: {
                suffix: 'Avg. Individual Dose Amount',
                prefix: '1,002.45 mg'
              }
            }
          }
        ]
      }
    }
  },
  metadata: {
    filters: [
      { name: 'hospitals', value: ['1234'] },
      { name: 'startDate', value: '2023-10-01' },
      { name: 'endDate', value: '2023-12-31' }
    ]
  }
}
