export const troughOutcomesMockData = {
  data: {
    type: '',
    attributes: {
      name: 'Trough Risk Thresholds',
      plotData: {
        plotSeries: {
          low: {
            label: '20 mg/L Threshold',
            type: 'line',
            color: '#D8AEC8'
          },
          medium: {
            label: '22 mg/L Threshold',
            type: 'line',
            color: '#A66F88'
          },
          high: {
            label: '24 mg/L Threshold',
            type: 'line',
            color: '#1B3F8B'
          }
        },
        dataPoints: [
          {
            name: 'October 2023',
            date: '2023-10-01',
            low: 9,
            medium: 6,
            high: 4,
            tooltip: {
              low: {
                title: 'October 2023',
                prefix: '20 mg/L Threshold',
                suffix: '(9%)',
                value: '9/100'
              },
              medium: {
                title: 'October 2023',
                prefix: '22 mg/L Threshold',
                suffix: '(6%)',
                value: '6/100'
              },
              high: {
                title: 'October 2023',
                prefix: '24 mg/L Threshold',
                suffix: '(4%)',
                value: '4/100'
              }
            }
          },
          {
            name: 'November 2023',
            date: '2023-11-01',
            low: 3,
            medium: 2,
            high: 2,
            tooltip: {
              low: {
                title: 'November 2023',
                prefix: '20 mg/L Threshold',
                suffix: '(3%)',
                value: '3/100'
              },
              medium: {
                title: 'November 2023',
                prefix: '22 mg/L Threshold',
                suffix: '(2%)',
                value: '2/100'
              },
              high: {
                title: 'November 2023',
                prefix: '24 mg/L Threshold',
                suffix: '(2%)',
                value: '2/100'
              }
            }
          },
          {
            name: 'December 2023',
            date: '2023-12-01',
            low: 7,
            medium: 5,
            high: 4,
            tooltip: {
              low: {
                title: 'December 2023',
                prefix: '20 mg/L Threshold',
                suffix: '(7%)',
                value: '7/100'
              },
              medium: {
                title: 'December 2023',
                prefix: '22 mg/L Threshold',
                suffix: '(5%)',
                value: '5/100'
              },
              high: {
                title: 'December 2023',
                prefix: '24 mg/L Threshold',
                suffix: '(4%)',
                value: '4/100'
              }
            }
          }
        ]
      }
    }
  },
  metadata: {
    filters: [
      { name: 'hospitals', value: ['1234'] },
      { name: 'startDate', value: '2023-10-01' },
      { name: 'endDate', value: '2023-12-31' }
    ]
  }
}
