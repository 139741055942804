import { InfoModal, Modal, Button, Tag, WHITE, blueSky500 } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'

import { useAdminHospitalDetailsStore } from '../../../../../../../hooks/useStore'
import { showErrorToast, showSuccessToast } from '../../../../../../../shared/toast'
import { IPutAdminHospitalSuperAdminInfoFeaturesSettingsWrapper } from '../../../../../../../store/Admin/AdminHospitalDetails/types'
import { IModalProps } from '../../../../../../PatientRouter/types'
import { buildFormFields, buildInputs } from '../../../../../../../shared/buildForms'
import { useFormValidation } from '../../../../../../../hooks/useFormValidation'
import { IInputBooleanSliderField } from '../../../../../../../store/types'

import './index.scss'

interface IProps extends IModalProps {
  hospitalId: string
}

export const HospitalSuperAdminInfoFeaturesSettingsModal: React.FC<IProps> = observer((props) => {
  const adminHospitalDetailsStore = useAdminHospitalDetailsStore()

  useEffect(() => {
    if (props.show) {
      adminHospitalDetailsStore.fetchAdminHospitalSuperAdminInfoFeaturesSettings(props.hospitalId)
    }
  }, [props.hospitalId, props.show])

  const getSuperadminFeatureHash = () => {
    if (adminHospitalDetailsStore.adminHospitalSuperAdminInfoFeaturesSettings) {
      const newSuperadminFeatureHash =
        adminHospitalDetailsStore.adminHospitalSuperAdminInfoFeaturesSettings.attributes.superAdminInfo.features.reduce<
            Record<string, IInputBooleanSliderField>
        >((acc, item) => {
          acc[item.id] = {
            ...item,
            type: 'booleanSlider',
            attributes: {
              ...item.attributes,
              label: '',
              description: (
                <div>
                  <div className='d-flex'>
                    {item.attributes.label}
                    <div className='optional-additional-features-tag'>
                      <Tag color={blueSky500} textColor={WHITE} size='small' text={item.attributes.shortLabel || ''} />
                    </div>
                  </div>
                  <div className='optional-additional-features-subtitle'>{item.attributes.description}</div>
                </div>
              ),
              currentValue: item.attributes.currentValue
            }
          }

          return acc
        }, {})

      return { attributes: newSuperadminFeatureHash }
    }

    return { attributes: {} }
  }

  const formFields = useMemo(() => {
    if (
      adminHospitalDetailsStore.settingsLoadStates.superAdminInfoFeaturesSettings === 'loaded' &&
        adminHospitalDetailsStore.adminHospitalSuperAdminInfoFeaturesSettings
    ) {
      const newSuperadminFeatureHash = getSuperadminFeatureHash()

      return buildFormFields(newSuperadminFeatureHash)
    }

    return {}
  }, [adminHospitalDetailsStore.settingsLoadStates.superAdminInfoFeaturesSettings])

  const form = useFormValidation(formFields)

  useEffect(() => {
    if (adminHospitalDetailsStore.settingsLoadStates.superAdminInfoFeaturesSettings === 'loaded') {
      form.reset()
    }
  }, [adminHospitalDetailsStore.settingsLoadStates.superAdminInfoFeaturesSettings])

  const formContent = (): JSX.Element => {
    if (
      adminHospitalDetailsStore.adminHospitalSuperAdminInfoFeaturesSettings &&
        adminHospitalDetailsStore.settingsLoadStates.superAdminInfoFeaturesSettings === 'loaded'
    ) {
      const newSuperadminFeatureHash = getSuperadminFeatureHash()

      return (
        <div className='position-relative w-100'>
          {buildInputs(
            newSuperadminFeatureHash,
            form,
            Object.keys(newSuperadminFeatureHash.attributes).map((key) => key.toString()),
            formFields
          )}
        </div>
      )
    }

    return <></>
  }

  const handleSubmit = async () => {
    const features = Object.entries(form.values).map(([key, value]) => ({
      id: key,
      value: value ?? false
    }))

    await adminHospitalDetailsStore.putAdminHospitalSuperAdminInfoFeaturesSettings(props.hospitalId, {
      superAdminInfo: { features }
    } as IPutAdminHospitalSuperAdminInfoFeaturesSettingsWrapper)

    if (
      ['loadError', 'updateError'].includes(
        adminHospitalDetailsStore.settingsLoadStates.superAdminInfoFeaturesSettings
      )
    ) {
      showErrorToast(
        adminHospitalDetailsStore.settingsErrors.superAdminInfoFeaturesSettings ||
          'Failed to update Hospital Superadmin Info Features'
      )

      return
    }

    showSuccessToast('Hospital Superadmin Info Features updated')
    props.setShow(false)
  }

  const handleClose = () => {
    props.setShow(false)
    form.reset()
  }

  return (
    <Modal show={props.show} onHide={handleClose}>
      <InfoModal
        size='s'
        loading={['loading', 'updating'].includes(
          adminHospitalDetailsStore.settingsLoadStates.superAdminInfoFeaturesSettings
        )}
        linkComponent={
          <>
            <Button
              className='mr-3'
              data-testid='cancel-btn'
              disabled={false}
              loading={['loading', 'updating'].includes(
                adminHospitalDetailsStore.settingsLoadStates.superAdminInfoFeaturesSettings
              )}
              onClick={handleClose}
              variant='primary-outline'
            >
              Cancel
            </Button>
            <Button
              data-testid='save-btn'
              disabled={
                !form.valid ||
                ['loading', 'updating'].includes(
                  adminHospitalDetailsStore.settingsLoadStates.superAdminInfoFeaturesSettings
                )
              }
              loading={['loading', 'updating'].includes(
                adminHospitalDetailsStore.settingsLoadStates.superAdminInfoFeaturesSettings
              )}
              onClick={handleSubmit}
              variant='primary'
            >
              Save
            </Button>
          </>
        }
        title='Optional additional features'
        message={formContent()}
      />
    </Modal>
  )
})
