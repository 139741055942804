import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useScreenSize from 'use-screen-size'
import classnames from 'classnames'
import {
  Col,
  Container,
  Row,
  ControlButton,
  Modal,
  InfoModal,
  SearchInput,
  Toggle,
  MultiLevelMenu,
  Badge,
  IMenuItem
} from '@doseme/cohesive-ui'
import { decode } from 'he'
import { Trans, useTranslation } from 'react-i18next'

import { useHospitalStore, usePatientListStore, usePatientStore } from '../../../../hooks/useStore'
import { PatientList } from './components/PatientList'
import { PatientPreviewPanel } from './components/PatientPreviewPanel'
import { activeArchivedOptions } from './constants'
import { buildDrugQueryParams, buildFilterItems } from './utils'
import { IFilterResourceListItem } from '../../../../store/types'

import './index.scss'

const HospitalPatientListPage: React.FC = observer((props) => {
  const navigate = useNavigate()
  const size = useScreenSize()

  const [selectedPatientId, setSelectedPatientId] = useState<string | undefined>('')
  const [showModal, setShowModal] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>('')
  const [activeArchived, setActiveArchived] = useState<string>('active')
  const [filterItems, setFilterItems] = useState<IMenuItem[]>([])
  const [drugFilter, setDrugFilter] = useState<Record<string, Record<string, string>>>({})
  const [clinicianFilter, setClinicianFilter] = useState<Record<string, string>>({})
  const [wardFilter, setWardFilter] = useState<Record<string, string>>({})

  const hospitalStore = useHospitalStore()
  const patientListStore = usePatientListStore()
  const patientStore = usePatientStore()

  const { t } = useTranslation()

  useEffect(() => {
    if (hospitalStore.loadState === 'loaded' && hospitalStore.hospital?.id) {
      if (patientListStore.filterLoadState === 'initial') {
        patientListStore.fetchPatientListFilters(hospitalStore.hospital.id)
      }

      if (patientListStore.loadState === 'initial') {
        patientListStore.fetchPatients(hospitalStore.hospital.id)
      }
    }
  }, [hospitalStore.loadState])

  useEffect(() => {
    //Clear any selected patient on reaching the patient list page
    patientStore.resetPatient()
  }, [])

  useEffect(() => {
    if (hospitalStore.loadState === 'loaded' && hospitalStore.hospital?.id) {
      const drugQueryParams = buildDrugQueryParams(drugFilter)
      patientListStore.fetchPatients(hospitalStore.hospital.id, drugQueryParams, Object.keys(wardFilter), Object.keys(clinicianFilter))
    }
  }, [hospitalStore.loadState, drugFilter, wardFilter, clinicianFilter])

  useEffect(() => {
    if (hospitalStore.loadState === 'loaded' && hospitalStore.hospital?.id) {
      patientListStore.fetchPatientListFilters(hospitalStore.hospital.id)
    }
  }, [hospitalStore.loadState])

  useEffect(() => {
    if (patientListStore.filterLoadState === 'loaded') {
      const patientFilters = buildFilterItems(
        [...patientListStore.patientFilters.values()],
        updateFilter,
        drugFilter,
        wardFilter,
        clinicianFilter
      )
      setFilterItems(patientFilters)
    }
  }, [patientListStore.filterLoadState, clinicianFilter, wardFilter, drugFilter])

  const getFilterCount = () => {
    return filterItems.reduce<number>((acc, curr) => {
      return acc + (curr.selectedCount || 0)
    }, 0)
  }

  const updateFilter = (
    type: string,
    selected: boolean,
    item: IFilterResourceListItem,
    allItems?: IFilterResourceListItem[]
  ) => {
    if (type === 'clinician') {
      const newClinicianFilter = { ...clinicianFilter }

      //Add
      if (selected) {
        newClinicianFilter[item.id] = item.name
        setClinicianFilter(newClinicianFilter)

        return
      }

      //Remove
      delete newClinicianFilter[item.id]
      setClinicianFilter(newClinicianFilter)

      return
    }

    if (type === 'ward') {
      const newWardFilter = { ...wardFilter }

      //Add
      if (selected) {
        newWardFilter[item.id] = item.name
        setWardFilter(newWardFilter)

        return
      }

      //Remove
      delete newWardFilter[item.id]
      setWardFilter(newWardFilter)

      return
    }

    //Drug filter
    const newDrugFilter = { ...drugFilter }
    const specificDrugFilter = newDrugFilter[type] || {}

    //Add
    if (selected) {
      specificDrugFilter[item.id] = item.name
      allItems?.forEach((item) => {
        specificDrugFilter[item.id] = item.name
      })
      newDrugFilter[type] = specificDrugFilter
      setDrugFilter(newDrugFilter)

      return
    }

    //Remove
    if (item.id === 'ALL') {
      delete newDrugFilter[type]
      setDrugFilter(newDrugFilter)

      return
    }
    delete specificDrugFilter[item.id]
    setDrugFilter(newDrugFilter)
  }

  const buildFilterBadges = (): JSX.Element[] => {
    let badges: JSX.Element[] = []

    const wardBadges = Object.keys(wardFilter).map((id: string, key: any) => {
      const name = wardFilter[id]

      return (
        <Badge
          key={id}
          id={id}
          title={decode(name)}
          onDismiss={(id: string) => {
            updateFilter('ward', false, { id: id, name: name })
          }}
        />
      )
    })
    badges = badges.concat(wardBadges)

    const clinicianBadges = Object.keys(clinicianFilter).map((id: string, key: any) => {
      const name = clinicianFilter[id]

      return (
        <Badge
          key={id}
          id={id}
          title={decode(name)}
          onDismiss={(id: string) => {
            updateFilter('clinician', false, { id: id, name: name })
          }}
        />
      )
    })
    badges = badges.concat(clinicianBadges)

    const drugBadges = Object.keys(drugFilter).reduce((acc: JSX.Element[], curr) => {
      if (drugFilter[curr] && drugFilter[curr]['ALL']) {
        const name = drugFilter[curr]['ALL']

        acc = acc.concat(
          <Badge
            key={decode(name)}
            id={'ALL'}
            title={decode(name)}
            onDismiss={(id: string) => {
              updateFilter(curr, false, { id: 'ALL', name: name })
            }}
          />
        )
      } else if (drugFilter[curr]) {
        acc = acc.concat(Object.keys(drugFilter[curr]).map((id: string, key: any) => {
          const name = drugFilter[curr][id]

          return (
            <Badge
              key={id}
              id={id}
              title={decode(name)}
              onDismiss={(id: string) => {
                updateFilter(curr, false, { id: id, name: name })
              }}
            />
          )
        }))
      }

      return acc
    }, [])
    badges = badges.concat(drugBadges)

    return badges
  }

  const handleAddPatientButton = () => {
    navigate('/patients/new')
  }

  const hospitalPatientListTitle = () => {
    return (
      <>
        <div className='patient-list-header'>
          <Trans i18nKey='patients.patientsAtHospital' tOptions={{ hospital: decode(hospitalStore.hospital?.attributes.name || '') }} >
            <span className='patient-list-title'>
              Patients at&nbsp;
              <span className='patient-list-hospital-name'>{decode(hospitalStore.hospital?.attributes.name || '')}</span>
            </span>
          </Trans>
          <div>
            <ControlButton icon={faPlus} onClick={handleAddPatientButton}>
              {t('patients.addPatient')}
            </ControlButton>
          </div>
          <div className='patient-list-archived-filter'>
            <Toggle
              name='archivedActive'
              selected={activeArchived}
              options={activeArchivedOptions}
              onChange={(option) => setActiveArchived(option.id)}
            />
          </div>
        </div>
        <div className='patient-list-search'>
          <SearchInput
            value={searchText}
            onChange={setSearchText}
            width={100000}
            borderRadius={12}
            placeholder='Search patients by name or ID'
          />
        </div>
        <div className='patient-list-filter'>
          <MultiLevelMenu
            title='Filters'
            menuItems={filterItems}
            selectedCount={getFilterCount()}
          />
          {buildFilterBadges()}
        </div>
      </>
    )
  }

  const isTabletSize = size.width <= 1080

  if (!isTabletSize) {
    return (
      <div>
        <div
          className={classnames('hospital-patient-list-content d-block', {
            'wide': size.width > 1240,
            'medium': size.width <= 1240,
            'hospital-patient-list-content-archived': activeArchived === 'archived'
          })}
        >
          {hospitalPatientListTitle()}
          <Container fluid={true}>
            <Row>
              <Col key='list-col' width={6}>
                <PatientList
                  hospitalId={hospitalStore.hospital?.id}
                  searchText={searchText}
                  activeArchived={activeArchived}
                  selectedPatientId={selectedPatientId}
                  setSelectedPatientId={setSelectedPatientId}
                  setActiveArchived={setActiveArchived}
                  isTabletSize={isTabletSize}
                />
              </Col>
              <Col data-testid='patient-preview-panel' key='preview-col' width={6}>
                <PatientPreviewPanel
                  isArchived={activeArchived === 'archived'}
                  selectedPatientId={selectedPatientId === undefined ? '' : selectedPatientId}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    )
  }

  return (
    <div>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false)
          setSelectedPatientId(undefined)
        }}
        messageOnly={true}
      >
        <InfoModal
          size='m'
          title=''
          message={
            <div data-testid='patient-preview-panel' className='w-100'>
              <PatientPreviewPanel
                isArchived={activeArchived === 'archived'}
                selectedPatientId={selectedPatientId === undefined ? '' : selectedPatientId}
              />
            </div>
          }
          onDismiss={() => {
            setShowModal(false)
            setSelectedPatientId(undefined)
          }}
          messageOnly={true}
        />
      </Modal>

      <div className={classnames(
        'hospital-patient-list-content d-block',
        { 'hospital-patient-list-content-archived': activeArchived === 'archived' })}
      >
        {hospitalPatientListTitle()}
        <Container fluid={true}>
          <Row>
            <Col>
              <PatientList
                hospitalId={hospitalStore.hospital?.id}
                searchText={searchText}
                activeArchived={activeArchived}
                selectedPatientId={selectedPatientId}
                setSelectedPatientId={setSelectedPatientId}
                setActiveArchived={setActiveArchived}
                setShowPatientPreviewModal={setShowModal}
                isTabletSize={isTabletSize}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
})

export { HospitalPatientListPage }
