import { parseISO, format } from 'date-fns'
import moment from 'moment'

import { dateFnsRawDateOnly } from '../../../../../../../../constants/timeFormat'
import { AdministrationsStore } from '../../../../../../../../store/administrations/AdministrationsStore'
import { IAdministration } from '../../../../../../../../store/administrations/types'
import { ICourse } from '../../../../../../../../store/course/types'
import { ObservationsStore } from '../../../../../../../../store/observations/ObservationsStore'
import { IObservation } from '../../../../../../../../store/observations/types'
import { IFormState } from '../../../../../../../../types/validation'
import { sortAdministrationsByDateAsc } from '../../../../../../../../utils/sorting'
import { calculateDefaultNextDoseTime } from '../SimulationPanel/components/NextDoseAtDateTime/utils/dateProps'
import { getLastAdministration } from '../SimulationPanel/utils'

export const getInitialValues = (
  form: IFormState | undefined,
  course: ICourse,
  hospitalTimezone: string,
  administrationsStore: AdministrationsStore,
  observationsStore: ObservationsStore,
  assumedDosingInterval: number | null,
  latestIncludedAdministration?: IAdministration,
  lastIncludedSeCrObservation?: IObservation
): Record<string, any> => {
  const previousValues = Object.assign({}, form?.values)

  const peviousDateString = (previousValues.dateAdministered && previousValues.timeAdministered)
    ? previousValues.dateAdministered + 'T' + previousValues.timeAdministered
    : null

  const nextDoseAt = getNextDoseDate(
    peviousDateString,
    course,
    hospitalTimezone,
    administrationsStore,
    observationsStore,
    assumedDosingInterval,
    latestIncludedAdministration,
    lastIncludedSeCrObservation
  )

  previousValues.dateAdministered = nextDoseAt ? nextDoseAt[0] : null
  previousValues.timeAdministered = nextDoseAt ? nextDoseAt[1] : null

  return previousValues
}

export const getNextDoseDate = (
  peviousDateString: string | null,
  course: ICourse,
  hospitalTimezone: string,
  administrationsStore: AdministrationsStore,
  observationsStore: ObservationsStore,
  assumedDosingInterval: number | null,
  latestIncludedAdministration?: IAdministration,
  lastIncludedSeCrObservation?: IObservation
): (string | Date)[] | undefined => {
  if ([administrationsStore.loadState, observationsStore.loadState].includes('loaded')) {
    const dosingInterval = assumedDosingInterval ||
      course!.attributes.limits.dosingPeriod.default.value

    const defaultNextDoseTime = calculateDefaultNextDoseTime(
      hospitalTimezone,
      dosingInterval,
      peviousDateString ?? latestIncludedAdministration?.attributes.administeredAt.value,
      lastIncludedSeCrObservation?.attributes.observedAt.value
    )

    return [
      parseISO(format(defaultNextDoseTime, dateFnsRawDateOnly)),
      defaultNextDoseTime.getHours().toString().padStart(2, '0') +
      ':' +
      defaultNextDoseTime.getMinutes().toString().padStart(2, '0')
    ]
  }
}

export const getSortedFormKeys = (
  forms: { [id: number]: IFormState | undefined },
  type: 'dose' | 'observation',
  latestForm?: string
) => {
  return Object.keys(forms).sort((a: string, b: string) => {
    const formA = forms[parseInt(a)]
    const formB = forms[parseInt(b)]

    const formADate =
      type === 'dose'
        ? parseISO(formA?.values.dateAdministered + 'T' + formA?.values.timeAdministered).getTime() || 0
        : parseISO(formA?.values.dateObserved + 'T' + formA?.values.timeObserved).getTime() || 0
    const formBDate =
      type === 'dose'
        ? parseISO(formB?.values.dateAdministered + 'T' + formB?.values.timeAdministered).getTime() || 0
        : parseISO(formB?.values.dateObserved + 'T' + formB?.values.timeObserved).getTime() || 0

    if (a === latestForm && formADate === 0) return 1
    if (b === latestForm && formBDate === 0) return -1

    return formADate - formBDate
  })
}

export const getLatestValidForm = (
  keys: string[],
  forms: { [id: number]: IFormState | undefined }
): IFormState | undefined => {
  for (let i = keys.length - 1; i >= 0; i--) {
    const form = forms[parseInt(keys[i])]

    if (form?.values.dateAdministered && form?.values.timeAdministered &&
      !isNaN(parseISO(form?.values.dateAdministered + 'T' + form?.values.timeAdministered).getTime())
    ) {
      return form
    }
  }
}

export const getAssumedDosingInterval = (
  sortedKeys: string[],
  forms: { [id: number]: IFormState | undefined },
  administrationType?: string,
  lastRecordedAdministration?: IAdministration | null,
  defaultAssumedDosingInterval?: number | null
) => {
  const filteredKeys = getGetFormKeysOfAdministrationType(sortedKeys, forms, administrationType)
  const lastDoseFormValues = forms[parseInt(filteredKeys[filteredKeys.length - 1])]?.values

  if (lastDoseFormValues) {
    const timeOfLastNewDose = moment(lastDoseFormValues['dateAdministered'] + 'T' + lastDoseFormValues['timeAdministered'])

    // If this is the second new dose, derive the dosing interval from the time difference between the last included admin and the new one
    if (filteredKeys.length === 1 && lastRecordedAdministration) {
      const timeOfLastIncludedAdmin = moment(lastRecordedAdministration.attributes.administeredAt.value)

      return timeOfLastNewDose.diff(timeOfLastIncludedAdmin, 'hours')
    }

    // If there are already 2 or more new doses to add, derive the dosing interval from the time difference between the last 2
    if (filteredKeys.length >= 2) {
      const secondLastDoseFormValues = forms[parseInt(filteredKeys[filteredKeys.length - 2])]?.values

      if (secondLastDoseFormValues) {
        const timeOfSecondLastNewDose = moment(secondLastDoseFormValues['dateAdministered'] + 'T' + secondLastDoseFormValues['timeAdministered'])

        return timeOfLastNewDose.diff(timeOfSecondLastNewDose, 'hours')
      }
    }
  }

  return defaultAssumedDosingInterval || null
}

export const getGetFormKeysOfAdministrationType = (
  sortedKeys: string[],
  forms: { [id: number]: IFormState | undefined },
  administrationType?: string
) => {
  return sortedKeys.filter((key: string) => {
    return forms[parseInt(key)]?.values['administrationType']?.id === administrationType
  })
}

export const getLatestAdministrationOfType = (
  administrations: IAdministration[],
  administrationType?: string
): IAdministration | undefined => {
  if (administrationType) {
    const administrationsOfType = administrations.filter(
      (x) => !x.attributes.excludeFromCalculations
        && x.attributes.administrationType.id === administrationType
    )

    return getLastAdministration(sortAdministrationsByDateAsc(administrationsOfType))
  }
}
