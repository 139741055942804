import { makeAutoObservable } from 'mobx'

import axiosClient, { AxiosError, AxiosResponse } from '../../utils/axiosClient'
import { analyticsGetUrl } from '../../constants/api'
import { RootStore } from '../RootStore'
import { TLoadState } from '../types'
import { Analytics } from './Analytics'
import { IAnalytics } from './types'

export class AnalyticsStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  error: string = ''

  analytics: Analytics | null = null

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  resetStore() {
    this.analytics = null
    this.error = ''

    this.setLoadState('initial')
  }

  setAnalytics(analyticsAttrs: IAnalytics) {
    const analytics = new Analytics(this, analyticsAttrs)
    this.analytics = analytics

    return analytics
  }

  async fetchAnalyticsDetails(hospitalId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IAnalytics>>(analyticsGetUrl(hospitalId), { headers })
      .then((response: AxiosResponse) => {
        this.setAnalytics(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }
}
