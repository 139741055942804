import { makeAutoObservable } from 'mobx'

import axiosClient, { AxiosError, AxiosResponse } from '../../../utils/axiosClient'
import { RootStore } from '../../RootStore'
import { TLoadState } from '../../types'
import { AdminVendorDetails } from './AdminVendorDetails'
import { IAdminAddVendorDetails, IAdminVendorDetails, IPutAdminVendorDetails } from './types'
import { adminVendorAddGetUrl, adminVendorDetailsGetPutUrl } from '../../../constants/api'
import { IAdminVendorListItem } from '../AdminVendorList/types'
import { AdminAddVendorDetails } from './AdminAddVendorDetails'

export class AdminVendorDetailsStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  error: string = ''

  settingsLoadStates: Record<string, TLoadState> = {
    addVendorDetails: 'initial'
  }
  settingsErrors: Record<string, string> = {
    addVendorDetails: ''
  }

  adminVendorDetails: AdminVendorDetails | null = null
  adminAddVendorDetails: AdminAddVendorDetails | null = null

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  resetStore() {
    this.adminVendorDetails = null
    this.adminAddVendorDetails = null

    this.settingsLoadStates = {
      addVendorDetails: 'initial'
    }
    this.settingsErrors = {
      addVendorDetails: ''
    }
    this.error =''

    this.setLoadState('initial')
  }

  setAdminVendorDetails(adminVendorDetails: IAdminVendorDetails) {
    this.resetAdminVendorDetails()
    this.adminVendorDetails = new AdminVendorDetails(this, adminVendorDetails)
  }

  resetAdminVendorDetails() {
    this.adminVendorDetails = null
  }

  setLoadStateSettings(loadState: TLoadState, setting: string) {
    this.settingsLoadStates[setting] = loadState
  }

  setErrorSettings(errorState: 'loadError' | 'updateError', error: string, setting: string) {
    this.settingsErrors[setting] = error
    this.setLoadStateSettings(errorState, setting)
  }

  async fetchAdminVendorDetails(vendorId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IAdminVendorDetails>>(adminVendorDetailsGetPutUrl(vendorId), {
        headers
      })
      .then((response: AxiosResponse) => {
        this.setAdminVendorDetails(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  setAddAdminVendorDetails(adminAddVendorDetails: IAdminAddVendorDetails) {
    this.resetAddAdminVendorDetails()
    this.adminAddVendorDetails = new AdminAddVendorDetails(this, adminAddVendorDetails)
  }

  resetAddAdminVendorDetails() {
    this.adminVendorDetails = null
  }

  async fetchAdminAddVendorDetails() {
    this.setLoadStateSettings('loading', 'addVendorDetails')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IAdminVendorDetails>>(adminVendorAddGetUrl(), {
        headers
      })
      .then((response: AxiosResponse) => {
        this.setAddAdminVendorDetails(response.data.data)
        this.setLoadStateSettings('loaded', 'addVendorDetails')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setErrorSettings('loadError', loggableError, 'addVendorDetails')
      })
  }

  async putAdminVendorDetails(vendorId: string, details: IPutAdminVendorDetails) {
    const vendorListStore = this.rootStore.adminVendorListStore

    this.setLoadState('loading')
    vendorListStore.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .put<AxiosResponse<IAdminVendorListItem>>(
        adminVendorDetailsGetPutUrl(vendorId),
        {
          data: {
            id: vendorId,
            type: 'adminVendorNewWrite', //FIXME - update this - current type string in stoplight doesn't make sense
            attributes: details
          }
        },
        { headers }
      )
      .then((response: AxiosResponse) => {
        vendorListStore.adminVendors.set(vendorId, response.data.data)
        this.setLoadState('loaded')
        vendorListStore.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)
        vendorListStore.setError('updateError', loggableError)
      })
  }
}
