import { makeAutoObservable } from 'mobx'

import axiosClient, { AxiosError, AxiosResponse } from '../../../utils/axiosClient'
import { RootStore } from '../../RootStore'
import { TLoadState } from '../../types'
import { adminClinicianSecurityGetUrl, adminClinicianSecurityPasswordPutUrl, adminClinicianSecurityResetDeleteUrl } from '../../../constants/api'
import { AdminClinicianSecurity } from './AdminClinicianSecurity'
import { IAdminClinicianSecurity } from './types'

export class AdminClinicianSecurityStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  error: string = ''

  adminClinicianSecurity: IAdminClinicianSecurity | null = null

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  resetStore() {
    this.adminClinicianSecurity = null
    this.error = ''

    this.setLoadState('initial')
  }

  setAdminClinicianSecurity(adminClinicianSecurity: IAdminClinicianSecurity) {
    this.adminClinicianSecurity = new AdminClinicianSecurity(this, adminClinicianSecurity)
  }

  async fetchAdminClinicianSecurityDetails(clinicianId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IAdminClinicianSecurity>>(adminClinicianSecurityGetUrl(clinicianId), { headers })
      .then((response: AxiosResponse) => {
        this.setAdminClinicianSecurity(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  async resetAdminClinicianSecurityDetails(clinicianId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .delete<AxiosResponse<IAdminClinicianSecurity>>(adminClinicianSecurityResetDeleteUrl(clinicianId), { headers })
      .then(() => {
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  async putAdminClinicianPassword(clinicianId: string, password: string) {
    this.setLoadState('updating')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    const data = {
      id: clinicianId,
      type: 'adminClinicianSecurityPasswordWrite',
      attributes: {
        newPassword: password
      }
    }

    await axiosClient
      .put<AxiosResponse>(adminClinicianSecurityPasswordPutUrl(clinicianId), { data }, { headers })
      .then(() => {
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)
      })
  }
}
