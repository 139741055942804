import { AnalyticsPlotSkeleton, MessageOverlay } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCog } from '@fortawesome/free-solid-svg-icons'
// import { ListButton } from '@doseme/cohesive-ui'
import { useAnalyticsChartDailyDoseAmountsStore } from '../../../../hooks/useStore'
import { IBaseChartProps } from '../../types'
import { DailyDoseAmountsPlot } from './components'
import { formatErrorMessage } from '../../../PatientRouter/components/CourseProfile/components/DoseRecommendation/utils'

export const DailyDoseAmounts: React.FC<IBaseChartProps> = observer((props) => {
  const analyticsChartDailyDoseAmountsStore = useAnalyticsChartDailyDoseAmountsStore()

  useEffect(() => {
    analyticsChartDailyDoseAmountsStore.fetchAnalyticsChart()
  }, [props.hospitals, props.startDate, props.endDate])

  const plotContent = () => {
    if (
      analyticsChartDailyDoseAmountsStore.analyticsChartData
      && analyticsChartDailyDoseAmountsStore.loadState === 'loaded'
    ) {
      return (
        <div className='doseme-analytics-chart-plot'>
          <DailyDoseAmountsPlot
            plotData={analyticsChartDailyDoseAmountsStore.analyticsChartData.attributes.plotData.dataPoints}
            plotSeries={analyticsChartDailyDoseAmountsStore.analyticsChartData.attributes.plotData.plotSeries}
            plotName={analyticsChartDailyDoseAmountsStore.analyticsChartData.attributes.name}
          />
        </div>
      )
    }

    if (['loadError, updateError'].includes(analyticsChartDailyDoseAmountsStore.loadState)) {
      return (
        <MessageOverlay
          type='error'
          headerText='Failed to load analytics chart'
          messageText={
            <div className='panel-error-overlay-text'>
              {formatErrorMessage(analyticsChartDailyDoseAmountsStore.error) || 'Something went wrong'}
            </div>
          }
          showBlur={false}
        >
          <AnalyticsPlotSkeleton
            loading={false}
            style={{ width: '924px', height: '284px' }}
          />
        </MessageOverlay>
      )
    }

    return <AnalyticsPlotSkeleton loading={analyticsChartDailyDoseAmountsStore.loadState === 'loading'} style={{ width: '924px', height: '284px' }}/>
  }

  // Normally we would be issuing a GET request via our axiosClient to the appropriate chart endpoint
  // For now we have some mockData to pass on to the Plot component
  return (
    <div className='doseme-analytics-chart' key='daily-dose-amounts'>
      <div className='header-row'>
        <div className='title md-8'>Avg. Daily Dose Amounts</div>
        <div className='view-options md-4'>
          {/*<ListButton disabled={true}>*/}
          {/*  <FontAwesomeIcon icon={faCog} />&nbsp;View Options*/}
          {/*</ListButton>*/}
        </div>
      </div>
      {plotContent()}
    </div>
  )
})
