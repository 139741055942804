import { AnalyticsPlotSkeleton, MessageOverlay } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCog } from '@fortawesome/free-solid-svg-icons'
// import { ListButton } from '@doseme/cohesive-ui'
import { useAnalyticsChartCourseTimeToTherapeuticTargetStore } from '../../../../hooks/useStore'
import { IBaseChartProps } from '../../types'
import { CourseTimeToTherapeuticTargetPlot } from './components'
import { formatErrorMessage } from '../../../PatientRouter/components/CourseProfile/components/DoseRecommendation/utils'

export const CourseTimeToTherapeuticTarget: React.FC<IBaseChartProps> = observer((props) => {
  const courseTimeToTherapeuticTargetChartStore = useAnalyticsChartCourseTimeToTherapeuticTargetStore()

  useEffect(() => {
    courseTimeToTherapeuticTargetChartStore.fetchAnalyticsChart()
  }, [props.hospitals, props.startDate, props.endDate])

  const plotContent = () => {
    if (
      courseTimeToTherapeuticTargetChartStore.analyticsChartData
      && courseTimeToTherapeuticTargetChartStore.loadState === 'loaded'
    ) {
      return (
        <div className='doseme-analytics-chart-plot'>
          <CourseTimeToTherapeuticTargetPlot
            plotData={courseTimeToTherapeuticTargetChartStore.analyticsChartData.attributes.plotData.dataPoints}
            plotSeries={courseTimeToTherapeuticTargetChartStore.analyticsChartData.attributes.plotData.plotSeries}
            plotName={courseTimeToTherapeuticTargetChartStore.analyticsChartData.attributes.name}
          />
        </div>
      )
    }

    if (['loadError, updateError'].includes(courseTimeToTherapeuticTargetChartStore.loadState)) {
      return (
        <MessageOverlay
          type='error'
          headerText='Failed to load analytics chart'
          messageText={
            <div className='panel-error-overlay-text'>
              {formatErrorMessage(courseTimeToTherapeuticTargetChartStore.error) || 'Something went wrong'}
            </div>
          }
          showBlur={false}
        >
          <AnalyticsPlotSkeleton
            loading={false}
            style={{ width: '546px', height: '266px' }}
          />
        </MessageOverlay>
      )
    }

    return <AnalyticsPlotSkeleton
      style={{ width: '546px', height: '266px' }}
      loading={courseTimeToTherapeuticTargetChartStore.loadState === 'loading'}
    />
  }

  return (
    <div className='doseme-analytics-chart' key='course-time-to-therapeutic-target'>
      <div className='header-row'>
        <div className='title md-8'>Course Time to Therapeutic Target by drug model</div>
        <div className='view-options md-4'>
          {/* <ListButton disabled={true}>
          <FontAwesomeIcon icon={faCog} /> View Options
        </ListButton> */}
        </div>
      </div>
      {plotContent()}
    </div>
  )
})
